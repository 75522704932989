import { useOnceAnyDay } from '@flowus/common/hooks/use-once-any-day';
import { OnceAnyDayIds } from '@flowus/shared';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { NextMenuItem } from 'src/common/components/next-menu-item';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { publicLink } from 'src/common/const';
import { useModel } from 'src/common/create-model';
import { useHasNewActivity } from 'src/hooks/activities/use-has-new-activity';
import { useAppVersion } from 'src/hooks/public/use-version';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';
import { useGetSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { useClearUser, useCurrentUser, useLogout } from 'src/hooks/user';
import { useExChangeCodeRedBadge } from 'src/services/app/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { ProIcon } from '../header/pro_icon';
import { SettingModalCommon, SettingProvider } from './common';
import { SettingMenuType } from './type';
import { useCloseSettingModal } from './use-open-setting-modal';

export const SettingAside: FC = memo(() => {
  const openModal = useOpenModal();
  const closeSettingModal = useCloseSettingModal();
  const { currentSettingMenu, setCurrentSettingMenu } = useModel(SettingProvider);
  const currentUser = useCurrentUser();
  const getSpaceRolePermission = useGetSpaceRolePermission();
  const { editor } = getSpaceRolePermission(currentUser.uuid);
  const logout = useLogout();
  const isGuest = useIsGuest();
  const hasNewActivity = useHasNewActivity();
  const exChangeCodeRedBadge = useExChangeCodeRedBadge();
  const { showOnce: profitShowPoint, close: closeProfitShowPoint } = useOnceAnyDay(
    OnceAnyDayIds.SettingProfitRedPoint,
    {
      endDay: -1,
      enable: !__PRIVATE__,
    }
  );

  const switchType = (type: SettingMenuType) => {
    if (type === SettingMenuType.profit) {
      closeProfitShowPoint();
    }
    setCurrentSettingMenu(type);
  };

  const menuList = useMemo(() => {
    const list = [
      {
        type: SettingMenuType.line,
        name: '账号',
      },
      {
        type: SettingMenuType.account,
        icon: 'IcUser',
        name: '账号设置',
      },
      {
        type: SettingMenuType.general,
        icon: 'IcGeneralSetting',
        name: '通用设置',
      },
      {
        type: SettingMenuType.integral,
        icon: 'IcGift',
        name: '活动中心',
        hidden: __PRIVATE__ || __BUILD_IN__,
        badge:
          hasNewActivity || exChangeCodeRedBadge ? (
            <span className="flex items-center absolute right-2.5 top-[calc(50%-5px)] text-t4-bold w-2.5 h-2.5 bg-red rounded-full" />
          ) : undefined,
      },
      {
        type: SettingMenuType.profit,
        icon: 'IcCoin',
        name: '邀请返佣',
        hidden: __BUILD_IN__ || __PRIVATE__,
        badge: profitShowPoint ? (
          <ProIcon text="💰赚佣金" absolute={false} className="!rounded pointer-events-none" />
        ) : undefined,
      },
      {
        name: '空间',
        type: SettingMenuType.line,
      },
    ];

    if (editor) {
      list.push({
        icon: 'IcSpace',
        type: SettingMenuType.spaceInfo,
        name: '空间信息',
      });
    }

    list.push({
      icon: 'IcSettings',
      type: SettingMenuType.space,
      name: '空间设置',
    });

    if (!isGuest) {
      list.push({
        icon: 'IcMember',
        type: SettingMenuType.members,
        name: '空间成员',
      });
      list.push({
        icon: 'IcGlobeShare',
        type: SettingMenuType.sharePage,
        name: '公开分享',
      });
      list.push({
        badge: undefined,
        icon: 'IcUpgrade',
        type: SettingMenuType.upgrade,
        name: '升级',
      });
      if (editor) {
        list.push({
          hidden: __PRIVATE__,
          badge: undefined,
          icon: 'IcShoppingCart',
          type: SettingMenuType.order,
          name: '订单',
        });
      }
    }
    return list;
  }, [editor, exChangeCodeRedBadge, hasNewActivity, isGuest, profitShowPoint]);

  return (
    <div className="flex flex-col shrink-0 px-2 pt-3 w-[20%] min-w-[200px] text-grey3 border-r border-black_006 text-t2 overflow-y-auto">
      <div className="flex-1">
        {menuList.map((item, i) => {
          if (item.hidden) return null;

          if (item.type === SettingMenuType.line) {
            return (
              <span key={i} className="flex items-center px-2 h-9 text-t2">
                {item.name}
              </span>
            );
          }

          return (
            <NextMenuItem
              dataTestId={item.name}
              activeStyle="bg"
              key={i}
              active={item.type === currentSettingMenu}
              text={item.name}
              onClick={() => switchType(item.type)}
              addonBefore={<Icon name={item.icon as IconName} size="middle" />}
              badge={item.badge}
            />
          );
        })}
        <SettingModalCommon.SettingDivider className="my-2.5" list-item-inactive="" />
        <NextMenuItem
          staticStyle
          text="退出登录"
          onClick={() => {
            openModal.loading({ title: '正在退出', keyboard: false });
            bizTracker.logout();
            closeSettingModal();
            void logout();
          }}
          addonBefore={<Icon name="MIcSettingsLogout" size="middle" />}
        />
      </div>
      <div className="px-2 text-grey4 text-t3 space-y-2 pb-5">
        <div className="flex flex-col space-y-2">
          <a
            className="underline underline-offset-4"
            target="_blank"
            href={publicLink.userAgreement}
            rel="noreferrer"
          >
            用户协议
          </a>
          <a
            className="underline underline-offset-4"
            target="_blank"
            href={publicLink.privacyPolicy}
            rel="noreferrer"
          >
            隐私条款
          </a>
        </div>
        <VersionFC />
        <ReloadApp />
      </div>
    </div>
  );
});

const ReloadApp: FC = memo(() => {
  const clearUser = useClearUser();

  const handleClick = async () => {
    await clearUser();
    // if (isFlowUsApp.check) {
    //   ipcSendMessage(IpcMessage.ClearCacheAndReload);
    // }
  };

  return (
    <Tooltip
      arrow
      popup="用久了有点卡顿？点我试试"
      className="text-active_color animate-click"
      onClick={handleClick}
    >
      清理缓存重启
    </Tooltip>
  );
});

const VersionFC: FC = memo(() => {
  const appVersion = useAppVersion();

  return (
    <>
      <div>版本：{__VERSION__}</div>
      {appVersion && (
        <>
          <div>PC版本：{appVersion}</div>
        </>
      )}
    </>
  );
});
