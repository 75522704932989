import { Modals } from '@flowus/shared';
import { isBuildIn, isLocalEnv } from './build-in';
import { createSetState, createStore } from './zustand/utils';
import { useCallback } from 'react';
import type { LaunchScreenPopup } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';

const shareQueue: { id: Modals; success: boolean }[] = [
  // {
  //   id: Modals.HOT_SPOT,
  //   success: isBuildIn(),
  // },
];

const globalQueue = [
  {
    id: Modals.CLEAR_TEMPLATE,
    success: isBuildIn(),
  },
  {
    id: Modals.CREATE_PAGE_TIP,
    success: isBuildIn(),
  },
  {
    id: Modals.AI_NOVICE,
    success: isBuildIn(),
  },
  {
    id: Modals.HOT_SPOT,
    success: isBuildIn(),
  },
  {
    id: Modals.KOL_SALES,
    success: isBuildIn(),
  },
  {
    id: Modals.CREATE_SPACE_AFTER,
    success: isBuildIn(),
  },
  {
    id: Modals.ACQUISITION_TEAM_DIALOG,
    success: isBuildIn(),
  },
  // {
  //   id: Modals.UPDATE_LOG,
  //   success: false,
  // },
  {
    id: Modals.IMPORTANT_FEATURE,
    success: false,
  },
];

//#region 进入空间后的弹窗顺序
export const useGlobalModalQueue = createStore<{ queue: { id: Modals; success: boolean }[] }>(
  () => ({ queue: globalQueue })
);

const $setGlobalModalQueue = createSetState(useGlobalModalQueue);

//#endregion
export const completeGlobalModalQueue = (id: Modals) => {
  $setGlobalModalQueue(
    (state) => {
      const index = state.queue.findIndex((item) => item.id === id);
      if (index > -1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.queue[index]!.success = true;
      }
      return state;
    },
    { replace: true }
  );

  $setShareGlobalModalQueue(
    (state) => {
      const index = state.queue.findIndex((item) => item.id === id);
      if (index > -1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.queue[index]!.success = true;
      }
      return state;
    },
    { replace: true }
  );
};

//#region share 的活动
export const useShareGlobalModalQueue = createStore<{ queue: { id: Modals; success: boolean }[] }>(
  () => ({ queue: shareQueue })
);

const $setShareGlobalModalQueue = createSetState(useGlobalModalQueue);

//#endregion

//#region
export interface ActivityDialogConfig extends Partial<Omit<LaunchScreenPopup, 'resources'>> {
  bodyImage?: string;
  darkBodyImage?: string;
  headerImage?: string;
  darkHeaderImage?: string;
  confirmText?: string;
  noCancel?: boolean;
  onConfirm?: () => void;
  confirmType?: 'openLink' | 'openUpgrade' | 'none' | 'createSpace' | 'openAiChat';
  subTitle?: string;
}

export interface CheckActivityIdIsRead extends Partial<ActivityDialogConfig> {
  validate?: 'local' | 'user';
  userId?: string;
  spaceId?: string;
  request?: any;
}
/** 缓存弹窗次数 */
export const useCheckActivityIdIsRead = () => {
  return useCallback(async (config: CheckActivityIdIsRead) => {
    const {
      activityId,
      dailyPopupLimit = 1,
      totalPopupLimit = -1,
      userId,
      spaceId,
      request,
    } = config;
    let { validate = 'local' } = config;
    let isRead = false;

    if (!activityId) return true;
    if (!userId) return true;

    if (isLocalEnv()) {
      validate = 'local';
    }

    // #region 用本地缓存判断
    if (validate === 'local') {
      const id = config.isSpaceSensitive ? spaceId ?? userId : userId;
      const toDay = dayjs().format('YYYY-MM-DD');
      const localCache = localStorage.getItem(activityId) || '{}';
      const localCacheJson = JSON.parse(localCache);

      const updateLocalJson = () => {
        localStorage.setItem(activityId, JSON.stringify(localCacheJson));
      };

      const localConfig = localCacheJson[id];

      if (localConfig) {
        // 如果今日达到上限，或者总共达到上限
        if (
          localConfig.num === dailyPopupLimit ||
          (totalPopupLimit > 0 && localConfig.num >= totalPopupLimit)
        ) {
          isRead = true;
        } else {
          const isToday = localConfig.time === toDay;
          // 比如一天一次，一共三天

          // 今天达到上限了
          if (isToday) {
            if (localConfig.num > dailyPopupLimit) {
              isRead = true;
            } else {
              // 没有达到上限
              localCacheJson[id].num++;
            }
          } else {
            // 总共是否上限了
            // -1 无上限
            if (localConfig.num > totalPopupLimit && totalPopupLimit !== -1) {
              isRead = true;
            } else {
              // 没有达到上限，更新时间。
              localCacheJson[id].num++;
              localCacheJson[id].time = toDay;
            }
          }
        }
      } else {
        localCacheJson[id] = {
          num: 1,
          time: toDay,
        };
      }
      updateLocalJson();
    }
    // #endregion

    // #region 用user信息判断
    if (validate === 'user') {
      const useSettingJsonData = await request.infra.getSetting(userId);
      const userSetting: { userReadActivities: string[] } =
        JSON.parse(useSettingJsonData || '{}') || {};

      if (!userSetting.userReadActivities) {
        // 没有设置过，就初始化
        userSetting.userReadActivities = [activityId];
      } else if (!userSetting.userReadActivities.includes(activityId)) {
        // 有设置过，但是没有这个活动id，就添加
        userSetting.userReadActivities.push(activityId);
      } else {
        // 有设置过，有这个活动id，就是已读
        isRead = true;
      }

      if (!isRead) {
        void request.infra.updateUserSetting(userId, userSetting);
      }
    }
    // #endregion

    return isRead;
  }, []);
};

/** 检查是否符合弹窗要求 */
export const useOpenActivityDialog = () => {
  const checkActivityIdIsRead = useCheckActivityIdIsRead();

  return useCallback(
    async (config: CheckActivityIdIsRead) => {
      const isRead = await checkActivityIdIsRead(config);
      return !isRead;
    },
    [checkActivityIdIsRead]
  );
};
//#endregion
