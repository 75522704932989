export const isLocalEnv = () => {
  try {
    // @ts-ignore env
    return __HOST_LOCAL__ || __HOST_TEST__;
  } catch {
    return false;
  }
};

export const isBuildIn = () => {
  try {
    // @ts-ignore env
    return __BUILD_IN__;
  } catch {
    return false;
  }
};

export const isPrivate = () => {
  try {
    // @ts-ignore env
    return __PRIVATE__;
  } catch {
    return false;
  }
};

export const getCdnHost = () => {
  try {
    // @ts-ignore env
    return import.meta?.env?.VITE_CDN_HOST || isBuildIn()
      ? 'https://cdn.buildin.ai/'
      : 'https://cdn2.flowus.cn/';
  } catch {
    return '';
  }
};

export const commonInjector = {
  isBuildIn: isBuildIn(),
  // @ts-ignore env
  cdnHost: getCdnHost(),
};

export const commonInit = (params: Partial<typeof commonInjector>) => {
  Object.assign(commonInjector, params);
};
