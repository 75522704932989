import { PayType, encryptionPhone, useIsDarkMode } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { PlanCouponDto } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { Avatar } from 'src/common/components/avatar';
import { Button } from 'src/common/components/button';
import { SettingDivider } from 'src/common/components/divider';
import { IconWidget } from 'src/common/components/emoji-widget';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { publicLink } from 'src/common/const';
import { useCurrentSpace } from 'src/hooks/space';
import { useIsTeamSpace } from 'src/hooks/space/use-is-team-space';
import { useCurrentUser } from 'src/hooks/user';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { getCurrencySymbols } from 'src/utils/currency-format';
import { HelperIconBox } from '../helper-icon';
import { TransitionNumber } from '../transition-number';
import { UpgradePaddingUiKit } from './common';

// #region 内容区域
interface BodyProps {
  className?: string;
}
const BODY: FC<BodyProps> = (props) => {
  const { className, children } = props;
  return (
    <div className={cx('max-h-[80vh] overflow-y-auto pb-5 transition-opacity', className)}>
      {children}
    </div>
  );
};
// #endregion

// #region 顶部信息
interface HeaderProps {
  avatar?: ReactNode;
  close: () => void;
}
const Header: FC<HeaderProps> = ({ close }) => {
  const isTeamSpace = useIsTeamSpace();
  const currentUser = useCurrentUser();
  const userName = useUserName(currentUser.uuid);
  const currentSpace = useCurrentSpace();

  let content = (
    <>
      <Avatar
        className="mr-2 h-6 w-6"
        icon={currentSpace.icon}
        name={currentSpace.title}
        color={currentSpace.backgroundColor}
      />
      <span className="text-t2 text-grey3">{currentSpace.title}</span>
    </>
  );

  if (!isTeamSpace) {
    content = (
      <>
        <Avatar
          name={userName}
          color={currentUser.backgroundColor}
          icon={{ type: 'upload', value: currentUser.avatar }}
          className="mr-2 w-[25px] h-[25px] text-white rounded-full"
          imgClassName="rounded-full"
          style={{ fontSize: '14px' }}
        />
        <span className="text-grey3 text-t2">
          {userName}&nbsp;{encryptionPhone(currentUser.phone ?? currentUser.email)}
        </span>
      </>
    );
  }

  return (
    <div className="flex items-center pr-5 pl-8 h-12 border-b">
      {content}
      <Icon name="IcClose" onClick={close} size="middle" className="ml-auto animate-click" />
    </div>
  );
};
// #endregion

// #region 选择套餐
interface SelectPlanProps<T> {
  items: ItemProps<T>[];
  itemRender: (props: ItemProps<T>) => ReactNode;
  itemClick: (id: string) => void;
  selectId?: string;
  loading?: boolean;
  itemClassName?: string;
  className?: string;
}
type ItemProps<T> = T & {
  id: string;
  enable?: number;
};
const SelectPlan = <T extends {}>(props: SelectPlanProps<T>) => {
  const { items, selectId, loading, itemRender, itemClick, itemClassName, className } = props;
  return (
    <UpgradePaddingUiKit
      className={cx(
        'px-7 overflow-x-auto transition-all space-x-5',
        className,
        loading && 'opacity-60'
      )}
    >
      {items.map((item) => (
        <div
          key={item.id}
          className={cx(
            'flex flex-col items-center justify-center flex-shrink-0 w-[120px] h-32 border-2 rounded-md cursor-pointer relative',
            itemClassName,
            selectId === item.id ? 'border-black bg-black_006' : 'border-grey5',
            item.enable === 2 && 'opacity-30 cursor-not-allowed'
          )}
          onClick={() => {
            if (item.enable === 2 || selectId === item.id) return;
            itemClick(item.id);
          }}
        >
          {itemRender(item)}
        </div>
      ))}
    </UpgradePaddingUiKit>
  );
};
// #endregion

// #region 套餐结束时间
interface PlanEndTimeProps {
  endTime: string;
  className?: string;
}
const PlanEndTime: FC<PlanEndTimeProps> = (props) => {
  const { className, endTime, children } = props;

  return (
    <UpgradePaddingUiKit height={36} className={cx('px-8 text-grey3 text-t3', className)}>
      {'购买后有效期至'} {endTime}
      {children}
    </UpgradePaddingUiKit>
  );
};
// #endregion

// #region 付款方式
interface PayMethodProps {
  payMethod: PayType;
  disablePayMethod?: PayType[];
  switchPayMethod: (payMethod: PayType) => void;
  payMethodList?: {
    type: PayType;
    disable?: boolean;
    title: ReactNode;
  }[];
}
const PayMethod: FC<PayMethodProps> = (props) => {
  const { payMethod, switchPayMethod, disablePayMethod, payMethodList } = props;

  // 支付方式列表
  const renderPayList = useMemo(() => {
    if (!payMethodList) {
      return [
        {
          type: PayType.alipay,
          disable: disablePayMethod?.includes(PayType.alipay),
          title: (
            <div className="flex items-center">
              <Icon name="IcAlipayPay" size="middle" className="mr-2 text-[#1677FF]" /> 支付宝支付
            </div>
          ),
        },
        {
          type: PayType.wxpay,
          disable: disablePayMethod?.includes(PayType.wxpay),
          title: (
            <div className="flex items-center">
              <Icon name="IcWechatPay" size="middle" className="mr-2 text-[#07C160]" /> 微信支付
            </div>
          ),
        },
      ];
    }
    return payMethodList;
  }, [disablePayMethod, payMethodList]);

  return (
    <>
      <UpgradePaddingUiKit height={54} className="text-h3 px-8">
        支付方式
      </UpgradePaddingUiKit>
      <div className="flex justify-center px-6">
        {renderPayList.map((item) => (
          <div
            key={item.type}
            onClick={() => !item.disable && switchPayMethod(item.type)}
            className={cx(
              'text-t2-medium flex items-center justify-center mx-2.5 border flex-1 h-10 rounded-md cursor-pointer transition-all',
              item.type === payMethod ? 'border-black bg-black_003' : 'border-grey5',
              item.disable && 'cursor-not-allowed opacity-30'
            )}
          >
            {item.title}
          </div>
        ))}
      </div>
    </>
  );
};
// #endregion

// #region 付款
interface PayBarProps {
  price?: number;
  priceAfter?: ReactNode;
  priceUnit?: number;
  onPay: () => void;
  disablePay?: boolean;
  protocols?: { link: string; title: string }[];
}
const PayBar: FC<PayBarProps> = (props) => {
  const { price, onPay, disablePay, priceUnit, protocols = [], priceAfter } = props;
  const [read, setRead] = useState(false);
  const paidServices = { link: publicLink.paidServices, title: '《付费服务条款》' };
  const protocolTitle = [paidServices, ...protocols].map((i) => i.title).join('');
  const handleClickPay = () => {
    if (!read) {
      message.warning(`请开通前阅读并同意${protocolTitle}`);
      return;
    }
    onPay();
  };

  return (
    <div className="px-8">
      {/* 付费条款 */}
      <div className="flex justify-between items-center py-2.5 text-grey3 text-t3 select-none border-t">
        <div className="flex items-center cursor-pointer" onClick={() => setRead(!read)}>
          <Icon
            name={read ? 'IcCircleCheck' : 'IcCircleCheckbox'}
            size="middle"
            className={cx('mr-1.5', read ? 'text-active_color' : 'text-grey3')}
          />
          开通前阅读并同意
          {[paidServices, ...protocols]?.map((item) => (
            <a
              key={item.title}
              href={item.link}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
            >
              {item.title}
            </a>
          ))}
        </div>
        <span className="flex items-center">
          <IconWidget emojiStr="⚠️" disableHover iconSize={12} />
          &nbsp;暂不支持退款
        </span>
      </div>
      {/* 支付按钮 */}
      <div className="flex justify-between items-center h-20 border-t select-none">
        <div className="text-h3">
          <span>总价：</span>
          <span className="text-active_color-base">
            {getCurrencySymbols(priceUnit)}
            <TransitionNumber value={price} />
          </span>
          {priceAfter}
        </div>
        <Button
          autoFocus
          className="text-t2-medium w-52 h-10"
          disable={disablePay}
          colorType="active"
          onClick={handleClickPay}
        >
          立即支付
        </Button>
      </div>
    </div>
  );
};
// #endregion

// #region 选中特殊优惠券的额外效果
interface UnionCouponDescProps {
  coupon?: PlanCouponDto;
  className?: string;
}
const UnionCouponDesc: FC<UnionCouponDescProps> = (props) => {
  const { coupon, className } = props;
  const isDarkModel = useIsDarkMode();
  if (!coupon?.resources?.title) return null;

  return (
    <div className={className}>
      <SettingDivider className="mb-2.5" />
      <div
        className={'text-t2 p-4 rounded space-y-4'}
        style={{
          background: 'linear-gradient(89.52deg, #F3E4B5 0.34%, #E9C770 99.54%)',
        }}
      >
        <div className="flex justify-between text-[#8F6C28]">
          <div className="text-h4">{coupon.resources.title}</div>
          <HelperIconBox
            className="text-t4"
            iconClassName="text-[#8F6C28]"
            linkContainer
            link={coupon.resources.jumpLink}
          >
            了解活动详情
          </HelperIconBox>
        </div>
        <img
          className="w-full pb-2"
          src={(isDarkModel ? coupon.resources.darkBodyRes : coupon.resources.lightBodyRes) || ''}
          alt={coupon.resources.title}
        />
      </div>
    </div>
  );
};
// #endregion

// #region 套餐信息
interface OrderInfoProps {
  className?: string;
  planName?: string;
  price?: number;
  priceUnit?: number;
  protocols?: { link: string; title: string }[];
  info?: string[];
  desc?: string;
}
const OrderInfo: FC<OrderInfoProps> = (props) => {
  const { planName, priceUnit, price, protocols = [], className, info = [], desc } = props;
  const paidServices = { link: publicLink.paidServices, title: '付费服务条款' };
  return (
    <div className={className}>
      <UpgradePaddingUiKit height={54} className="text-h2">
        订单信息
      </UpgradePaddingUiKit>
      <div className="px-5 py-4 bg-grey9">
        <div className="text-t1-medium mb-2.5">{`${planName} 套餐计划`}</div>
        <div className="text-t2 text-grey3">
          {info.map((i) => (
            <p key={i}>{i}</p>
          ))}
        </div>
        <div className="text-t2 text-grey3" hidden={!desc}>
          {desc}
        </div>
        <SettingDivider className="my-1" />
        <div className="text-t2-medium">
          总计 {getCurrencySymbols(priceUnit)}
          <TransitionNumber value={price} />
        </div>
      </div>
      <UpgradePaddingUiKit className="text-t2 text-grey3 flex flex-col items-start">
        {'点击“立即升级”即表示你同意'}&nbsp;
        {[paidServices, ...protocols].map((i) => (
          <a
            href={i.link}
            key={i.link}
            target={'_blank'}
            className="animate-click underline-offset-4 underline"
          >
            {i.title}
          </a>
        ))}
      </UpgradePaddingUiKit>
    </div>
  );
};
// #endregion

// #region StripeBar
interface StripePayBarProps {
  onClick: () => void;
}
const StripePayBar: FC<StripePayBarProps> = (props) => {
  return (
    <Button colorType="active" className="w-40 h-9" type="submit" onClick={props.onClick}>
      立即升级
    </Button>
  );
};
// #endregion

export const UpgradeComponents = {
  OrderInfo,
  Header,
  SelectPlan,
  PayBar,
  PayMethod,
  PlanEndTime,
  BODY,
  UnionCouponDesc,
  StripePayBar,
};
