import { sleep } from '@flowus/common/async';
import { SMART_TEAM_MAX_PEOPLE, UPGRADE_MINI_SPACE_PEOPLE } from '@flowus/common/const';
import { isInWechat } from '@flowus/common/url';
import { PayType } from '@flowus/common/utils/pay-info';
import { isInWeapp } from '@flowus/login/utils';
import type { OverseaPlanDTO, PlanCouponDto, PlanDTO } from '@next-space/fe-api-idl';
import { useDebounceEffect } from 'ahooks';
import dayjs from 'dayjs';
import { cloneDeep, filter, first, parseInt, values } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useOpenModal } from 'src/common/components/next-modal';
import { createModel, useModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { useOpenStripe } from 'src/components/stripe';
import { useCurrentSpace } from 'src/hooks/space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { Modals } from 'src/modals';
import { SpacePlanType } from 'src/redux/types';
import { useCurrentSpacePlanInfo } from 'src/services/capacity/space-plans';
import { useUserBalance } from 'src/services/user/user-balance';
import { bizTracker } from 'src/utils/biz-tracker';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { getCurrencySymbols } from 'src/utils/currency-format';
import type { PatchState } from 'src/utils/type-utils';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import { useOpenSettingModal } from 'src/views/main/setting-modal/use-open-setting-modal';
import type { AsyncReturnType } from 'type-fest';
import { v4 } from 'uuid';
import type { SelectUpgradePlanProps } from '../common';
import { getCouponTypeByBizTracker, orderBizTracker } from '../common';
import { getPlanList, useGetCacheQuote } from '../hook/use-upgrade-support';
import { PayOfflineModal } from '../pay-offline-modal';
import { PayQrModal } from '../pay-qr-modal';
import { useOpenTeamWechatQr } from '../team-wechat-qr';

export enum RenewalType {
  /** 续费人数 */
  people,
  /** 续费时长 */
  time,
}
interface State {
  /** 选择支付方式 */
  selectedPayType: PayType;
  /** 自定义时长 */
  customTime?: number;
  /** 自定义人数 */
  customPeople: number;
  /** 增加人数 - 续费时选择人数才会用到 */
  addPeople: number;
  /** 选择购买的时间套餐 */
  selectedTime: {
    type: 'fixed' | 'custom';
    value?: number;
  };
  /** 选择续费类型 */
  selectedRenewalType?: RenewalType;
  /** 优惠券 */
  couponId?: string;
}

const useUpgradeTeamPlan = (props: SelectUpgradePlanProps) => {
  const { isFrom, selectPlanType: selectPlanSpaceType } = props;
  const openStripe = useOpenStripe();
  const openSettingModal = useOpenSettingModal();
  const { integralInfo } = useUserBalance();
  const userIntegralRecord = integralInfo?.currentScore ?? 0;
  const openTeamWechatQr = useOpenTeamWechatQr();
  const openModal = useOpenModal();
  const currentSpace = useCurrentSpace();
  const users = useCurrentSpaceUsers();
  const spaceUser = values(users);
  const inputCustomPeopleTimer = useRef<NodeJS.Timeout>();
  const { spacePlan } = useCurrentSpacePlanInfo();
  const getCacheQuote = useGetCacheQuote();
  const upgradePlanName = getSpacePlanTypeName(selectPlanSpaceType);
  /** 是续费吗 */
  const isRenew = selectPlanSpaceType === currentSpace.planType;
  const upgradeTitle = isRenew ? `续费“${upgradePlanName}”` : `升级到“${upgradePlanName}”`;
  const [orderStateKey, setOrderStateKey] = useState('');
  const isPersonalToSmallTeam =
    selectPlanSpaceType === SpacePlanType.smallTeam &&
    currentSpace.planType === SpacePlanType.personal;
  const isSmallTeamToTeam =
    currentSpace.planType === SpacePlanType.smallTeam && selectPlanSpaceType === SpacePlanType.team;

  const isPersonal = currentSpace.planType === SpacePlanType.personal;
  /** 升级到团队付费 */
  const upgradeTeam = selectPlanSpaceType === SpacePlanType.team;
  /** 升级到小组付费 */
  const upgradeSmallTeam = selectPlanSpaceType === SpacePlanType.smallTeam;
  const spaceMaxPeople = useMemo(() => {
    // 如果是升级到小组付费，按空间已有人数来算
    if (upgradeSmallTeam || isSmallTeamToTeam) {
      return Math.max(SMART_TEAM_MAX_PEOPLE, spaceUser.length);
    }
    const _max = !isRenew
      ? spaceUser.length
      : Math.max(spaceUser.length, spacePlan?.maxPeople ?? UPGRADE_MINI_SPACE_PEOPLE);
    return _max;
  }, [isRenew, isSmallTeamToTeam, spacePlan?.maxPeople, spaceUser.length, upgradeSmallTeam]);

  const [loading, setLoading] = useState(true);
  const [planList, setPlanList] = useState<(PlanDTO & OverseaPlanDTO)[]>([]);

  // 优惠券列表
  const [couponList, setCouponList] = useState<PlanCouponDto[]>([]);
  const [state, setState] = useState<State>({
    customPeople: Math.max(spaceMaxPeople, UPGRADE_MINI_SPACE_PEOPLE),
    selectedPayType: __BUILD_IN__ ? PayType.webpay : isInWechat ? PayType.wxpay : PayType.alipay,
    // selectedRenewalType: isRenew ? RenewalType.time : RenewalType.people,
    // 这里只有renew才用得到，h5为了显示renew的逻辑也用到了，需要默认为time
    selectedRenewalType: RenewalType.time,
    addPeople: 1,
    selectedTime: {
      type: 'fixed',
      value: isPersonalToSmallTeam ? 0 : __BUILD_IN__ ? 12 : 1,
    },
    couponId: undefined,
  });
  const curCoupon = couponList.find((i) => i.id === state.couponId);

  /** 后端计算的价格 */
  const [calculateQuote, setCalculateQuote] =
    useState<AsyncReturnType<typeof request.infra.getQuote>>();

  /** 增加人数时，出现的购买时长 「年数+天数」 */
  const completionTime = useMemo(() => {
    if (!calculateQuote?.desiredExpireTime) {
      return { day: 0 };
    }
    const now = Date.now();
    const endDay = new Date(calculateQuote.desiredExpireTime);
    // 距离结束时间的年数
    const yearDiff = dayjs(endDay).diff(now, 'year');
    // 先通过「整年数」推到「年后时间」
    const yearTime = dayjs(now).add(yearDiff, 'year');
    // 通过「年后时间」，拿到和「整年数」的差天数
    const dayDiff = dayjs(endDay).diff(yearTime, 'day');
    return { year: yearDiff, day: dayDiff };
  }, [calculateQuote?.desiredExpireTime]);

  /** 获取当前购买的套餐的参数状态 */
  const getOrderParams = (params = state) => {
    params = cloneDeep(params);
    // 当前购买的套餐
    const currentPlan = planList.find((item) => {
      const planType = __BUILD_IN__ ? item.orderPackageType : item.spacePlanType;
      if (isRenew) {
        if (params.selectedRenewalType === RenewalType.people) {
          return planType === 'renewTeamPeople';
        }
        if (params.selectedRenewalType === RenewalType.time) {
          if (__BUILD_IN__) {
            return planType === 'renewTeamTime' && item.monthNum === params.selectedTime.value;
          }
          return planType === 'renewTeamTime';
        }
      }
      if (__BUILD_IN__) {
        return planType === 'upgradeTeam' && item.monthNum === params.selectedTime.value;
      }
      return planType === 'upgradeTeam';
    });

    let date =
      params.selectedTime.type === 'custom' ? params.customTime : params.selectedTime.value;

    // 兼容海外包月的情况，因为都是靠monthNum算的，但planId决定了要不要 monthNum x12 而不是端上做
    if (__BUILD_IN__ && date === 12) {
      date = 1;
    }

    let addPeople = 0;

    let people = params.customPeople || spaceMaxPeople;

    if (isRenew) {
      // 特殊处理，如果是续费人数，需要加上空间总人数
      if (params.selectedRenewalType === RenewalType.people) {
        addPeople = params.addPeople;
        people = params.addPeople + spaceMaxPeople;
        // 同时时间传0，因为只是增加人数，时间是固定的到期时间
        date = 0;
      } else {
        addPeople = spaceMaxPeople;
        // 如果是续费时间，这里传空间总人数
        people = spaceMaxPeople;
      }
    } else {
      addPeople = params.customPeople || 0;
    }
    return {
      /** 增加的人数 */
      addPeople,
      /** 套餐 */
      currentPlan,
      /** 套餐id */
      planId: currentPlan?.id ?? '',
      /** 增加的年数 */
      date: date ?? 0,
      /** 升级套餐之后，空间的人数 */
      people,
    };
  };

  /** 请求接口计算价格 */
  const getQuoteApi = async (params = state) => {
    const { planId, date, people } = getOrderParams(params);
    if (!planId) return;
    const res = await getCacheQuote({
      planId,
      date,
      people,
      spaceId: currentSpace.uuid,
      couponId: params.couponId,
    });
    return res;
  };

  /** 更新后端计算价格 */
  const patchCalculateQuote = async (params = state) => {
    const { planId } = getOrderParams(params);
    if (!planId) return;
    const timer = setTimeout(() => {
      setLoading(true);
      clearTimeout(timer);
    }, 800);
    const res = await getQuoteApi(params);
    setOrderStateKey(v4());
    clearTimeout(timer);
    setCalculateQuote(res);
    setLoading(false);
  };

  /** 更新优惠券 */
  const patchCouponList = async (params = state, amount = 0) => {
    const { planId, date } = getOrderParams(params);
    const couponListRes = await request.infra.getCouponList(
      planId,
      parseInt(`${date * 12}`),
      currentSpace.uuid
    );
    const couponList = filter(
      couponListRes.list,
      (i) => (i.integralRecord ?? 0) <= userIntegralRecord
    );
    setCouponList(couponListRes.list ?? []);
    let newCouponId = params.couponId;
    const found = couponList.find((v) => v.id === params.couponId);
    if (!found) {
      // 如果本身已经设置了优惠卷，就不要重新设置第一个了
      const firstCoupon = first(couponList);
      if (!firstCoupon) {
        patchState('couponId', '');
      } else {
        patchState('couponId', firstCoupon.id);
        newCouponId = firstCoupon.id;
      }
    }
    void patchCalculateQuote({ ...params, couponId: newCouponId });
  };

  useEffect(() => {
    void getPlanList(currentSpace.uuid, selectPlanSpaceType).then((res) =>
      setPlanList(res.list || [])
    );
    bizTracker.event('payorder_show', { is_from: isFrom });
  }, [currentSpace.uuid, isFrom, selectPlanSpaceType]);

  useDebounceEffect(
    () => {
      if (planList.length === 0) return;
      void getQuoteApi({ ...state, couponId: undefined })?.then((res) => {
        void patchCouponList(state, res?.amount);
      });
    },
    [
      planList.length,
      state.customTime,
      state.selectedTime,
      state.selectedRenewalType,
      state.addPeople,
      state.customPeople,
    ],
    { wait: 500 }
  );

  useDebounceEffect(
    () => {
      if (planList.length === 0) return;
      void patchCalculateQuote(state);
    },
    [state.couponId],
    { wait: 500 }
  );

  const patchState: PatchState<State> = (key, value) => {
    setState((pre) => {
      const newState = { ...pre, [key]: value };
      return newState;
    });
  };

  /** 关闭弹窗 */
  const close = () => {
    openModal.closeModal(Modals.SELECT_UPDATE_PLAN);
  };

  /** 更换支付方式 */
  const changePayType = (orderId: string) => {
    void request.infra.cancelOrder(orderId);
  };

  const checkoutOrder = async () => {
    const { date, planId, people } = getOrderParams();
    let payType = state.selectedPayType;
    // 由于获取套餐还是web的逻辑，所以只能在最后调用接口的时候替换为h5 alipay
    if (isMobile) {
      // if (payType === PayType.alipay) {
      //   payType = PayType.alipayH5;
      // } else
      if (payType === PayType.wxpay) {
        if (isInWeapp()) {
          payType = PayType.minipay;
        } else if (isInWechat) {
          payType = PayType.wxjsapi;
        } else {
          payType = PayType.wxpayH5;
        }
      }
    }
    const res = await request.infra.createPreOrderV2.raw({
      payType,
      spaceId: currentSpace.uuid,
      couponId: state.couponId,
      planId,
      date,
      people,
    });

    openModal.closeModal('payLoading');

    if (res.code === 200) {
      return res.data;
    }

    throw new Error('下单失败');
  };

  const sendOrderBizTracker = (bizType: 'order_finish' | 'pay_order') => {
    const order_new = isRenew ? 'yes' : 'no';
    orderBizTracker({
      bizType,
      order_amount: calculateQuote?.amount,
      order_month: (completionTime.year || 0) * 12,
      order_channel:
        state.selectedPayType === PayType.offline
          ? 'card'
          : state.selectedPayType === PayType.alipay
          ? 'zhifubao'
          : 'weixin',
      is_from: isFrom,
      order_new,
      order_type: 'single',
      coupon_type: getCouponTypeByBizTracker(curCoupon?.type),
      coupon_amount: curCoupon?.salePrice,
      pay_point: curCoupon?.integralRecord,
      space_current_type: currentSpace.planType,
      space_target_type: selectPlanSpaceType,
    });
  };
  /** 下单 */
  const payOrder = async () => {
    if (__BUILD_IN__) {
      openStripe({ getClientSecret: checkoutOrder });
      return;
    }
    if (state.selectedPayType === PayType.offline) {
      const res = await request.infra.getOrderList(1, 1, currentSpace.uuid);
      if (res.pendingList?.[0]) {
        openModal.warning({
          title: <div className="mb-3">请先处理未完成订单</div>,
          confirmText: '去查看',
          confirm: () => {
            close();
            openSettingModal({
              defaultMenuType: SettingMenuType.order,
              from: OpenSettingFrom.order,
            });
          },
        });
        return;
      }
    }

    openModal.loading({ modalId: 'payLoading', title: '正在下单' });
    const order = await checkoutOrder();

    // 订单成功后的回调
    const onFinish = async () => {
      sendOrderBizTracker('order_finish');
      close();
      await sleep(1000);
      openSettingModal({
        defaultMenuType: SettingMenuType.upgrade,
        from: OpenSettingFrom.pay,
      });
      if (upgradeTeam) {
        openTeamWechatQr();
      }
    };

    sendOrderBizTracker('pay_order');

    if (PayType.offline === state.selectedPayType) {
      openModal.modal({
        keyboard: false,
        content: ({ onCloseModal }) => (
          <PayOfflineModal
            onCloseModal={onCloseModal}
            onFinish={onFinish}
            orderId={order.orderId}
            price={order.amount || 0}
            endTime={order.expirationTime ?? 0}
            remark={order.remark}
            changePayType={() => {
              onCloseModal();
              changePayType(order.orderId);
            }}
          />
        ),
      });
    } else {
      openModal.modal({
        keyboard: false,
        modalId: Modals.PAY_MODAL,
        content: () => (
          <PayQrModal
            onFinish={onFinish}
            qrCodeUrl={`${order.qrCodeUrl}`}
            orderId={order.orderId}
            payType={state.selectedPayType}
            priceTitle={`${getCurrencySymbols(calculateQuote?.amountUnit)}${order.amount}`}
            rePayOrder={checkoutOrder}
            changePayType={() => {
              openModal.closeModal(Modals.PAY_MODAL);
              changePayType(order.orderId);
            }}
          />
        ),
      });
    }
  };

  return {
    sendOrderBizTracker,
    selectPlanSpaceType,
    couponList,
    state,
    patchState,
    calculateQuote,
    inputCustomPeopleTimer,
    spaceMaxPeople,
    completionTime,
    loading,
    getOrderParams,
    payOrder,
    upgradeTitle,
    close,
    currentCoupon: curCoupon,
    upgradePlanName,
    checkoutOrder,
    orderStateKey,
    isRenew,
    isPersonalToSmallTeam,
    isSmallTeamToTeam,
    upgradeTeam,
    isPersonal,
  };
};
export const UpgradeTeamPlanContext = createModel(useUpgradeTeamPlan);

export const useUpgradeTeamPlanContext = () => useModel(UpgradeTeamPlanContext);
