import { encryptionPhone, Role } from '@flowus/common';
import { Highlight } from '@flowus/common/components/hight-light';
import { cx } from '@flowus/common/cx';
import type { PermissionDTO } from '@next-space/fe-api-idl';
import { PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { colors } from 'src/colors';
import { Avatar } from 'src/common/components/avatar';
import { UNNAMED_GROUP, UNNAMED_USER } from 'src/common/const';
import { useRestoreRole } from 'src/hooks/block/use-restore-role';
import { useAllUser } from 'src/hooks/page/use-subscription-data';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpace } from 'src/hooks/space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { getSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { useCurrentUser } from 'src/hooks/user';
import { getUserName } from 'src/hooks/user/use-remark-name';
import type { LocalUser } from 'src/redux/reducers/users';
import { useGuestsList } from 'src/services/app';
import { useGetPageId } from 'src/utils/getPageId';
import { searchUsers } from 'src/utils/search-util';
import type { ShareProps, UserPermission } from '../types';
import { permissionName, Permissions } from './permissions';

interface Props extends Pick<ShareProps, 'onChange' | 'selectUser'> {
  filterUserNoInSpace?: boolean;
  searchText?: string;
  noRemove?: boolean;
  showRight?: boolean;
  isForm?: boolean;
  showRestore?: boolean;
  activeIndex?: number;
  permissions: (PermissionDTO & { index?: number })[];
}

export const UserList: FC<Props> = ({
  filterUserNoInSpace,
  searchText = '',
  activeIndex,
  permissions,
  onChange,
  showRight,
  noRemove,
  selectUser,
  isForm,
  showRestore,
}) => {
  const currentSpace = useCurrentSpace();
  const users = useAllUser();
  const guestsList = useGuestsList();
  const startRef = useRef(false);
  const spaceUsers = useCurrentSpaceUsers();
  const currentUser = useCurrentUser();
  const restoreRole = useRestoreRole();
  const pageId = useGetPageId();
  const { role } = usePermissions(pageId);
  const ref = useRef<HTMLDivElement>(null);

  const process = useCallback(
    (permission: PermissionDTO & { index?: number }) => {
      const item: UserPermission = {
        index: permission.index ?? 0,
        type: permission.type,
        role: permission.role,
        isGuest: permission.isGuest,
      };

      if (permission.type === PermissionType.SPACE) {
        item.id = 'space';
        item.backgroundColor = currentSpace.backgroundColor;
        item.name = currentSpace.title;
        item.usersNum = currentSpace.permissions?.length ?? 0;
        item.icon = currentSpace.icon;
        if (searchText) {
          const usersArray = Object.entries(users).map(([_, u]) => u);
          item.userNames = searchUsers(usersArray, searchText).map((u) => getUserName(u.uuid));
        }
      } else if (permission.type === PermissionType.USER && permission.userId) {
        const user = users[permission.userId];
        if (!user) return null;
        // 不是外部协作者 并且 空间成员里没有的 可能是被移除的成员 所以不展示
        if (filterUserNoInSpace && !spaceUsers[user.uuid] && !permission.isGuest) return null;
        item.id = user.uuid;
        item.name = getUserName(user.uuid);
        item.backgroundColor = user.backgroundColor;
        item.icon = { type: 'upload', value: user.avatar };
        item.phone = user.phone || user.email;
        item.isGuest = permission.isGuest || (!!searchText && !spaceUsers[user.uuid]);
        item.isGuestInSpace = guestsList.some((g) => g.userId === user.uuid);
      } else if (permission.type === PermissionType.GROUP && permission.groupId) {
        const group = currentSpace.permissionGroups?.find(
          (group) => group.id === permission.groupId
        );
        if (!group) return null;

        item.id = group.id;
        item.name = group.name || UNNAMED_GROUP;
        item.usersNum = group.userIds.length;
        item.icon = group.icon;
        if (searchText) {
          const usersArray = group.userIds.map((id) => users[id]).filter((u) => !!u) as LocalUser[];
          item.userNames = searchUsers(usersArray, searchText).map((u) => getUserName(u.uuid));
        }
      }
      return item;
    },
    [currentSpace, guestsList, spaceUsers, searchText, users, filterUserNoInSpace]
  );

  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        event.preventDefault();
        event.stopPropagation();

        if (startRef.current) return;

        if ((activeIndex ?? -1) >= 0) {
          const permission = permissions.find((o) => o.index === activeIndex);
          if (permission) {
            const item = process(permission);
            if (item && !item.role) {
              selectUser?.({ ...permission, name: item.name || item.phone });
            }
          }
        }
      }
    };

    const compositionstart = () => {
      startRef.current = true;
    };
    const compositionend = () => {
      startRef.current = false;
    };

    document.addEventListener('keydown', onEnter);
    document.addEventListener('compositionstart', compositionstart);
    document.addEventListener('compositionend', compositionend);

    return () => {
      document.removeEventListener('keydown', onEnter);
      document.removeEventListener('compositionstart', compositionstart);
      document.removeEventListener('compositionend', compositionend);
    };
  }, [activeIndex, permissions, process, selectUser]);

  const isShowRestore = useMemo(() => {
    if (!showRestore) return false;
    const spaceEditor = getSpaceRolePermission(currentUser.uuid).editor;
    if (!spaceEditor) return false;
    if (Role.contains(role, PermissionRole.EDITOR)) return false;

    return permissions.every((item) => {
      if (item.type === PermissionType.USER && item.userId) {
        if (!spaceUsers[item.userId]) {
          return true;
        }
      }
      // 所有人都不是最高权限的时候，就需要 restore 了
      return !Role.contains(item.role ?? PermissionRole.NONE, PermissionRole.EDITOR);
    });
  }, [currentUser.uuid, permissions, role, showRestore, spaceUsers]);

  return (
    <div ref={ref} className="max-h-[40vh] overflow-y-auto">
      <div hidden={!isShowRestore} className="text-t4 text-grey3 space-x-0.5 p-1">
        <span>{'此页面无管理员，你可'}</span>
        <span
          className="animate-click underline underline-offset-2 text-black"
          onClick={() => restoreRole(pageId)}
        >
          {'重置权限'}
        </span>
      </div>
      <Virtuoso
        skipAnimationFrameInResizeObserver
        customScrollParent={ref.current || document.body}
        data={permissions}
        overscan={10}
        increaseViewportBy={10}
        itemContent={(_, permission) => {
          const item = process(permission);
          if (!item) return null;
          const searchedUsersNum = item.userNames?.length ?? 0;

          const fullRounded = permission.type === PermissionType.USER ? 'rounded-full' : '';

          const hasRole =
            !item.isGuest && !!searchText && item.role && item.role !== PermissionRole.NONE;

          return (
            <div
              className={cx(
                'relative flex items-center justify-between rounded p-2',
                !hasRole && selectUser && 'cursor-pointer animate-click',
                item.index === activeIndex && 'normal-bg'
              )}
              onClick={() =>
                !hasRole &&
                selectUser &&
                selectUser({ ...permission, name: item.name || item.phone })
              }
              key={item.id}
            >
              {hasRole && (
                <div className="absolute inset-0 bg-white1 opacity-70 cursor-not-allowed"></div>
              )}

              <div className={cx('flex items-center w-full', hasRole && item.role && 'w-60')}>
                <Avatar
                  color={item.backgroundColor || colors.grey}
                  name={item.name || (item.isGuest ? '客' : UNNAMED_USER)}
                  icon={item.icon}
                  className={`mr-2 w-7 h-7 !text-t1 ${fullRounded}`}
                  imgClassName={fullRounded}
                />
                <div className="flex flex-col">
                  <div className="flex text-t2">
                    <div className="flex items-center max-w-[240px] text-ellipsis space-x-1">
                      <div>{item.name || (item.isGuest ? item.phone : UNNAMED_USER)}</div>
                      {item.isGuest && (
                        <div className="rounded px-1 bg-grey6 text-t4 text-grey3">外部</div>
                      )}
                    </div>
                    {item.usersNum !== undefined && (
                      <div className="flex-shrink-0">{`（${item.usersNum} 人）`}</div>
                    )}
                  </div>

                  {item.name && item.phone && (
                    <span className="text-grey3 text-t4">{encryptionPhone(item.phone)}</span>
                  )}

                  {!!searchedUsersNum && (
                    <span className="text-t4 text-grey3 line-clamp-1">
                      <span className="mr-1">包含:</span>
                      {item.userNames?.map((userName, i) => (
                        <span
                          className={cx(i !== searchedUsersNum - 1 && 'mr-1')}
                          key={`${userName}-${i}`}
                        >
                          <Highlight text={userName} search={searchText} />
                          {i !== searchedUsersNum - 1 && ','}
                        </span>
                      ))}
                    </span>
                  )}
                </div>
              </div>

              {hasRole && item.role && (
                <div className="text-t4 flex-shrink-0">
                  {isForm ? '可填写' : `已设置${permissionName[item.role]}`}
                </div>
              )}

              {!showRight && item.isGuest && !item.isGuestInSpace && (
                <div className="text-t4 text-grey3 flex-shrink-0">未加入该空间</div>
              )}

              <Permissions
                isForm={isForm}
                isGuest={item.isGuest}
                noRemove={noRemove}
                isSpaceType={item.type === PermissionType.SPACE}
                role={item.role}
                onChange={onChange ? (role) => onChange(role, permission) : undefined}
                showRight={showRight}
              />
            </div>
          );
        }}
      />
    </div>
  );
};
