/** 抓手按钮 */

import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { covertBlockToTitle } from 'src/editor/component/menu-list/menu-item';
import { ID_HOVER_MENU } from 'src/hooks/page/use-dnd/helper';
import { bizTracker } from 'src/utils/biz-tracker';

import { useOpenBlockRightMenuList } from './block-menu-list/hook';
import type { HoverNode } from './type';

export const DragButton: FC<{ hoverNode: HoverNode }> = ({ hoverNode }) => {
  const openBlockRightMenuList = useOpenBlockRightMenuList();

  return (
    <div
      id={ID_HOVER_MENU}
      data-draggable
      data-disable-select
      data-block-id={hoverNode.id}
      data-view-id={hoverNode.node.dataset.viewId}
      data-group-value={hoverNode.node.dataset.groupValue}
      onClick={(event) => {
        const title = covertBlockToTitle(hoverNode.id);
        bizTracker.event('block_menu', { block_name: title });
        openBlockRightMenuList({
          blockId: hoverNode.id,
          blockNode: hoverNode.node,
          menuPosition: event.currentTarget,
        });
      }}
      className={`w-5 h-6 flex items-center justify-center animate-hover rounded text-grey4 hover:text-grey1 cursor-grab`}
    >
      <Icon name="IcMenu" size="small" className="pointer-events-none" />
    </div>
  );
};
