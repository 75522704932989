export * from './other-icons';
import { getCdnHost } from '../build-in';

export const ossImagesPath = `${getCdnHost()}fe-web-app-images`;

export const globalBaseImage = {
  weAppQr: `${ossImagesPath}/we-app-qr.jpg`,
  qr: `${ossImagesPath}/qr.png`,
  payAccessBg: `${ossImagesPath}/pay-access-bg.png`,
  icon: `${ossImagesPath}/icon.svg`,
  defaultAvatar: `${ossImagesPath}/default-avatar.svg`,
  loadingGif: `${ossImagesPath}/loading-gif.svg`,
  favicon: `${ossImagesPath}/favicon.png`,
  logo: `${ossImagesPath}/logo.svg`,
  logoWide: `${ossImagesPath}/logo_black_wide.svg`,
  logoWhite: `${ossImagesPath}/logo_white.svg`,
  logoBlackWide: `${ossImagesPath}/logo_black_wide.svg`,
  logoWhiteWide: `${ossImagesPath}/logo_white_wide.svg`,
  resizeLeft: `${ossImagesPath}/resize-left.svg`,
  loginAvatar: `${ossImagesPath}/login-avatar.svg`,
  spaceTeam: `${ossImagesPath}/space-team.svg`,
  spacePersonal: `${ossImagesPath}/space-personal.svg`,
  submit: `${ossImagesPath}/submit.png`,
  clearTemplate: `${ossImagesPath}/clear-template.png`,
  pointBg: `${ossImagesPath}/point-bg.svg`,
  createSpaceTeam: `${ossImagesPath}/create-space-team.svg`,
  createSpacePersonal: `${ossImagesPath}/create-space-personal.svg`,
  createSpacePersonalBottom: `${ossImagesPath}/create-space-personal-bottom.svg`,
  shareFormQr: `${ossImagesPath}/share-form-qr.png`,
  orderRemarks: `${ossImagesPath}/order-remarks.svg`,
  teamWechatQr: `${ossImagesPath}/team-wechat-qr.jpg`,
  templateEmpty: `${ossImagesPath}/template_empty.svg`,
  star: `${ossImagesPath}/star.svg`,
  importGuestLeft: `${ossImagesPath}/import-guest-left.png`,
  importGuestRight: `${ossImagesPath}/import-guest-right.png`,
  compactLayout: `${ossImagesPath}/layout/compact-layout.png`,
  defaultLayout: `${ossImagesPath}/layout/default-layout.png`,
  looseLayout: `${ossImagesPath}/layout/loose-layout.png`,
  wechatClipperFailed: `${ossImagesPath}/wechat-clipper-failed.png`,
  bindWechatClip: `${ossImagesPath}/bind-wechat-clip.png`,
  subscribeRecommend: `${ossImagesPath}/subscribe-recommend.png`,
  subscribeCenter: `${ossImagesPath}/subscribe-center.png`,

  templatesEmpty: `${ossImagesPath}/activity/web-big-image/templates-empty.jpg`,

  transparent: `${ossImagesPath}/transparent.png`,
  medal: `${ossImagesPath}/medal.png`,
  medalBg: `${ossImagesPath}/medal-bg.png`,
  littleMedal: `${ossImagesPath}/little-medal.png`,

  pdfSummary: `${ossImagesPath}/pdf-preview-home/summary.png`,
  pdfFile: `${ossImagesPath}/pdf-preview-home/file.png`,
  pdfCompare: `${ossImagesPath}/pdf-preview-home/compare.png`,
  pdfAsk: `${ossImagesPath}/pdf-preview-home/ask.png`,

  activityHelp: `${getCdnHost()}activity/task/web/help.png`,
  accountInvite: `${ossImagesPath}/activity/account-invite.svg`,
  couponCountDown: `${ossImagesPath}/activity/coupon-count-down.svg`,
  s102Head: `${ossImagesPath}/activity/s102-header1.webp`,
  s102Body: `${ossImagesPath}/activity/s102-body1.webp`,
  s102TeamHead: `${ossImagesPath}/activity/s102-header2.webp`,

  // ai 次数超出限制
  aiCountExceed1: `${ossImagesPath}/ai/img1.png`,
  aiCountExceed2: `${ossImagesPath}/ai/img2.png`,
  aiCountExceed3: `${ossImagesPath}/ai/img3.png`,
  defaultWare: `${ossImagesPath}/score/default-ware.png`,
  // 图表
  chartBarBase: `${ossImagesPath}/chart/chart_bar_base.svg`,
  chartBarStack: `${ossImagesPath}/chart/chart_bar_stack.svg`,
  chartBarPercent: `${ossImagesPath}/chart/chart_bar_percent.svg`,
  chartHorizontalbarBase: `${ossImagesPath}/chart/chart_horizontalbar_base.svg`,
  chartHorizontalbarStack: `${ossImagesPath}/chart/chart_horizontalbar_stack.svg`,
  chartHorizontalbarPercent: `${ossImagesPath}/chart/chart_horizontalbar_percent.svg`,
  chartLineBase: `${ossImagesPath}/chart/chart_line_base.svg`,
  chartLineSmooth: `${ossImagesPath}/chart/chart_line_smooth.svg`,
  chartLineStep: `${ossImagesPath}/chart/chart_line_step.svg`,
  chartNumber: `${ossImagesPath}/chart/chart_number.svg`,
  chartPieBase: `${ossImagesPath}/chart/chart_pie_base.svg`,
  expandnode: `${ossImagesPath}/expandnode.svg`,
  pageEmpty: `${ossImagesPath}/page_empty.png`,
  //升级面板
  personal: `${ossImagesPath}/all/personal.png`,
  smallTeam: `${ossImagesPath}/all/small_team.png`,
  team: `${ossImagesPath}/all/team.png`,
  spaceBg: `${ossImagesPath}/all/space_bg.png`,
  couponStar: `${ossImagesPath}/all/star.svg`,

  logoWithText: `${ossImagesPath}/all/logo_and_text.png`,
};

export const globalDarkImage = {
  logoWide: `${ossImagesPath}/logo_white_wide.svg`,
  payAccessBg: `${ossImagesPath}/pay-access-bg-dark.png`,
  spacePersonal: `${ossImagesPath}/dark-image/space-personal.svg`,
  createSpaceTeam: `${ossImagesPath}/dark-image/create-space-team.svg`,
  createSpacePersonal: `${ossImagesPath}/dark-image/create-space-personal.svg`,
  clearTemplate: `${ossImagesPath}/dark-image/clear-template.svg`,
  spaceTeam: `${ossImagesPath}/dark-image/space-team.svg`,
  teamQrHeader: `${ossImagesPath}/dark-image/team-qr-header.svg`,
  templatesEmpty: `${ossImagesPath}/dark-image/templates-empty.png`,
  compactLayout: `${ossImagesPath}/layout/dark-compact-layout.png`,
  defaultLayout: `${ossImagesPath}/layout/dark-default-layout.png`,
  looseLayout: `${ossImagesPath}/layout/dark-loose-layout.png`,
  logoWithText: `${ossImagesPath}/all/dark/logo_and_text.png`,
};

export const GlobalImages = {
  reload: (isDarkMode: boolean) => {
    if (isDarkMode) {
      Object.assign(GlobalImages, globalDarkImage);
    } else {
      Object.assign(GlobalImages, globalBaseImage);
    }
  },
  ...globalBaseImage,
};
