import { sortBy } from 'lodash-es';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useBitable } from 'src/bitable/context';
import { getSortedRecordIds } from 'src/hooks/collection-view/use-get-sorted-records';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { useCollectionSearchById, useIsMobileSize } from 'src/services/app/hook';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { getDateTimeStamp, ONE_DAY } from 'src/utils/date-utils';
import type { RenderCard } from './card';
import { Card, getCardHeight, PROPERTY_TOP } from './card';
import { getCalendarRenderTime } from './utils/get-calendar-render-time';
import { useGetPageId } from 'src/utils/getPageId';

const useSelectRecord = (weekFirstDay: number, weekLastDay: number) => {
  const { viewId, collectionId } = useBitable();
  const pageId = useGetPageId();

  // 监听 search 值
  useCollectionSearchById(viewId);
  return useObservableStore(
    (state) => {
      const { blocks, collectionViews } = state;
      const collection = blocks[collectionId];
      const renderCards: RenderCard[] = [];
      const view = collectionViews[viewId];
      if (!view || !collection) return renderCards;

      const { calendarBy = '', calendarByEnd = '' } = view.format;
      const sortedRecordIds = getSortedRecordIds({ viewId, state, pageId });

      const { schema = {} } = collection.data;

      sortedRecordIds.forEach((recordId: string) => {
        const { rawStartTime, renderStartTime, rawEndTime, renderEndTime } = getCalendarRenderTime({
          recordId,
          calendarBy,
          calendarByEnd,
          blocks,
        });
        if (!renderStartTime || !renderEndTime) return;
        const startDate = getDateTimeStamp(renderStartTime);
        const endDate = getDateTimeStamp(renderEndTime);
        if (endDate < weekFirstDay || startDate > weekLastDay) return;

        const weekStartDate =
          startDate >= weekFirstDay && startDate <= weekLastDay ? startDate : weekFirstDay;
        const weekEndDate =
          endDate >= weekFirstDay && endDate <= weekLastDay ? endDate : weekLastDay;
        renderCards.push({
          calendarBy,
          calendarByEnd,
          calendarByType: schema[calendarBy]?.type,
          calendarByEndType: schema[calendarByEnd]?.type,
          recordId,
          rawStartTime,
          rawEndTime,
          renderStartTime,
          renderEndTime,
          weekStartDate,
          weekEndDate,
          isStartTime: weekStartDate === startDate,
          isEndTime: weekEndDate === endDate,
          order: 1,
        });
      });

      const sortedCards = sortBy(renderCards, (item) => item.weekStartDate);
      const dateSet: Record<string, number[]> = {};
      sortedCards.forEach((card) => {
        let order = 0;
        const orderList = dateSet[card.weekStartDate];
        if (orderList) {
          order = Object.entries(sortBy(orderList)).findIndex(
            ([key, value]) => Number(key) !== value
          );
          order = order === -1 ? orderList.length : order;
        }

        let nextWeekDay = card.weekStartDate;
        while (nextWeekDay <= card.weekEndDate) {
          dateSet[nextWeekDay] = dateSet[nextWeekDay] ?? [];
          dateSet[nextWeekDay]?.push(order);
          nextWeekDay += ONE_DAY;
        }

        card.order = order + 1;
      });

      return sortedCards;
    },
    [viewId, collectionId, weekFirstDay, weekLastDay]
  );
};

export const WeekCards: FC<{
  weekFirstDay: number;
  weekLastDay: number;
  weekRef: React.RefObject<HTMLDivElement>;
}> = memo(({ weekFirstDay, weekLastDay, weekRef }) => {
  const renderCards = useSelectRecord(weekFirstDay, weekLastDay);
  const { viewId } = useBitable();
  const isMobileSize = useIsMobileSize();
  const [visibleProperty = []] = useProperties(viewId, { visible: true });
  const visiblePropertyLength = visibleProperty.length;

  useEffect(() => {
    const weekNodes = weekRef.current;
    if (!weekNodes) return;

    const minHight = isMobileSize ? 80 : 124;
    if (renderCards.length === 0) {
      weekNodes.style.height = `${minHight}px`;
    } else {
      const sum: Record<string, number> = {};
      renderCards.forEach((item) => {
        let time = item.weekStartDate;
        while (time <= item.weekEndDate) {
          sum[time] = (sum[time] || 0) + 1;
          time = time + ONE_DAY;
        }
      });

      const maxCardNum = Math.max(...Object.values(sum));
      const height = maxCardNum * getCardHeight(visiblePropertyLength) + PROPERTY_TOP;
      weekNodes.style.height = `${Math.max(height, minHight)}px`;
    }
  }, [isMobileSize, renderCards, visiblePropertyLength, weekRef]);

  if (renderCards.length === 0) return null;

  return (
    <>
      {renderCards.map((card) => (
        <Card {...card} key={card.recordId} weekFirstDay={weekFirstDay} />
      ))}
    </>
  );
});
