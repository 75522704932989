import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Fragment, useMemo } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { CURRENT_DOMAIN, PersonSpaceFreeCapacity } from 'src/common/const';

// 打勾的icon
const selected = <Icon name="IcCheck02" size="middle" />;
const activeColorSelected = <Icon name="IcCheck02" size="middle" className="text-active_color" />;

const activeNoLimit = <span className="text-active_color">无限次</span>;
const tooltipFc = (str: string, link?: string, popup?: string) => (
  <Tooltip popup={popup} className={cx(link && 'cursor-pointer border-b border-grey6')}>
    <a href={link ? link : undefined} target="_blank" rel="noreferrer">
      {str}
    </a>
  </Tooltip>
);

const useSpacePlanPrice = () => {
  /** 价格表 */
  const fuPriceData = [
    // AI权益
    {
      title: <span className="text-black">AI 权益</span>,
      data: [
        [tooltipFc('总次数'), '5次/月', activeNoLimit, activeNoLimit, activeNoLimit],
        [
          tooltipFc('AI 问问空间', '', 'AI 将基于空间内容进行回答'),
          selected,
          activeColorSelected,
          activeColorSelected,
          activeColorSelected,
        ],
        [
          tooltipFc('AI 问问知识库', '', 'AI 将基于知识库的精选免费内容进行回答。'),
          selected,
          activeColorSelected,
          activeColorSelected,
          activeColorSelected,
        ],
        [
          tooltipFc('AI 问问我的订阅', '', 'AI 将基于订阅的内容进行回答。'),
          '',
          activeColorSelected,
          activeColorSelected,
          activeColorSelected,
        ],
        [
          tooltipFc('AI 写文章'),
          selected,
          activeColorSelected,
          activeColorSelected,
          activeColorSelected,
        ],
        [
          tooltipFc('AI 续写'),
          selected,
          activeColorSelected,
          activeColorSelected,
          activeColorSelected,
        ],
        [
          tooltipFc('AI 翻译'),
          selected,
          activeColorSelected,
          activeColorSelected,
          activeColorSelected,
        ],
        [
          tooltipFc('AI 总结'),
          selected,
          activeColorSelected,
          activeColorSelected,
          activeColorSelected,
        ],
        [
          tooltipFc('AI 摘要'),
          selected,
          activeColorSelected,
          activeColorSelected,
          activeColorSelected,
        ],
        [
          tooltipFc('AI 解释'),
          selected,
          activeColorSelected,
          activeColorSelected,
          activeColorSelected,
        ],
        [
          tooltipFc('AI PDF 总结'),
          selected,
          activeColorSelected,
          activeColorSelected,
          activeColorSelected,
        ],
      ],
    },
    // 用量
    {
      title: '用量',
      data: [
        [tooltipFc('页面/块数量'), '无限', '无限', '无限', '无限'],
        [
          tooltipFc(
            '空间成员人数',
            `${CURRENT_DOMAIN}/share/12a522e8-cf0d-4959-af83-28276ead05e6`,
            '可邀请团队伙伴加入空间实现团队内部的高效协作'
          ),
          '1',
          '1',
          '上限5人',
          '无限',
        ],
        [
          tooltipFc(
            '外部协作人数',
            `${CURRENT_DOMAIN}/share/b987ee4d-ee13-44d2-96a9-07161675ae13`,
            '可邀请外部临时人员加入空间的指定页面协作，适用于跨团队协作、客户沟通等场景'
          ),
          '上限5人',
          '1000 人',
          '无限',
          '无限',
        ],
        [tooltipFc('文件上传', '', '上传单个图片或文件附件的大小限制'), '2MB', '5GB', '5GB', '5GB'],
        [
          tooltipFc(
            '存储容量',
            '',
            '团队版的计算方式，假设10人团队空间，存储容量为：300GB+150GB*10=1800GB'
          ),
          PersonSpaceFreeCapacity,
          '200GB',
          '300GB',
          '300GB+150GB/人',
        ],
        ['思维导图节点数', '300', '无限', '无限', '无限'],
        ['单个块字数限制', '100000', '100000', '100000', '100000'],
      ],
    },
    // 基础
    {
      title: '基础',
      data: [
        [
          tooltipFc(
            '多端同步（Web+APP）',
            '',
            '支持全平台客户端使用，Windows / Mac / iOS / Android'
          ),
          selected,
          selected,
          selected,
          selected,
        ],
        [tooltipFc('全文搜索', '', '页面内容层面的搜索'), selected, selected, selected, selected],
        [
          tooltipFc('页面动态', '', '支持查看页面所有具体编辑记录，便于协作时追溯'),
          selected,
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc(
            '页面历史',
            '',
            '页面每隔一段时间会保存页面版本，可查看或返回到过去任意一个版本'
          ),
          '',
          '30天',
          '60天',
          '180天',
        ],
        [
          tooltipFc(
            '引用建议',
            '',
            '当页面内容与其它页面标题存在隐含关联时进行的自动推荐，辅助知识点串联'
          ),
          '',
          selected,
          selected,
          selected,
        ],
        [tooltipFc('文件批量下载'), '', selected, selected, selected],
      ],
    },
    // 分享与协作
    {
      title: '分享与协作',
      data: [
        [
          tooltipFc('分享链接', '', '支持公开分享页面 / 文件夹 / 单文件'),
          selected,
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('分享链接密码访问', '', '支持为公开分享的页面设置访问密码，就像网盘分享一样'),
          selected,
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('自定义空间域名', '', '如，英文域名.flowus.cn 的自定义空间域名'),
          selected,
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('分享页支持订阅', '', '其他人登陆后可以订阅此页面'),
          selected,
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('分享链接有效期', '', '有效期过后分享会自动关闭'),
          '',
          selected,
          selected,
          selected,
        ],
        [tooltipFc('关闭分享页底部广告'), '', selected, selected, selected],
        [
          tooltipFc('单页面外部协作', '', '单个页面可加入的外部协作者人数上限'),
          '5',
          '1000',
          '1000',
          '1000',
        ],
        [
          tooltipFc('公共模板', '', '将页面设置为可快速创建的公共模板'),
          '5',
          '无限',
          '无限',
          '无限',
        ],
        [
          tooltipFc('公开主页', '', '如，英文域名.flowus.cn 的自定义空间域名'),
          '',
          selected,
          selected,
          selected,
        ],
        ['团队协作', '', '', selected, selected],
        ['团队空间', '', '', selected, selected],
      ],
    },
    // 安全与权限管理
    {
      title: '安全与权限管理',
      data: [
        ['页面权限管理', '', '', selected, selected],
        ['空间成员管理', '', '', selected, selected],
        ['空间成员分组', '', '', '', selected],
        [
          <div className="whitespace-normal">空间安全设置(空间水印/禁止协作分享等)</div>,
          '',
          '',
          '',
          selected,
        ],
      ],
    },
    // 更多功能
    {
      title: '更多功能',
      data: [
        [
          tooltipFc(
            '多维表全部属性',
            `${CURRENT_DOMAIN}/share/6a17bdee-5155-4569-9715-5ad894b0f4dd`,
            '支持18种属性类型，广泛适用于各类场景'
          ),
          selected,
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc(
            '多维表全部视图',
            `${CURRENT_DOMAIN}/share/6a17bdee-5155-4569-9715-5ad894b0f4dd`,
            '支持7种视图，同组数据多种展现形式'
          ),
          selected,
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('多维表单附件', '', '多维表媒体属性上传单个文件附件的大小限制'),
          '2MB',
          '5GB',
          '5GB',
          '5GB',
        ],
        [
          tooltipFc(
            '文件/文件夹管理',
            `${CURRENT_DOMAIN}/share/fa4ab5ea-075f-4ccc-8e70-a112186310f3`,
            '支持在页面中插入文件，或通过文件夹形式管理文件'
          ),
          selected,
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc(
            '引用页面和双向链接',
            `${CURRENT_DOMAIN}/share/ef1a1829-baf5-4d92-bb9f-55a16e07219f`,
            '支持引用已有页面以及查看被引用情况，便于网状式管理内容'
          ),
          selected,
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc(
            '文件预览',
            `${CURRENT_DOMAIN}/share/771003b8-40d9-4f59-8d3a-99a690338fbe`,
            '支持PDF/Office/图片/视频等类型在线预览'
          ),
          selected,
          selected,
          selected,
          selected,
        ],
        // [tooltipFc('文件编辑（Office）'), '', selected, selected, selected],
        [tooltipFc('微信剪藏'), selected, selected, selected, selected],
        [
          tooltipFc('全部模板', `${CURRENT_DOMAIN}/template`, '通过借鉴优质模板，更好更快上手使用'),
          selected,
          selected,
          selected,
          selected,
        ],
        ['夜间模式', selected, selected, selected, selected],
        [tooltipFc('字体/页面排版'), '', selected, selected, selected],
        ['拷贝到空间', '3次/月', '无限', '无限', '无限'],
      ],
    },
  ];

  const binPriceData = [
    // 用量
    {
      title: '用量',
      data: [
        [tooltipFc('页面/块数量'), '无限', '无限', '无限'],
        [
          tooltipFc('空间成员人数', '', '可邀请团队伙伴加入空间实现团队内部的高效协作。'),
          '1',
          '1',
          '无限',
        ],
        [
          tooltipFc(
            '外部协作人数',
            '',
            '可邀请外部临时人员加入空间的指定页面进行协作，适用于跨团队协作和客户沟通等场景。'
          ),
          '上限5人',
          '上限 100 人',
          '上限 1000 人',
        ],
        [
          tooltipFc('空间存储容量', '', '空间所有图片或文件附件的容量限制。'),
          '2GB',
          '上限 1TB',
          '上限 1TB',
        ],
        [
          tooltipFc('单个文件上传', '', '上传单个图片或文件附件的大小限制。'),
          '上限 5MB',
          '上限 5GB',
          '上限 5GB',
        ],
        [tooltipFc('思维导图节点数', '', '思维导图可添加的节点数量限制。'), '300', '无限', '无限'],
        ['单个块字数限制', '100000', '100000', '100000'],
      ],
    },
    // 基础
    {
      title: '基础',
      data: [
        [
          tooltipFc(
            '多端同步（Web+APP）',
            '',
            '支持全平台客户端使用，支持 iOS / Android 实时同步。'
          ),
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('全文搜索', '', '支持全局搜索空间内所有页面文本。'),
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('页面动态', '', '页面每隔一段时间会保存编辑动态。'),
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc(
            '页面历史',
            '',
            '页面每隔一段时间会保存页面版本，可查看或返回到过去任意一个版本。'
          ),
          '',
          '30天',
          '180天',
        ],
        [
          tooltipFc(
            '引用建议',
            '',
            '当页面内容与其它页面标题存在隐含关联时进行的自动推荐，辅助知识点串联。'
          ),
          '',
          selected,
          selected,
        ],
        [tooltipFc('文件批量下载'), '', selected, selected],
      ],
    },
    // 分享与协作
    {
      title: '分享与协作',
      data: [
        [
          tooltipFc('分享链接', '', '支持公开分享页面 / 文件夹 / 单文件'),
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('分享链接密码访问', '', '支持为公开分享的页面设置访问密码，就像网盘分享一样'),
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('自定义空间域名', '', '如，英文域名.buildin.ai 的自定义空间域名'),
          selected,
          selected,
          selected,
        ],
        ['关闭分享页底部广告', '', selected, selected],
        [tooltipFc('公共模板', '', '将页面设置为可快速创建的公共模板。'), '5', '无限', '无限'],
        ['公开主页', '', selected, selected],
        ['团队协作', '', '', selected],
        ['团队空间', '', '', selected],
      ],
    },
    // 安全与权限管理
    {
      title: '安全与权限管理',
      data: [
        ['页面权限管理', '', '', selected],
        ['空间成员管理', '', '', selected],
        ['空间成员分组', '', '', selected],
      ],
    },
    // 更多功能
    {
      title: '更多功能',
      data: [
        [
          tooltipFc('多维表全部属性', '', '支持18种属性类型，广泛适用于各类场景'),
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('多维表全部视图', '', '支持7种视图，同组数据多种展现形式'),
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('多维表附件上传', '', '多维表媒体属性上传单个附件的大小限制。'),
          '上限 5MB',
          '上限 5GB',
          '上限 5GB',
        ],
        [
          tooltipFc('文件/文件夹管理', '', '支持在页面中插入文件，或通过文件夹形式管理文件。'),
          selected,
          selected,
          selected,
        ],
        [
          tooltipFc('文件预览（Office、PDF等）', ``, '支持PDF/Office/图片/视频等类型在线预览'),
          selected,
          selected,
          selected,
        ],
        ['夜间模式', selected, selected, selected],
        ['字体/页面排版', '', selected, selected],
      ],
    },
  ];

  if (__BUILD_IN__) {
    return binPriceData;
  }

  return fuPriceData;
};

const useAiPlanPrice = () => {
  return [
    {
      title: '用量',
      data: [
        [tooltipFc('GPT-3.5 请求'), '每天 10 条', '每月 3000 条'],
        [
          tooltipFc('阅读 PDF'),
          '每月 2 文件\n每文件 200 页以内',
          '每天 50 文件\n每文件 2000 页以内',
        ],
      ],
    },
  ];
};

interface PriceTableProps {
  type: 'spacePlan' | 'ai';
}
export const PriceTable: FC<PriceTableProps> = (props) => {
  const { type = 'spacePlan' } = props;
  const spacePlanPrice = useSpacePlanPrice();
  const aiPlanPrice = useAiPlanPrice();

  const priceData = useMemo(() => {
    if (type === 'spacePlan') return spacePlanPrice;
    if (type === 'ai') return aiPlanPrice;
    return spacePlanPrice;
  }, [aiPlanPrice, spacePlanPrice, type]);

  return (
    <>
      {/* 价格表 */}
      {priceData.map((planData, dataIndex) => {
        return (
          <Fragment key={dataIndex}>
            <div className="border-grey5 border-b h-9 px-2 flex items-center text-t2-medium text-grey3">
              {planData.title}
            </div>
            {planData.data.map((item, index) => (
              <div
                key={`${dataIndex}-${index}`}
                className={cx(
                  'text-t2 border-grey5 border-b grid',
                  dataIndex === priceData.length - 1 &&
                    index === planData.data.length - 1 &&
                    'border-none'
                )}
                style={{
                  gridTemplateColumns: `repeat(${item.length}, minmax(0, 1fr))`,
                }}
              >
                {item.map((value, valueIndex) => (
                  <span
                    key={valueIndex}
                    className={cx(
                      'flex items-center px-2 flex-shrink-0 py-2 min-h-[36px] border-grey5 border-l whitespace-pre',
                      valueIndex === 0 && 'text-grey3 border-l-0',
                      valueIndex % 2 !== 0 && 'bg-black_003'
                    )}
                  >
                    {value}
                  </span>
                ))}
              </div>
            ))}
          </Fragment>
        );
      })}
    </>
  );
};
