import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import type { CellViewProps } from '../types';
import { Site } from '../types';
import { getRelationRecords } from './get-relation-records';
import { useBlock } from 'src/hooks/block/use-block';

export const RelationValue: FC<CellViewProps> = ({ className, recordId, propertyId, site }) => {
  const { relationRecords } = useRelationRecords(recordId, propertyId);

  if (relationRecords.length === 0) return null;

  return (
    <div
      className={cx(
        (site === Site.CELL || site === Site.FIELD) && 'w-full h-full p-2 flex flex-wrap',
        (site === Site.CALENDAR || site === Site.LIST) && 'inline',
        site === Site.CARD && 'flex flex-wrap w-full overflow-hidden',
        className
      )}
    >
      {relationRecords.map((uuid: string) => {
        return (
          <InlinePage
            interactable
            uuid={uuid}
            key={uuid}
            autoIconSize={site !== Site.CELL && site !== Site.FIELD}
            className={cx(
              'leading-none p-0 mr-2 whitespace-nowrap',
              site === Site.CARD && 'text-ellipsis'
            )}
          />
        );
      })}
    </div>
  );
};

export const useRelationRecords = (recordId: string, propertyId: string) => {
  const block = useBlock(recordId);
  return useObservableStore(
    ({ blocks }) => {
      return getRelationRecords(recordId, propertyId, blocks);
    },
    [recordId, propertyId, block?.data.collectionProperties]
  );
};
