import { cx } from '@flowus/common/cx';
import type { PlanDTO, SpaceDTO } from '@next-space/fe-api-idl';
import { useDebounceEffect } from 'ahooks';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'src/common/components/button';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { request } from 'src/common/request';
import { getDateUnit, PayCycleButton, usePayCycleButton } from 'src/components/pay-cycle-button';
import { getPlanList } from 'src/components/select-upgrade-plan/hook/use-upgrade-support';
import { getCurrentSpace, useCurrentSpace } from 'src/hooks/space';
import { useCurrentSpaceUserSize } from 'src/hooks/space/use-current-space-users';
import { useGetSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { useIsTeamSpace } from 'src/hooks/space/use-is-team-space';
import { useCurrentUser } from 'src/hooks/user';
import { SpacePlanType } from 'src/redux/types';
import { useLimitConfig } from 'src/services/app/hook/subscription-data';
import { ViewPath } from 'src/utils';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { getCurrencySymbols } from 'src/utils/currency-format';
import type { AsyncReturnType } from 'type-fest';
import { useCloseSettingModal } from '../use-open-setting-modal';
import { useOpenUpgradePlan } from '../use-open-upgrade-plan';
import { PriceTable } from './price-data';
import { divide, round } from 'lodash-es';
import { useSetting } from '../common';
import { OpenSettingFrom } from '../type';
import { SMART_TEAM_MAX_PEOPLE } from '@flowus/common/const';

const validSpacePlanType = (plan: string) => {
  return getCurrentSpace().planType === plan;
};

// #region type
type QuoteValueType = AsyncReturnType<typeof request.infra.getQuote>;

const defaultCouponInfo: {
  smallTeam: { label?: string };
  team: { label?: string };
  personal: { label?: string };
} = {
  smallTeam: {},
  team: {},
  personal: {},
};
const defaultCalculateQuote: {
  team: QuoteValueType | undefined;
  personal: QuoteValueType | undefined;
  smallTeam: QuoteValueType | undefined;
} = {
  team: undefined,
  personal: undefined,
  smallTeam: undefined,
};
// #endregion

// #region hook
export const useSpacePlanDataHeader = ({ isFrom = OpenSettingFrom.package }) => {
  const openModal = useOpenModal();
  const currentSpace = useCurrentSpace();
  const isTeamSpace = useIsTeamSpace();
  const isTeam = validSpacePlanType(SpacePlanType.team);
  const isSmall = validSpacePlanType(SpacePlanType.smallTeam);
  const currentUser = useCurrentUser();
  const getSpaceRolePermission = useGetSpaceRolePermission();
  const { editor } = getSpaceRolePermission(currentUser.uuid);
  const currentSpaceUserSize = useCurrentSpaceUserSize();
  const limitConfig = useLimitConfig();
  const [planList, setPlanList] = useState<PlanDTO[]>([]);
  const [couponInfo, setCouponInfo] = useState(defaultCouponInfo);
  const { switchPayType, setSwitchPayType } = usePayCycleButton({
    defaultTimeType: __BUILD_IN__ ? 'year' : 'all',
  });
  const [selectPlanType, setSelectPlanType] = useState<SpacePlanType>(
    calculateSelectPlanType(currentSpace.planType)
  );

  const openUpgradePlan = useOpenUpgradePlan();
  const closeSettingModal = useCloseSettingModal();

  /** 后端计算的价格 */
  const [calculateQuote, setCalculateQuote] = useState(defaultCalculateQuote);

  // 从后端获取套餐的价格
  useEffect(() => {
    void getPlanList(currentSpace.uuid, 'all').then(
      (res) => res.list?.length && setPlanList(res.list)
    );
  }, [currentSpace.uuid]);

  useEffect(() => {
    // 如果space变了就需要默认选一个套餐，这个目前只有改版后的升级页面用得到。
    setSelectPlanType(calculateSelectPlanType(currentSpace.planType));
  }, [currentSpace.planType]);

  // 通过支付方式查找对应的套餐列表
  const getPlanTypeData = useCallback(
    (spacePlanType: SpacePlanType) => {
      const list = planList.filter((p) => {
        if (switchPayType === 'month') {
          return p.monthNum === 1;
        }
        if (switchPayType === 'year') {
          return p.monthNum === 12;
        }
        return true;
      });
      return list.find((i) => i.spaceType === spacePlanType);
    },
    [planList, switchPayType]
  );

  const getPriceAndUnit = useCallback(
    (quoteKey: keyof typeof calculateQuote, plan?: PlanDTO) => {
      const cur = calculateQuote[quoteKey];
      const curPrice = cur?.amount ?? plan?.currentPrice ?? plan?.originalPrice;
      const curPriceUnit = cur?.amountUnit ?? plan?.currentPriceUnit ?? plan?.originalPriceUnit;
      return [curPrice, curPriceUnit];
    },
    [calculateQuote]
  );

  // 两种tab的价格内容
  const playTypeData = useMemo(() => {
    // 专业版兑换套餐
    const personalPro = getPlanTypeData(SpacePlanType.personal);

    // 团队版兑换套餐
    const teamOfCash = getPlanTypeData(SpacePlanType.team);

    //  小组版兑换套餐
    const smallOfCash = getPlanTypeData(SpacePlanType.smallTeam);

    const [smallCurPrice, smallCurPriceUnit] = getPriceAndUnit('smallTeam', smallOfCash);
    const [teamCurPrice, teamCurPriceUnit] = getPriceAndUnit('team', teamOfCash);
    const [personalCurPrice, personalCurPriceUnit] = getPriceAndUnit('personal', personalPro);

    //  个人允许升级为小组版
    const allowUpgradeSmallTeam =
      currentSpace.planType &&
      [
        SpacePlanType.smallTeam,
        SpacePlanType.personal,
        SpacePlanType.freePersonal,
        SpacePlanType.freeTeam,
      ].includes(currentSpace.planType as SpacePlanType);

    return [
      //  个人套餐
      [
        {
          //  个人版
          hidden: false,
          type: SpacePlanType.freePersonal,
          originalPrice: 0,
          currentPrice: 0,
          description: getSpacePlanTypeName(SpacePlanType.freePersonal),
        },
        {
          //  个人专业版
          hidden: false,
          type: SpacePlanType.personal,
          currentPrice: personalCurPrice,
          currentPriceUnit: getCurrencySymbols(personalCurPriceUnit),
          originalPrice: personalPro?.originalPrice,
          originalPriceUnit: getCurrencySymbols(
            !isTeamSpace ? personalCurPriceUnit : personalPro?.originalPriceUnit
          ),
          description: getSpacePlanTypeName(SpacePlanType.personal),
          updatePlan: !isTeamSpace,
          label: __BUILD_IN__ ? '早鸟优惠20%' : !isTeamSpace ? couponInfo?.personal.label : '',
          id: personalPro?.id,
          unit: getDateUnit(personalPro?.monthNum, { prefix: '/' }),
          tips: null,
        },
      ],
      //  团队套餐
      [
        {
          //  小组版
          hidden: __BUILD_IN__,
          type: SpacePlanType.smallTeam,
          currentPrice: smallCurPrice,
          currentPriceUnit: getCurrencySymbols(smallCurPriceUnit),
          originalPrice: smallOfCash?.originalPrice,
          originalPriceUnit: getCurrencySymbols(smallOfCash?.originalPriceUnit),
          description: getSpacePlanTypeName(SpacePlanType.smallTeam),
          updatePlan: allowUpgradeSmallTeam,
          label: !isTeam ? couponInfo?.smallTeam.label : '',
          id: smallOfCash?.id,
          unit: getDateUnit(smallOfCash?.monthNum, { prefix: '/' }),
        },
        {
          //  团队版
          hidden: false,
          type: SpacePlanType.team,
          currentPrice: teamCurPrice,
          currentPriceUnit: getCurrencySymbols(teamCurPriceUnit),
          originalPrice: teamOfCash?.originalPrice,
          originalPriceUnit: getCurrencySymbols(teamOfCash?.originalPriceUnit),
          description: getSpacePlanTypeName(SpacePlanType.team),
          updatePlan: isTeamSpace || __BUILD_IN__,
          label: __BUILD_IN__ ? '早鸟优惠20%' : isTeamSpace ? couponInfo?.team?.label : '',
          id: teamOfCash?.id,
          unit: getDateUnit(teamOfCash?.monthNum, { prefix: '/' }),
          tips: !isTeamSpace && __BUILD_IN__ && (
            <NavLink to={ViewPath.create} onClick={() => closeSettingModal()}>
              免费开通团队试用版
            </NavLink>
          ),
        },
      ],
    ];
  }, [
    closeSettingModal,
    couponInfo?.personal.label,
    couponInfo?.smallTeam.label,
    couponInfo?.team?.label,
    currentSpace.planType,
    getPlanTypeData,
    getPriceAndUnit,
    isTeam,
    isTeamSpace,
  ]);

  const planData = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      proPerson: playTypeData[0]![1]!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      freePerson: playTypeData[0]![0]!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      smallTeam: playTypeData[1]![0]!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      team: playTypeData[1]![1]!,
    }),
    [playTypeData]
  );

  const upgradePlan = useCallback(
    (plan: SpacePlanType) => {
      if (plan === SpacePlanType.smallTeam) {
        if (currentSpaceUserSize > limitConfig.membersMaxLimit) {
          openModal.warning({
            title: `小组版空间成员人数上限${limitConfig.membersMaxLimit}人，请移出超出成员后重试升级。`,
            confirmText: '知道了',
            noCancel: true,
          });
          return;
        }
      }
      openUpgradePlan(plan, isFrom);
    },
    [currentSpaceUserSize, isFrom, limitConfig.membersMaxLimit, openModal, openUpgradePlan]
  );

  const checkUpgradePlan = useCallback(
    (plan: SpacePlanType) => {
      if (plan === SpacePlanType.smallTeam) {
        if (currentSpaceUserSize > limitConfig.membersMaxLimit) {
          openModal.warning({
            title: `小组版空间成员人数上限${limitConfig.membersMaxLimit}人，请移出超出成员后重试升级。`,
            confirmText: '知道了',
            noCancel: true,
          });
          return false;
        }
      }
      return true;
    },
    [currentSpaceUserSize, limitConfig.membersMaxLimit, openModal]
  );

  const getPrice = useCallback(
    async (planId: string, people = 1) => {
      const couponRes = await request.infra.getCouponList(planId, undefined, currentSpace.uuid);

      const coupon = couponRes.list?.[0];
      const label = coupon?.label;

      const params = {
        spaceId: currentSpace.uuid,
        planId,
        couponId: coupon?.id,
        date: 1,
        people,
      };

      const personalRes = await request.infra.getQuote(params);

      return {
        quote: personalRes,
        label,
      };
    },
    [currentSpace.uuid]
  );

  const [smallTeamPlanId, personalPlanId, teamPlanId] = useMemo(() => {
    return [planData.smallTeam.id, planData.proPerson.id, planData.team.id];
  }, [planData?.proPerson.id, planData?.smallTeam.id, planData?.team.id]);

  useDebounceEffect(
    () => {
      const newCalculateQuote = { ...defaultCalculateQuote };
      const newCouponInfo = { ...defaultCouponInfo };

      void (async () => {
        if (teamPlanId) {
          const res = await getPrice(teamPlanId, currentSpaceUserSize);
          if (isTeamSpace) {
            // @ts-ignore idl
            const unitPrice = round(divide(res.quote.amount ?? 0, res.quote.increasedPeople), 1);
            newCalculateQuote['team'] = { ...res.quote, amount: unitPrice };
          }
          newCouponInfo['team'] = { label: res.label };
        }

        if (isTeam) return;
        if (smallTeamPlanId) {
          const res = await getPrice(smallTeamPlanId, SMART_TEAM_MAX_PEOPLE);
          newCalculateQuote['smallTeam'] = res.quote;
          newCouponInfo['smallTeam'] = { label: res.label };
        }

        if (personalPlanId && !isSmall) {
          const res = await getPrice(personalPlanId);
          newCalculateQuote['personal'] = res.quote;
          newCouponInfo['personal'] = { label: res.label };
        }
      })().then(() => {
        setCalculateQuote(newCalculateQuote);
        setCouponInfo(newCouponInfo);
      });
    },
    [
      smallTeamPlanId,
      personalPlanId,
      teamPlanId,
      isTeam,
      isSmall,
      isTeamSpace,
      getPrice,
      currentSpaceUserSize,
    ],
    { wait: 200 }
  );

  return {
    playTypeData,
    planData,
    planList,
    upgradePlan,
    editor,
    switchPayType,
    selectPlanType,
    setSelectPlanType,
    setSwitchPayType,
    checkUpgradePlan,
  };
};
// #endregion

// #region render
export const UpgradeSettingContent: FC = () => {
  return (
    <div className={'shrink-0 transition-opacity min-w-[720px]'}>
      {/* 付费升级按钮区域 */}
      <PlanDataHeader />
      <PriceTable type="spacePlan" />
    </div>
  );
};

const PlanDataHeader: FC = () => {
  const { isFrom } = useSetting();
  const { playTypeData, upgradePlan, editor, switchPayType, setSwitchPayType, planList } =
    useSpacePlanDataHeader({ isFrom });
  return (
    <div
      className={cx(
        'grid border-b border-grey5 opacity-0 transition-opacity',
        __BUILD_IN__ ? 'grid-cols-4' : 'grid-cols-5',
        planList.length && 'opacity-100'
      )}
    >
      <PayCycleButton onSwitch={setSwitchPayType} payType={switchPayType} />
      {playTypeData.map((data) =>
        data.map((item, index) => {
          const currentPlanType = validSpacePlanType(item.type);
          const disabled = item.currentPrice !== 0 && !item.currentPrice;
          const isTeam = item.type === SpacePlanType.team;
          const currentIsFreeTeam =
            validSpacePlanType(SpacePlanType.freeTeam) && item.type === SpacePlanType.smallTeam;

          if (item.hidden) return null;
          let btn = <></>;

          if (item.updatePlan) {
            if (!disabled) {
              btn = (
                <Tooltip
                  className="flex-1 flex text-t2-medium"
                  popup={editor ? '' : '仅管理员可操作'}
                >
                  <>
                    {currentIsFreeTeam && (
                      <Button colorType="secondary" className="pointer-events-none w-14 mr-2.5">
                        试用中
                      </Button>
                    )}
                    <Button
                      disable={!editor}
                      colorType="active"
                      className="flex-1"
                      onClick={() => upgradePlan(item.type)}
                    >
                      {currentPlanType ? '续费' : '升级'}
                    </Button>
                  </>
                </Tooltip>
              );
            }
          } else {
            btn = (
              <Tooltip
                className="cursor-not-allowed flex-1"
                popup={currentPlanType ? undefined : '当前空间暂不支持升级到该空间计划'}
              >
                <Button className="text-t2-medium pointer-events-none w-full bg-white text-grey3">
                  {currentPlanType ? '当前版本' : '升级'}
                </Button>
              </Tooltip>
            );
          }

          let tag = <></>;
          if (item.label) {
            tag = (
              <div className="mb-5 bg-red w-fit text-white rounded-sm py-px px-1.5 text-t3 flex justify-center">
                {item.label}
              </div>
            );
          }

          return (
            <div
              key={`${item}-${index}`}
              className={cx(
                'flex flex-col justify-between border-l border-grey5 p-2.5 flex-grow-0 flex-shrink-0',
                index % 2 === 0 && 'bg-black_003'
              )}
            >
              <div className="flex-1">
                <div className="text-h4 mb-5">{item.description}</div>
                <div className="mb-5">
                  <span
                    className={cx(
                      'text-h2',
                      item.currentPrice !== 0 && !item.currentPrice && 'text-grey4'
                    )}
                  >
                    {disabled
                      ? '暂未公布'
                      : item.currentPrice === 0
                      ? '免费'
                      : `${item.currentPriceUnit}${item.currentPrice}`}
                  </span>
                  <span className="text-t1 text-grey4" hidden={!item.currentPrice}>
                    {isTeam ? `${item.unit}/人` : item.unit}
                  </span>
                  <div
                    hidden={__BUILD_IN__}
                    className={cx(
                      'text-t1 text-grey4',
                      (!item.currentPrice ||
                        !item.originalPrice ||
                        item.currentPrice >= item.originalPrice) &&
                        'opacity-0'
                    )}
                  >
                    <span className="line-through">
                      {item.originalPriceUnit}
                      {item.originalPrice}
                    </span>
                    {isTeam ? `人${item.unit}` : item.unit}
                  </div>
                </div>
              </div>
              <>{tag}</>
              {/*  升级按钮 */}
              <div>{btn}</div>
              <div className="h-5 text-t3 text-grey3 mt-2.5">{item.tips}</div>
            </div>
          );
        })
      )}
    </div>
  );
};
// #endregion

const calculateSelectPlanType = (planType?: SpaceDTO['planType']) => {
  if (planType === 'freePersonal' || planType === 'personal') {
    return SpacePlanType.personal;
  }
  if (planType === 'freeTeam' || planType === 'smallTeam') {
    return SpacePlanType.smallTeam;
  }
  if (planType === 'team') {
    return SpacePlanType.team;
  }
  return SpacePlanType.personal;
};
