import { arrayMove } from '@dnd-kit/sortable';
import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import React, { useContext, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { PropertyValues } from 'src/bitable/board-view/card/property-values';
import { useBitable } from 'src/bitable/context';
import { getDatePropertyFromBlock } from 'src/bitable/table-view/cell/helpers';
import { RecordCreatedFrom } from 'src/bitable/table-view/types';
import { Icon } from 'src/common/components/icon';
import { ILLEGAL_TEXT, SHORT_DATE_FORMAT, UNTITLED } from 'src/common/const';
import { IconTrigger } from 'src/components/icon-trigger';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { buildDateSegment } from 'src/editor/utils/segments';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useBlock } from 'src/hooks/block/use-block';
import { checkPageSort } from 'src/hooks/block/use-insert-record';
import { useRecordBgColor } from 'src/hooks/block/use-record-color';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { getValuesFromGroupValue } from 'src/hooks/collection-view/get-values-from-groupvalue';
import { useReadonly } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { getState } from 'src/redux/store';
import { setAppUiState, useNewCreatedRecord } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { getDateTimeStamp } from 'src/utils/date-utils';
import { numberToPercent } from 'src/utils/number';
import { Direction, DirectionMap, ONE_DAY, UnitWidthMap, ZoomLevel } from '../const';
import { useTimeline } from '../context';
import { Indicator } from '../indicator';
import { autoScrollX, autoScrollY, rafX, rafY } from '../utils/auto-scroll';
import { addIndicator, createOverLay } from '../utils/dom';
import {
  getDateMomentStamp,
  getUnitLength,
  getUnitTime,
  isDateTime,
  unitIsMoment,
  updateDate,
} from '../utils/get-timeline-dates';
import { NoBindIdContext } from 'src/editor/editor/raw/no-bind-id-context';

interface Props {
  recordId: string;
  card: React.MutableRefObject<HTMLDivElement | null>;
}

let timer: NodeJS.Timeout | undefined;

export const useRenderTime = (recordId: string) => {
  const { zoomLevel } = useTimeline();
  const { viewId, collectionId } = useBitable();
  const isDayOrHour = zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR;

  return useObservableStore(
    ({ collectionViews, blocks }) => {
      const view = collectionViews[viewId];
      if (!view) return;

      const timelineBy = view.format.timelineBy ?? '';
      const timelineByEnd = view.format.timelineByEnd ?? '';
      const collection = blocks[collectionId];
      const block = blocks[recordId];
      if (!collection || !block) return;

      const timelineBySchema = collection.data.schema?.[timelineBy];
      const timelineByEndSchema = collection.data.schema?.[timelineByEnd];
      if (!timelineBySchema || !timelineByEndSchema) return;

      let recordStartTime: number | undefined;
      let recordEndTime: number | undefined;
      let startTimeType = TextType.DATE;
      let endTimeType = TextType.DATE;
      if (
        timelineBySchema.type === CollectionSchemaType.CREATED_AT ||
        timelineBySchema.type === CollectionSchemaType.UPDATED_AT
      ) {
        startTimeType = TextType.DATETIME;
        recordStartTime =
          timelineBySchema.type === CollectionSchemaType.CREATED_AT
            ? block.createdAt
            : block.updatedAt;
      } else if (timelineBySchema.type === CollectionSchemaType.DATE) {
        const result = getDatePropertyFromBlock(block, timelineBy);
        startTimeType = result?.textType ?? TextType.DATE;
        recordStartTime = result?.timestamp;
      }

      if (
        timelineByEndSchema.type === CollectionSchemaType.CREATED_AT ||
        timelineByEndSchema.type === CollectionSchemaType.UPDATED_AT
      ) {
        endTimeType = TextType.DATETIME;
        recordEndTime =
          timelineByEndSchema.type === CollectionSchemaType.CREATED_AT
            ? block.createdAt
            : block.updatedAt;
      } else if (timelineByEndSchema.type === CollectionSchemaType.DATE) {
        const result = getDatePropertyFromBlock(block, timelineByEnd);
        endTimeType = result?.textType ?? TextType.DATE;
        recordEndTime = result?.timestamp;
      }

      if (!recordStartTime && !recordEndTime) return;

      let renderStartTime = recordStartTime || recordEndTime;
      let renderEndTime = recordEndTime;
      if (recordStartTime && recordEndTime) {
        renderEndTime = recordEndTime >= recordStartTime ? recordEndTime : recordStartTime;
      }
      if (!recordEndTime) {
        renderEndTime = recordStartTime;
      }

      if (!renderStartTime || !renderEndTime) return;

      if (isDayOrHour) {
        if (startTimeType === TextType.DATE) {
          renderStartTime = getDateTimeStamp(renderStartTime);
        }
        if (endTimeType === TextType.DATE) {
          renderEndTime = getDateTimeStamp(renderEndTime) + ONE_DAY;
        }
      }

      if (!renderStartTime || !renderEndTime) return;

      return {
        timelineBy,
        timelineByEnd,
        renderStartTime,
        renderEndTime,
      };
    },
    [viewId, collectionId, recordId]
  );
};

export const RecordCard: FC<Props> = ({ recordId, card }) => {
  const {
    container,
    datesContainer,
    timelineDatesRef,
    draggingRecordId,
    zoomLevel,
    containerWidth,
  } = useTimeline();
  const readonly = useReadonly(recordId, false);
  const { viewId, managerReadonly } = useBitable();
  const transaction = useTransaction();
  const openPage = useOpenPage();
  const block = useBlock(recordId);
  const [draggingNewDate, setDraggingNewDate] = useState<number>();
  const [translateNewDate, setTranslateNewDate] = useState<number>();
  const [draggingDirection, setDraggingDirection] = useState<Direction>();
  const { illegal } = usePermissions(recordId);
  const updatePropertyValue = useUpdatePropertyValue();
  const lineRef = useRef<HTMLDivElement>(null);
  const mouseDownPos = useRef({ x: 0, y: 0 });
  const renderTime = useRenderTime(recordId);
  const bgColor = useRecordBgColor(viewId, recordId);
  const newCreatedRecord = useNewCreatedRecord();
  const canBindId = useContext(NoBindIdContext);

  if (!renderTime) return null;

  let { renderStartTime, renderEndTime } = renderTime;
  const { timelineBy, timelineByEnd } = renderTime;

  const showIcon = Boolean(block?.data.icon?.type);
  const unitWidth = UnitWidthMap[zoomLevel];
  const isDayOrHour = zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR;

  const isNewCreateRecordId =
    recordId === newCreatedRecord?.id &&
    viewId === newCreatedRecord.viewId &&
    newCreatedRecord.from === RecordCreatedFrom.TIMELINE;

  const handleTranslate = (event: React.MouseEvent) => {
    event.preventDefault();
    if (readonly || managerReadonly) return;

    const datesNode = datesContainer.current;
    const containerNode = container.current;
    const cardNode = card.current;
    const lineNode = lineRef.current;
    const nextPageNode = (event.currentTarget as HTMLDivElement).closest(
      '.next-space-page'
    ) as HTMLDivElement | null;
    if (!nextPageNode || !cardNode || !containerNode || !lineNode || !datesNode) return;

    mouseDownPos.current = { x: event.clientX, y: event.clientY };
    let lastMousePos = { x: event.clientX, y: event.clientY };
    const cardRect = cardNode.getBoundingClientRect();
    const cardWidth = cardRect.width;
    const cursorToCardLeft = event.clientX - cardRect.left;
    let newStartDate: number;
    let isMoving = false;
    let overlayContainer: HTMLDivElement | undefined;
    let target: HTMLElement | undefined;
    let position: 'top' | 'bottom' | undefined;

    const handleMouseMove = (event: MouseEvent) => {
      lastMousePos = { x: event.clientX, y: event.clientY };

      if (!isMoving) {
        if (
          Math.abs(event.clientX - mouseDownPos.current.x) < 5 &&
          Math.abs(event.clientY - mouseDownPos.current.y) < 5
        ) {
          return;
        }

        isMoving = true;
        draggingRecordId.current = recordId;
        overlayContainer = createOverLay(lineNode);
      }

      autoScrollX(event.clientX, containerNode);
      autoScrollY({ y: event.clientY, container: nextPageNode });

      updateMoveState(event.clientX, event.clientY);
    };

    const handleMouseUp = () => {
      nextPageNode.removeEventListener('scroll', handleScroll);
      containerNode.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      cancelAnimationFrame(rafX);
      cancelAnimationFrame(rafY);
      overlayContainer?.remove();
      setTranslateNewDate(undefined);
      draggingRecordId.current = undefined;
      if (target && target.lastElementChild?.classList.contains('indicator')) {
        target.lastElementChild.remove();
      }
      if (!isMoving || !block) return;

      const targetId = target?.dataset.recordId;
      const needSort = targetId && targetId !== recordId;
      transaction(() => {
        const { timelineBy, timelineByEnd } = getViewFormat(viewId) ?? {};
        if (!timelineBy || !timelineByEnd) return;

        const newEndTime =
          newStartDate +
          getUnitTime(zoomLevel) *
            (isDayOrHour ? cardWidth / unitWidth : cardWidth / unitWidth - 1);

        const { blocks, collectionViews } = getState();
        const collection = blocks[block.parentId];
        const view = collectionViews[viewId];
        if (!collection || !view) return;
        const startTimeSchema = collection.data.schema?.[timelineBy];
        const endTimeSchema = collection.data.schema?.[timelineByEnd];

        let newPropertyValue: Record<string, SegmentDTO[]> = {};
        if (startTimeSchema?.type === CollectionSchemaType.DATE) {
          const startTimeType = getDatePropertyFromBlock(block, timelineBy)?.textType;
          newPropertyValue[timelineBy] = [
            buildDateSegment({
              from: updateDate(renderStartTime, newStartDate, zoomLevel),
              includeTime: isDateTime(zoomLevel, startTimeType),
            }),
          ];
        }
        if (endTimeSchema?.type === CollectionSchemaType.DATE) {
          const endTimeType = getDatePropertyFromBlock(block, timelineByEnd)?.textType;
          newPropertyValue[timelineByEnd] = [
            buildDateSegment({
              from: updateDate(renderEndTime, newEndTime, zoomLevel),
              includeTime: isDateTime(zoomLevel, endTimeType),
            }),
          ];
        }

        // 分组时
        if (needSort) {
          const currentValue = lineNode.parentElement?.dataset.groupValue;
          const targetGroupValue = target?.dataset.groupValue;
          if (currentValue !== targetGroupValue) {
            const propertyValue = getValuesFromGroupValue({
              viewId,
              groupValue: target?.dataset.groupValue,
            });
            newPropertyValue = {
              ...newPropertyValue,
              ...propertyValue,
            };
          }
        }

        updateBlock(recordId, {
          data: {
            segments: newPropertyValue.title ?? block.data.segments,
            collectionProperties: {
              ...block.data.collectionProperties,
              ...newPropertyValue,
            },
          },
        });

        if (needSort) {
          const { newPageSort } = checkPageSort(view.pageSort, collection.subNodes);
          const oldIndex = newPageSort.indexOf(recordId);
          let newIndex = newPageSort.indexOf(targetId);
          newIndex =
            position === 'top'
              ? oldIndex < newIndex
                ? newIndex - 1
                : newIndex
              : oldIndex < newIndex
              ? newIndex
              : newIndex + 1;

          if (oldIndex !== newIndex) {
            CollectionViewManager.update(viewId, {
              pageSort: arrayMove(newPageSort, oldIndex, newIndex),
            });
          }
        }
      });
    };

    const handleScroll = () => updateMoveState(lastMousePos.x, lastMousePos.y);

    const updateMoveState = (clientX: number, clientY: number) => {
      if (!overlayContainer) return;
      overlayContainer.style.transform = `translate(${clientX - mouseDownPos.current.x}px, ${
        clientY - mouseDownPos.current.y
      }px)`;

      // 日期添加高亮背景
      const containerRect = containerNode.getBoundingClientRect();
      const cursorLeftDistance =
        containerNode.scrollLeft + clientX - containerRect.x - cursorToCardLeft; // 当前光标距离容器最左端的水平距离
      const unitLength = Math.round(cursorLeftDistance / unitWidth);
      newStartDate = (timelineDatesRef.current[0] as number) + unitLength * getUnitTime(zoomLevel);
      setTranslateNewDate(newStartDate);

      const result = addIndicator(containerNode, overlayContainer, recordId);
      target = result.target;
      position = result.position;
    };

    nextPageNode.addEventListener('scroll', handleScroll);
    containerNode.addEventListener('scroll', handleScroll);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = (event: React.MouseEvent, direction: Direction) => {
    if (readonly) return;
    event.preventDefault();
    event.stopPropagation();

    const datesNode = datesContainer.current;
    const containerNode = container.current;
    if (!containerNode || !datesNode) return;
    setDraggingDirection(direction);
    const isLeftDirection = direction === Direction.left;
    let dragEndDate: number | undefined;
    let lastMouseX = event.clientX;

    const handleMouseMove = (event: MouseEvent) => {
      lastMouseX = event.clientX;

      autoScrollX(event.clientX, containerNode);
      draggingRecordId.current = recordId;
      updateDragEndDate(event.clientX);
    };

    const handleMouseUp = () => {
      containerNode.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      cancelAnimationFrame(rafX);
      setDraggingDirection(undefined);
      setDraggingNewDate(undefined);
      draggingRecordId.current = undefined;

      if (!block) return;
      const collection = getState().blocks[block.parentId];
      if (!collection) return;
      transaction(() => {
        if (!dragEndDate) return;

        const { blocks } = getState();
        const collection = blocks[block.parentId];
        if (!collection) return;
        const startTimeSchema = collection.data.schema?.[timelineBy];
        const endTimeSchema = collection.data.schema?.[timelineByEnd];

        const newPropertyValue: Record<string, SegmentDTO[]> = {};
        if (startTimeSchema?.type === CollectionSchemaType.DATE) {
          const startTimeType = getDatePropertyFromBlock(block, timelineBy)?.textType;
          newPropertyValue[timelineBy] = isLeftDirection
            ? [
                buildDateSegment({
                  from: updateDate(renderStartTime, dragEndDate, zoomLevel),
                  includeTime: isDateTime(zoomLevel, startTimeType),
                }),
              ]
            : [
                buildDateSegment({
                  from: new Date(renderStartTime),
                  includeTime: isDateTime(zoomLevel, startTimeType),
                }),
              ];
        }
        if (endTimeSchema?.type === CollectionSchemaType.DATE) {
          const endTimeType = getDatePropertyFromBlock(block, timelineByEnd)?.textType;
          newPropertyValue[timelineByEnd] = isLeftDirection
            ? [
                buildDateSegment({
                  from:
                    renderEndTime > renderStartTime
                      ? new Date(renderEndTime)
                      : updateDate(renderEndTime, renderStartTime, zoomLevel),
                  includeTime: isDateTime(zoomLevel, endTimeType),
                }),
              ]
            : [
                buildDateSegment({
                  from: updateDate(renderEndTime, dragEndDate, zoomLevel),
                  includeTime: isDateTime(zoomLevel, endTimeType),
                }),
              ];
        }

        updateBlock(recordId, {
          data: {
            collectionProperties: {
              ...block.data.collectionProperties,
              ...newPropertyValue,
            },
          },
        });
      });
    };

    const handleScroll = () => updateDragEndDate(lastMouseX);

    const updateDragEndDate = (clientX: number) => {
      const rect = containerNode.getBoundingClientRect();
      const leftDistance = containerNode.scrollLeft + clientX - rect.x;
      const unitLength =
        isLeftDirection || isDayOrHour
          ? Math.round(leftDistance / unitWidth)
          : Math.round(leftDistance / unitWidth) - 1;
      const newDate = (timelineDatesRef.current[0] as number) + unitLength * getUnitTime(zoomLevel);
      if (!newDate) return;

      if (
        (isLeftDirection && newDate <= Math.max(renderStartTime, renderEndTime)) ||
        (!isLeftDirection &&
          newDate >=
            (unitIsMoment(zoomLevel)
              ? getDateMomentStamp(renderStartTime)
              : getDateTimeStamp(renderStartTime)))
      ) {
        dragEndDate = newDate;

        setDraggingNewDate(newDate);
      }
    };

    containerNode.addEventListener('scroll', handleScroll);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  if (draggingNewDate) {
    if (draggingDirection === Direction.left) {
      renderStartTime = draggingNewDate;
    } else {
      renderEndTime = draggingNewDate;
    }
  }
  const left =
    getUnitLength(renderStartTime as number, timelineDatesRef.current[0] as number, zoomLevel, {
      needDateStart: true,
      abs: false,
    }) * unitWidth;

  const unitLength = getUnitLength(renderEndTime as number, renderStartTime as number, zoomLevel, {
    needDateStart: true,
    abs: true,
  });
  // day 和 hour 结束时间都是一个时刻，不用向后扩展，不用加 1
  const width = isDayOrHour ? Math.max(unitLength * unitWidth, 10) : (unitLength + 1) * unitWidth;

  const maskImagePos = numberToPercent((left + width) / containerWidth);
  const WebkitMaskImage = `linear-gradient(90deg, rgb(0, 0, 0) ${maskImagePos}, rgba(0, 0, 0, 0.4) ${maskImagePos})`;

  const content = (
    <div className="absolute left-0 flex h-full w-full items-center overflow-hidden" ref={lineRef}>
      <div
        ref={card}
        onMouseDown={handleTranslate}
        data-block-id={canBindId ? recordId : undefined}
        data-view-id={viewId}
        data-hide-hover-menu
        className="absolute flex h-[34px] cursor-pointer items-center whitespace-nowrap"
        style={{
          left,
          width,
          boxShadow: '0px 1px 3px var(--black_006-base)',
        }}
      >
        <div
          className="h-full w-full"
          onClick={(event) =>
            openPage(recordId, {
              illegal,
              forceOpenInRight: event.altKey,
              forceOpenNewTab: event.ctrlKey || event.metaKey,
            })
          }
        />

        {timelineByEnd !== timelineBy && !readonly && (
          <>
            {DirectionMap.map((direction) => {
              return (
                <div
                  key={direction}
                  className={cx(
                    'group absolute z-10 h-full w-[9px] rounded-full px-[3px] py-1.5 hover:cursor-col-resize',
                    draggingDirection && draggingDirection !== direction && 'pointer-events-none',
                    direction === 'left' ? '-left-[5px]' : '-right-[5px]'
                  )}
                  onMouseDown={(event) => handleMouseDown(event, direction)}
                >
                  <span
                    className={cx(
                      'inline-block h-full w-[3px] rounded-full group-hover:bg-black',
                      draggingDirection === direction && 'bg-black'
                    )}
                  />
                  {draggingDirection === direction && draggingNewDate && (
                    <div
                      className={cx(
                        'text-t4 absolute top-1.5 flex items-center rounded bg-white1 px-1 text-black',
                        direction === 'left' ? 'right-3' : 'left-3'
                      )}
                    >
                      {isDayOrHour
                        ? dayjs(getDateMomentStamp(draggingNewDate)).format('MM/DD HH:mm')
                        : dayjs(draggingNewDate).format(SHORT_DATE_FORMAT)}
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
        <div className="absolute pointer-events-none right-0 top-0 rounded h-full z-10 w-full bg-white3">
          <span
            style={{ backgroundColor: bgColor }}
            className="absolute w-full h-full rounded border border-black/10"
          />
        </div>
      </div>
      <div
        className="pointer-events-none absolute left-0 flex h-full w-full items-center z-10"
        style={{ WebkitMaskImage }}
      >
        <div
          className="absolute flex h-[34px] cursor-pointer items-center px-2"
          style={{ left, minWidth: width }}
        >
          {illegal && (
            <>
              <IconTrigger
                className="text-t2-medium mr-1 inline p-0 align-middle opacity-30"
                blockId={recordId}
                trigger={false}
                iconSize={16}
                defaultIcon={<Icon name="IcPageEmpty" size="middle" />}
              />
              <span className="border-grey6 border-b opacity-30">{ILLEGAL_TEXT}</span>
            </>
          )}

          {!illegal && (
            <>
              {showIcon && !isNewCreateRecordId && (
                <IconTrigger
                  tooltipClassName="align-middle"
                  className="mr-1.5 inline-flex p-0 align-middle"
                  blockId={recordId}
                  // trigger={false}
                  iconSize={20}
                />
              )}

              {isNewCreateRecordId ? (
                <RichTextEdit
                  uuid={recordId}
                  className="text-t2-medium inline whitespace-nowrap"
                  plugins={TITLE_EDITOR_PLUGINS}
                  placeholder={UNTITLED}
                  segments={block?.data.segments}
                  autoFocus={true}
                  autoFocusCaretToEnd={true}
                  onChange={(segment) => updatePropertyValue(recordId, 'title', segment)}
                  onFocus={() => clearTimeout(timer)}
                  onBlur={() => {
                    timer = setTimeout(() => {
                      setAppUiState({ $newCreatedRecord: undefined });
                    }, 1000);
                  }}
                  onKeyDown={(event) => {
                    if (isHotkey('enter')(event) || isHotkey('esc')(event)) {
                      (event.currentTarget as HTMLDivElement).blur();
                    }
                  }}
                />
              ) : (
                <RichText
                  className="text-t2-medium inline whitespace-nowrap"
                  segments={block?.data.segments}
                  placeholder={UNTITLED}
                  interactable={false}
                  plugins={TITLE_EDITOR_PLUGINS}
                />
              )}

              {!isNewCreateRecordId && <PropertyValues recordId={recordId} />}
            </>
          )}
        </div>
      </div>

      {datesContainer.current &&
        (draggingNewDate || translateNewDate) &&
        createPortal(
          <Indicator startTime={translateNewDate ?? renderStartTime} unitLength={unitLength} />,
          datesContainer.current
        )}
    </div>
  );

  return <>{content}</>;
};
