import { Button } from '@flowus/common/components/button';
import { message } from '@flowus/common/components/message';
import { cx } from '@flowus/common/cx';
import { useApiState } from '@flowus/common/hooks/use-api-state';
import { useOpenModal } from '@flowus/common/next-modal';
import { Tooltip } from '@flowus/common/tooltip';
import { getCurrencySymbols } from '@flowus/common/utils';
import { Modals, SpacePlanType } from '@flowus/shared';
import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Icon } from 'src/common/components/icon';
import { PersonSpaceFreeCapacity, PersonSpaceFreeCopyLimit, publicLink } from 'src/common/const';
import { createModel, useModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { useCurrentSpace, useSpace } from 'src/hooks/space';
import { OpenDialogEnum } from 'src/hooks/space/use-open-dialog-from-location';
import { useLimitConfig } from 'src/services/app/hook/subscription-data';
import { useSpaceCapacity } from 'src/services/capacity';
import { SearchParams } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { useSpacePlanDataHeader } from 'src/views/main/setting-modal/upgrade-setting/upgrade-setting-content';
import { useOpenUpgradeSpace } from 'src/views/main/setting-modal/use-open-setting-modal';
import { HelperIcon } from '../helper-icon';
import { useOpenUpgradeCapacityModal } from '../select-upgrade-plan/upgrade-capacity';
import { UpgradeSpacePlanType } from './types';

interface UpgradeSpacePlanProps {
  /**
   * @maxCapacity 达到空间上限
   * @payCapacity 购买容量包
   * @maxGuestCount 升级购买协作者数量
   * @maxFileSize 突破文件限制
   */
  type: UpgradeSpacePlanType;
  spaceId?: string;
}

const useUpgradeSpacePlan = (props: UpgradeSpacePlanProps) => {
  return { ...props };
};

const UpgradeSpacePlanContext = createModel(useUpgradeSpacePlan);
const useUpgradeSpacePlanContext = () => useModel(UpgradeSpacePlanContext);

const UpgradeSpacePlan: FC<{ spaceId?: string }> = (props) => {
  const { spaceId } = props;
  const currentSpace = useCurrentSpace();
  const space = useSpace(spaceId);
  const isOtherSpace = spaceId !== currentSpace.uuid;
  const openModal = useOpenModal();
  const { type } = useUpgradeSpacePlanContext();
  const { guestMaxLimit } = useLimitConfig();
  const spacePlanName = getSpacePlanTypeName(space.uuid ? space.planType : currentSpace.planType);
  const spaceCapacity = useSpaceCapacity(spaceId);

  const handleClose = useCallback(() => {
    openModal.closeModal(Modals.MINI_PRICE_PLAN_MODAL);
  }, [openModal]);

  const [header, content] = useMemo(() => {
    const _content = {
      title: null as ReactNode,
      desc: null as ReactNode,
      popup: null as ReactNode,
      content: null as ReactNode,
    };
    if (type === UpgradeSpacePlanType.maxCapacity) {
      _content.title = [space.uuid ? `「${space.title}」` : '', '达到空间存储容量上限'].join('');
      _content.desc = (
        <>
          {spacePlanName}仅提供{getReadableFileSizeString(spaceCapacity.maxCapacity)}
          容量，升级获得更多空间容量
        </>
      );
      _content.popup = `达到空间储存容量上限：当前空间计划为${spacePlanName}空间，总共可使用${getReadableFileSizeString(
        spaceCapacity.maxCapacity
      )}容量。回收站中的页面和文件也会占有空间容量。`;
      _content.content = (
        <>
          <Panel.PersonPanel from={OpenSettingFrom.dialog_capacity_limit} />
          <Panel.ProPersonPanel
            from={OpenSettingFrom.dialog_capacity_limit}
            jumpSpaceId={isOtherSpace ? space.uuid : undefined}
          />
        </>
      );
    }

    // if (type === UpgradeSpacePlanType.payCapacity) {
    //   _content.title = <>还不想升级？试试购买容量包</>;
    //   _content.content = (
    //     <>
    //       <Panel.SpaceCapacityPanel />
    //       <Panel.ProPersonPanel />
    //     </>
    //   );
    // }

    if (type === UpgradeSpacePlanType.maxCopyLimit) {
      _content.title = <>达到每月拷贝页面次数上限</>;
      _content.desc = (
        <>
          每月可拷页面到{spacePlanName}空间{PersonSpaceFreeCopyLimit}，升级荻得无限拷贝次数
        </>
      );
      _content.content = (
        <>
          <Panel.PersonPanel from={OpenSettingFrom.dialog_copy_limit} />
          <Panel.ProPersonPanel
            from={OpenSettingFrom.dialog_copy_limit}
            jumpSpaceId={isOtherSpace ? space.uuid : undefined}
          />
        </>
      );
    }

    if (type === UpgradeSpacePlanType.maxFileSize) {
      _content.title = <>需要上传更大的文件？</>;
      _content.desc = <>{spacePlanName}仅支持上传2M以下的文件，升级上传更大的文件</>;
      _content.content = (
        <>
          <Panel.PersonPanel from={OpenSettingFrom.file} />
          <Panel.ProPersonPanel
            from={OpenSettingFrom.file}
            jumpSpaceId={isOtherSpace ? space.uuid : undefined}
          />
        </>
      );
    }
    if (type === UpgradeSpacePlanType.maxGuestCount) {
      _content.title = <>升级获得更多协作者席位</>;
      _content.desc = (
        <>
          {spacePlanName}仅提供{guestMaxLimit}个外部协作者席位，升级获得更席位
        </>
      );
      _content.popup = `当前空间计划为${spacePlanName}，总共可邀请${guestMaxLimit}名外部协作者。\n外部协作者适用于跨团队项目合作、客户沟通等场景，您可以邀请客户、合作方、甲方等参与查看和编辑特定页面。`;
      _content.content = (
        <>
          <Panel.PersonPanel from={OpenSettingFrom.guest_max} />
          <Panel.ProPersonPanel
            from={OpenSettingFrom.guest_max}
            jumpSpaceId={isOtherSpace ? space.uuid : undefined}
          />
        </>
      );
    }
    if (type === UpgradeSpacePlanType.subscribeAiLimit) {
      _content.title = (
        <div className="flex items-center">
          <div>「订阅问问」仅限空间会员可用</div>
          <Tooltip popup="「订阅问问」是针对订阅内容进行AI智能对话的功能，可快速提升阅读效率">
            <Icon name={'IcInviteHelp'} size="middle" />
          </Tooltip>
        </div>
      );
      _content.desc = <>个人免费版仅提供「空间问问」、「AI聊天」,升级获得更多AI权益</>;
      _content.content = (
        <>
          <Panel.PersonPanel from={OpenSettingFrom.dialog_subscribe_ai} />
          <Panel.ProPersonPanel
            from={OpenSettingFrom.dialog_subscribe_ai}
            jumpSpaceId={isOtherSpace ? space.uuid : undefined}
          />
        </>
      );
    }

    const _header = (
      <>
        <div className="w-full text-h2 pl-7 pr-4 py-7 flex items-center justify-between">
          <div>
            <span>{_content.title}</span>
            {_content.popup && <HelperIcon popup={_content.popup} size="middle" />}
          </div>
          <Icon className="text-grey3" size="middle" name="IcClose" onClick={handleClose} />
        </div>
        <div className="w-full px-7 py-2.5 text-t3 text-grey3">{_content.desc}</div>
      </>
    );

    return [_header, _content.content];
  }, [
    guestMaxLimit,
    handleClose,
    isOtherSpace,
    space.title,
    space.uuid,
    spaceCapacity.maxCapacity,
    spacePlanName,
    type,
  ]);

  useEffect(() => {
    bizTracker.event(type);
  }, [type]);

  return (
    <div>
      {header}
      <div className="grid grid-cols-2 gap-5 px-7 pb-6">{content}</div>
    </div>
  );
};

// #region Panel

const funcListData = {
  freePersonCapacity: [
    {
      type: 'title',
      value: '包括特性：',
    },
    {
      type: 'select',
      value: `存储容量${PersonSpaceFreeCapacity}`,
    },
    {
      type: 'select',
      value: '文件上传限制2M',
    },
    {
      type: 'select',
      value: '外部协作人数5人',
    },
    {
      type: 'select',
      value: `拷贝页面到空间${PersonSpaceFreeCopyLimit}/月`,
    },
  ],
  proPerson: [
    {
      type: 'title',
      value: '包括免费版所有权益，并且：',
    },
    {
      type: 'select',
      value: '存储容量200G',
    },
    {
      type: 'select',
      value: '文件上传限制5G',
    },
    {
      type: 'select',
      value: '外部协作人数无限',
    },
    {
      type: 'select',
      value: '拷贝页面到空间次数无限',
    },
    {
      type: 'select',
      value: '历史页面保留30天',
    },
    {
      type: 'select',
      value: '公共模版无限',
    },
    {
      type: 'select',
      value: '思维导图节点数无限',
    },
    {
      type: 'link',
      value: '了解更多功能',
      link: publicLink.pricing,
    },
  ],
  capacity: [],
} as const;

type FuncListDataKey = keyof typeof funcListData;

const FunctionList: FC<{ type: FuncListDataKey }> = (props) => {
  const { type } = props;

  return (
    <>
      {funcListData[type].map((item) => (
        <div
          key={item.value}
          className={cx(
            'flex items-center space-x-2 py-2 text-t2',
            item.type === 'title' && 'text-grey3'
          )}
        >
          {item.type === 'select' && (
            <>
              <Icon size="small" className="text-active_color" name="IcCheck01" />
              <span>{item.value}</span>
            </>
          )}
          {item.type === 'link' && (
            <a href={item.link} target="_blank" className="text-active_color text-t4">
              {item.value}
            </a>
          )}
        </div>
      ))}
    </>
  );
};

const PanelContainer: FC<{
  title?: ReactNode;
  type: FuncListDataKey;
  price?: ReactNode;
  onClick: () => void;
}> = (props) => {
  const { title, type, price, children, onClick } = props;
  const isPro = ['proPerson'].includes(type);

  return (
    <div className={'border rounded flex flex-col'}>
      {title && (
        <div
          className={cx('text-h4 p-3 bg-grey9', isPro && 'bg-active_color_10 text-active_color')}
        >
          {title}
        </div>
      )}
      <div className="py-4 px-3 flex flex-col flex-1">
        <div>{price}</div>
        <FunctionList type={type} />
        {children}
        {isPro && (
          <Button className="self-end" colorType="active" size="auto" onClick={onClick}>
            立即升级
          </Button>
        )}
      </div>
    </div>
  );
};

const SpaceCapacityPanel: FC = () => {
  const currentSpace = useCurrentSpace();
  const [planList = { list: [] }] = useApiState({
    getApi: () => request.infra.capacityPlanList(currentSpace.uuid),
    enable: !!currentSpace.uuid,
  });
  const openUpgradeSpace = useOpenUpgradeSpace();
  const openUpgradeCapacityModal = useOpenUpgradeCapacityModal();

  return (
    <PanelContainer
      title="存储容量包"
      type="capacity"
      onClick={() => {
        openUpgradeSpace(OpenSettingFrom.dialog_capacity_package);
      }}
    >
      {planList.list?.map((item) => (
        <div className="text-h3 py-2 flex items-center justify-between">
          <span>{item.name}</span>
          <span>
            <span>
              {getCurrencySymbols(item.currentPriceUnit)}
              {item.currentPrice}
            </span>
            <span className="text-t1 text-grey3">/年</span>
          </span>
        </div>
      ))}
      <div className="self-end w-full mt-auto">
        <Button
          colorType="active_line"
          size="auto"
          onClick={() => {
            openUpgradeCapacityModal({ isFrom: OpenSettingFrom.dialog_capacity_package });
          }}
        >
          购买容量包
        </Button>
      </div>
    </PanelContainer>
  );
};

const PersonPanel: FC<{ from: OpenSettingFrom }> = ({ from }) => {
  const currentSpace = useCurrentSpace();
  const openUpgradeSpace = useOpenUpgradeSpace();
  return (
    <PanelContainer
      onClick={() => {
        openUpgradeSpace(from);
      }}
      price={<div className="text-h2">免费</div>}
      title={
        <div className="space-x-1">
          <span>个人免费版空间</span>
          {currentSpace.planType === SpacePlanType.freePersonal && (
            <span className="text-t4 text-grey3 bg-black_006 rounded px-1 py-0.5">当前方案</span>
          )}
        </div>
      }
      type="freePersonCapacity"
    />
  );
};

const ProPersonPanel: FC<{
  from: OpenSettingFrom;
  jumpSpaceId?: string;
}> = ({ from, jumpSpaceId }) => {
  const { planData } = useSpacePlanDataHeader({
    isFrom: OpenSettingFrom.file,
  });
  const { type: modalType } = useUpgradeSpacePlanContext();
  const isPayCapacity = modalType === UpgradeSpacePlanType.payCapacity;
  const openUpgradeSpace = useOpenUpgradeSpace();

  const price = useMemo(() => {
    const curPrice = (
      <span>
        <span className="text-h2 text-black">
          {getCurrencySymbols(planData.proPerson.currentPriceUnit)}
          {planData.proPerson.currentPrice}
        </span>
        <span>/年</span>
      </span>
    );

    return (
      <div className="text-grey3 space-x-1">
        {isPayCapacity && (
          <div className="space-x-1">
            <span className="text-black text-h2">200G</span>
            <span>存储容量</span>
          </div>
        )}
        {curPrice}
        <span className="line-through">
          {getCurrencySymbols(planData.proPerson.originalPriceUnit)}
          {planData.proPerson.originalPrice}/年
        </span>
        {planData.proPerson.label && (
          <span className="bg-red text-white text-t4 rounded px-1.5 py-0.5">
            {planData.proPerson.label}
          </span>
        )}
      </div>
    );
  }, [
    isPayCapacity,
    planData.proPerson.currentPrice,
    planData.proPerson.currentPriceUnit,
    planData.proPerson.label,
    planData.proPerson.originalPrice,
    planData.proPerson.originalPriceUnit,
  ]);

  return (
    <PanelContainer
      onClick={() => {
        if (jumpSpaceId) {
          // 跳到对应的空间再打开升级
          message.success('正在切换空间，稍等片刻...');
          // 切换空间再升级
          const params = new URLSearchParams();
          params.set(SearchParams.spaceId, jumpSpaceId);
          params.set(SearchParams.openDialog, OpenDialogEnum.openUpgradeSpace);
          window.location.href = `${window.location.origin}?${params.toString()}`;
        } else {
          openUpgradeSpace(from);
        }
      }}
      price={price}
      title={
        <div className="flex items-center space-x-1">
          <span>个人专业版空间</span>
          {isPayCapacity && (
            <span className="bg-black-base text-white rounded px-1.5 py-0.5 text-t4">更畅销</span>
          )}
        </div>
      }
      type="proPerson"
    />
  );
};
// #endregion

const Panel = {
  PersonPanel,
  ProPersonPanel,
  SpaceCapacityPanel,
};

export const useOpenUpgradeSpacePlan = () => {
  const openModal = useOpenModal();

  return useCallback(
    (params: UpgradeSpacePlanProps) => {
      openModal.modal({
        modalId: Modals.MINI_PRICE_PLAN_MODAL,
        content: () => (
          <UpgradeSpacePlanContext {...params}>
            <UpgradeSpacePlan spaceId={params.spaceId} />
          </UpgradeSpacePlanContext>
        ),
      });
    },
    [openModal]
  );
};
