import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ILLEGAL_TEXT, UNTITLED } from 'src/common/const';
import { useSize } from 'src/common/utils/use-size';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { buildDateSegment } from 'src/editor/utils/segments';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useBlock } from 'src/hooks/block/use-block';
import { useRecordBgColor } from 'src/hooks/block/use-record-color';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { useReadonly } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { getState } from 'src/redux/store';
import { setAppUiState, useNewCreatedRecord } from 'src/services/app';
import { getDateTimeStamp, ONE_DAY, ONE_MOMENT } from 'src/utils/date-utils';
import { BlockDiscussionsBadge } from 'src/views/comments/block-discussions-badge';
import { PropertyValues } from '../board-view/card/property-values';
import { useBitable } from '../context';
import type { OnResizeEnd } from './add-resize';
import { addResize } from './add-resize';
import type { RenderDayCard } from './const';
import {
  CalendarViewType,
  CARD_GAP,
  MIN_HEIGHT,
  PADDING_LEFT,
  PADDING_TOP,
  UNIT_HEIGHT,
} from './const';
import { getCalendarByType } from './day-view/use-category-record';
import { getCardHeight } from './month-view/card';
import { useShowingTablePageIcon } from 'src/hooks/collection-view/use-collection-view';

interface Props {
  base: number;
  record: RenderDayCard;
  container: React.RefObject<HTMLDivElement>;
  viewType: CalendarViewType;
  isAllDay?: boolean;
}

export const RecordItem: FC<Props> = (props) => {
  const { record, base, container, viewType, isAllDay } = props;
  const { uuid, startTime, endTime, left = 0, recordCount = 1 } = record;
  const openPage = useOpenPage();
  const readonly = useReadonly(uuid);
  const todoRef = useRef<HTMLDivElement>(null);
  const { width } = useSize(container, 'width') ?? {};
  const { illegal } = usePermissions(uuid);
  const { viewId } = useBitable();
  const transaction = useTransaction();
  const bgColor = useRecordBgColor(viewId, uuid);

  const [visibleProperty = []] = useProperties(viewId, { visible: true });
  const visiblePropertyLength = visibleProperty.length;

  const startDate = getDateTimeStamp(startTime);
  const endDate = getDateTimeStamp(endTime);
  let disableTop = false;
  let disableBottom = false;
  const { calendarByType, calendarByEndType } = getCalendarByType(viewId);
  if (
    calendarByType === CollectionSchemaType.CREATED_AT ||
    calendarByType === CollectionSchemaType.UPDATED_AT ||
    (calendarByType === CollectionSchemaType.DATE && startDate !== base)
  ) {
    disableTop = true;
  }
  if (
    calendarByEndType === CollectionSchemaType.CREATED_AT ||
    calendarByEndType === CollectionSchemaType.UPDATED_AT ||
    (calendarByEndType === CollectionSchemaType.DATE && endDate !== base)
  ) {
    disableBottom = true;
  }

  useEffect(() => {
    const todoNode = todoRef.current;
    const containerNode = container.current;
    const timelinePage = todoNode?.closest('[data-page-id]') as HTMLDivElement | null;
    if (!todoNode || !containerNode || !timelinePage) return;

    const onResizeEnd: OnResizeEnd = (index, length) => {
      const newStart = base + index * ONE_MOMENT;
      const newEnd = newStart + Math.floor(length) * ONE_MOMENT;

      const viewInfo = getViewFormat(viewId);
      if (!viewInfo) return;
      const { calendarBy, calendarByEnd } = viewInfo;
      if (!calendarBy || !calendarByEnd) return;

      const newPropertyValue: Record<string, SegmentDTO[]> = {};
      if (!disableTop) {
        newPropertyValue[calendarBy] = [
          buildDateSegment({
            from: new Date(newStart),
            includeTime: true,
          }),
        ];
      }
      if (!disableBottom) {
        newPropertyValue[calendarByEnd] = [
          buildDateSegment({
            from: new Date(newEnd),
            includeTime: true,
          }),
        ];
      }

      transaction(() => {
        const { blocks } = getState();
        const block = blocks[uuid];
        if (!block) return;

        updateBlock(uuid, {
          data: {
            collectionProperties: {
              ...block.data.collectionProperties,
              ...newPropertyValue,
            },
          },
        });
      });
    };

    const onClick = (event: MouseEvent) => {
      openPage(uuid, {
        illegal,
        forceOpenInRight: event.altKey,
        forceOpenNewTab: event.ctrlKey || event.metaKey,
      });
    };

    const remove = addResize(todoNode, timelinePage, containerNode, onResizeEnd, onClick, {
      disableTop: disableTop || readonly || !!isAllDay,
      disableBottom: disableBottom || readonly || !!isAllDay,
    });
    return () => {
      remove();
    };
  }, [
    container,
    base,
    openPage,
    uuid,
    illegal,
    isAllDay,
    viewId,
    transaction,
    disableTop,
    disableBottom,
    readonly,
  ]);

  useEffect(() => {
    const todoNode = todoRef.current;
    const containerNode = container.current;
    if (!todoNode || !containerNode) return;

    if (isAllDay) {
      todoNode.style.opacity = '1';
      todoNode.style.width = `${containerNode.clientWidth - PADDING_LEFT - 13}px`;
      return;
    }

    const gaps = (recordCount - 1) * CARD_GAP;

    if (viewType === CalendarViewType.DAY) {
      const cardWidthSum = containerNode.clientWidth - PADDING_LEFT - 13 - gaps;
      const cardWidth = cardWidthSum / recordCount;

      todoNode.style.width = `${cardWidth}px`;
      todoNode.style.left = `${(cardWidth + CARD_GAP) * left + 6}px`;
    } else {
      const oneDayWidth = (containerNode.clientWidth - PADDING_LEFT) / 7;
      const cardWidthSum = oneDayWidth - gaps;
      const cardWidth = cardWidthSum / recordCount;
      const day = new Date(base).getDay() || 7;

      todoNode.style.width = `${cardWidth}px`;
      todoNode.style.left = `${(cardWidth + CARD_GAP) * left + (day - 1) * oneDayWidth}px`;
    }

    todoNode.style.opacity = '1';
  }, [recordCount, base, container, left, viewType, width, isAllDay]);

  if (!startTime || !endTime) return null;

  let height = 0;
  let top = undefined;
  if (isAllDay) {
    height = getCardHeight(visiblePropertyLength);
  } else {
    const topOffset = Math.max(startTime, base) - base;
    const offsetHeight = Math.min(endTime, base + ONE_DAY) - Math.max(startTime, base);

    top = (topOffset / ONE_MOMENT) * UNIT_HEIGHT + PADDING_TOP;
    height = (offsetHeight / ONE_MOMENT) * UNIT_HEIGHT;
  }
  return (
    <BlockDrop
      key={uuid}
      id={uuid}
      viewId={viewId}
      data-record-id={uuid}
      blockRef={todoRef}
      style={{ top, height, minHeight: MIN_HEIGHT }}
      className={cx(
        'rounded border bg-white1 flex-shrink-0 opacity-0 overflow-hidden cursor-pointer',
        isAllDay ? 'mt-1.5' : 'absolute'
      )}
    >
      <div
        className={cx('relative h-full p-1', height <= UNIT_HEIGHT * 2 ? 'py-0' : '')}
        style={{ boxShadow: '0px 1px 3px var(--black_006)', backgroundColor: bgColor }}
      >
        {illegal && (
          <>
            <IconTrigger
              className="text-t2-medium mr-1 inline p-0 align-middle opacity-30"
              blockId={uuid}
              trigger={false}
              iconSize={16}
              defaultIcon={<Icon name="IcPages" size="middle" />}
            />
            <span className="border-b border-grey6 opacity-30">{ILLEGAL_TEXT}</span>
          </>
        )}
        {!illegal && (
          <>
            <div className="flex">
              <RecordTitle recordId={uuid} height={height} />
            </div>

            <PropertyValues recordId={uuid} />
          </>
        )}
      </div>

      {!isAllDay && !readonly && (
        <>
          {!disableTop && (
            <div className="resize-top" onClick={(event) => event.stopPropagation()} />
          )}
          {!disableBottom && (
            <div className="resize-bottom" onClick={(event) => event.stopPropagation()} />
          )}
        </>
      )}
    </BlockDrop>
  );
};

export const RecordTitle: FC<{ recordId: string; height: number }> = ({ recordId, height }) => {
  const { viewId } = useBitable();
  const block = useBlock(recordId);
  const updateValue = useUpdatePropertyValue();
  const newCreateRecord = useNewCreatedRecord();
  const showIcon = (block?.subNodes ?? []).length > 0 || Boolean(block?.data.icon?.type);
  const isNewCreateRecordId = recordId === newCreateRecord?.id && viewId === newCreateRecord.viewId;

  const [showEditor, setShowEditor] = useState(isNewCreateRecordId);
  const isShowPageIcon = useShowingTablePageIcon(viewId);
  if (!block) return null;

  return (
    <>
      {showIcon && isShowPageIcon && (
        <IconTrigger
          tooltipClassName="align-middle"
          className="mr-1.5 inline-flex p-0 align-middle"
          blockId={recordId}
          // trigger={false}
          iconSize={16}
          defaultIcon={<BlockDefaultIcon uuid={recordId} size="small" />}
        />
      )}

      {showEditor ? (
        <RichTextEdit
          uuid={recordId}
          className={cx(
            'text-t4-medium whitespace-nowrap align-middle flex-grow-0',
            height >= MIN_HEIGHT && 'leading-[22px]'
          )}
          plugins={TITLE_EDITOR_PLUGINS}
          keydownOption={{ bracketRightKey: false }}
          placeholder={UNTITLED}
          autoFocus={true}
          toolbar={false}
          autoFocusCaretToEnd={true}
          segments={block.data.segments}
          interactable={true}
          onChange={(segment) => updateValue(recordId, 'title', segment)}
          onBlur={() => {
            setShowEditor(false);
            setAppUiState({ $newCreatedRecord: undefined });
          }}
          onKeyDown={(event) => {
            if (isHotkey('mod+a')(event)) {
              event.stopPropagation();
              return;
            }
            if (isHotkey('enter')(event) || isHotkey('esc')(event)) {
              (event.currentTarget as HTMLDivElement).blur();
            }
          }}
        />
      ) : (
        <RichText
          className={cx(
            'text-t4-medium text-ellipsis whitespace-nowrap align-middle',
            height >= MIN_HEIGHT && 'leading-[22px]'
          )}
          plugins={TITLE_EDITOR_PLUGINS}
          segments={block?.data.segments}
          placeholder={UNTITLED}
          interactable={false}
        />
      )}

      <BlockDiscussionsBadge
        className="inline-flex align-middle pointer-events-none"
        blockId={recordId}
      />
    </>
  );
};
