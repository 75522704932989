import { Emitter } from '@next-space/fe-events';

interface EventMap {
  inviteConfirmModal: any;
  hoverId: any;
  removeHoverMenu: any;
  syncBlock: [{ uuid: string; pageId: string; spaceId: string }];
  showOutline: any;
  pdfCursor: any;
  pdfCursorColor: any;
  sidebarType: [any];
  hoverItem: any;
  closeModal: any;
  syncNoPermission: any;
  modalId: any;
  pdfRenderPage: any;
  aiPageWidth: any;
  aiPage: any;
  chart2png: any;
}

export const emitter = new Emitter<EventMap>();
// @ts-ignore max listeners default is 10, it will make a warning
Emitter.defaultMaxListeners = 200;
