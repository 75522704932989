import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { useOpenAiEditorFromBitable } from 'src/editor/editor/uikit/ai-editor/use-open-ai-from-bitable';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { updateCollectionView } from 'src/hooks/block/use-update-collection-view';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { Modals } from 'src/modals';
import { getState } from 'src/redux/store';
import { useCollectionSearchById } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { useIsInRight } from 'src/utils/right-utils';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { LockButton } from 'src/views/main/header/lock-button';
import { TableClassName } from '../const';
import { useBitable } from '../context';
import { isFilterEnabled } from '../table-view/body/filters';
import { BitableSearch } from './bitable-search';
import { ConfigTableFilter } from './config-table-filter';
import { ConfigTableSort } from './config-table-sort';
import { GroupList } from './group-list';
import { getValidPropertyIds } from './group-list/utils';
import { EmbedOptionMenu } from './options-menu';
import { BitableFormManager, useRenderCreateRecordButton } from './page-manager';
import { TableTitle } from './table-title';
import { ViewsManager } from './view-list-manager';
import { ViewSettingPanel } from './view-setting-panel';
import { useNormalizePath } from 'src/hooks/page';
import { CorrectOffsetY } from '../timeline-view/const';

interface Props {
  className?: string;
}

export const EmbedManager: FC<Props> = ({ className }) => {
  const { viewId, collectionId, managerReadonly, readonly, viewParentId, pageManager, viewType } =
    useBitable();
  const searchKeyword = useCollectionSearchById(viewId);
  const view = useCollectionView(viewId);
  const formIllegal = view?.format.formIllegal;
  const openModal = useOpenModal();
  const { enableAI } = useEnableAI();
  const openAIEditor = useOpenAiEditorFromBitable();
  const isRight = useIsInRight();
  const path = useNormalizePath(viewParentId);
  const isForm = CollectionViewType.FORM === view?.type;
  const isTable = CollectionViewType.TABLE === view?.type;

  const showTitle = useObservableStore(
    ({ collectionViews }) => collectionViews[viewId]?.format.showTitle ?? true,
    [viewId]
  );

  const { filterEnabled, sortersEnabled, groupEnabled, subGroupEnabled } = useObservableStore(
    (state) => {
      const viewInfo = getViewFormat(viewId, state.blocks, state.collectionViews);
      if (!viewInfo) return {};
      const { view, collection, groupBy, subGroupBy } = viewInfo;

      const filterEnabled = isFilterEnabled(view.format.filter, collection.data.schema);

      const sorters = view.format.sorters ?? [];
      const sortersEnabled = sorters.filter((it) => !it.disable).length > 0;

      const allIds = getValidPropertyIds(collectionId, state);
      const groupEnabled = allIds.includes(groupBy?.property ?? '');
      const subGroupEnabled = allIds.includes(subGroupBy?.property ?? '');

      return { filterEnabled, sortersEnabled, groupEnabled, subGroupEnabled };
    },
    [collectionId, viewId]
  );

  const viewTitleRef = useRef(view?.title ?? '');
  useEffect(() => {
    viewTitleRef.current = view?.title ?? '';
  }, [view?.title]);

  const showOptionMenu = (event: MouseEvent<HTMLElement>) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      modalId: Modals.BITABLE_OPTION_MENU,
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      content() {
        return <EmbedOptionMenu />;
      },
    });
  };

  const viewSettingButtonRef = useRef<HTMLButtonElement>(null);
  const viewIdRef = useRef(viewId);
  viewIdRef.current = viewId;
  const showViewSetting = () => {
    openModal.dropdown({
      popcorn: viewSettingButtonRef.current || document.body,
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      closeBeforeCallBack() {
        const newTitle = viewTitleRef.current;
        const view = getState().collectionViews?.[viewIdRef.current];
        if (newTitle !== view?.title) {
          updateCollectionView(viewIdRef.current, { title: newTitle });
        }
      },
      content: ({ onCloseModal }) => (
        <ViewSettingPanel viewTitleRef={viewTitleRef} onCloseModal={onCloseModal} />
      ),
    });
  };

  const showGroups = (event: MouseEvent, isSubGroup = false) => {
    openModal.dropdown({
      popcorn: elementToGetBoundingClientRect(event.currentTarget),
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      content() {
        return <GroupList isSubGroup={isSubGroup} />;
      },
    });
  };

  const showConfigTableSort = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: elementToGetBoundingClientRect(event.currentTarget),
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      content() {
        return <ConfigTableSort />;
      },
    });
  };

  const showConfigTableFilter = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: elementToGetBoundingClientRect(event.currentTarget),
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      content() {
        return <ConfigTableFilter />;
      },
    });
  };

  const openEditor = () => {
    const popcorn = querySelectorFromMainContent(`[data-block-id="${viewParentId}"]`, isRight);
    if (!popcorn) return;
    openAIEditor(viewParentId, popcorn);
  };

  const renderCreateRecordButton = useRenderCreateRecordButton();

  useEffect(() => {
    if (!pageManager) return;

    const managerNode = pageManager.current;
    const embedTable = managerNode?.closest('[data-block-id]');
    const nextSpacePage = managerNode?.closest('.next-space-page');
    if (!managerNode || !embedTable || !nextSpacePage) return;

    const handleScroll = () => {
      requestAnimationFrame(() => {
        const embedTableRect = embedTable.getBoundingClientRect();
        const nextSpacePageRect = nextSpacePage.getBoundingClientRect();
        if (embedTableRect.top < nextSpacePageRect.top) {
          let translateY = Math.abs(nextSpacePageRect.top - embedTableRect.top);
          if (embedTableRect.bottom - nextSpacePageRect.top < managerNode.clientHeight) {
            translateY -=
              managerNode.clientHeight - (embedTableRect.bottom - nextSpacePageRect.top);
          }
          managerNode.style.transform = `translate3d(0,${Math.round(
            translateY + CorrectOffsetY
          )}px,0)`;
        } else {
          managerNode.style.transform = 'none';
        }
      });
    };

    nextSpacePage.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      nextSpacePage.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [pageManager]);

  return (
    <div className={cx('group flex items-center justify-between flex-col', className)}>
      <div
        className="flex items-center justify-between w-full relative bg-white1 z-50"
        ref={pageManager}
      >
        <ViewsManager className="print:hidden" />

        <div
          className={cx(
            'flex opacity-0 transition-opacity group-hover:opacity-100 print:hidden overflow-auto',
            searchKeyword !== undefined && 'opacity-100'
          )}
        >
          <LockButton uuid={viewParentId} />

          {!managerReadonly && (
            <>
              <BitableFormManager />

              {isTable && enableAI && (
                <Tooltip popup="AI">
                  <button
                    className="text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-[#00E384]"
                    onClick={openEditor}
                  >
                    <Icon size="middle" className="mr-1" name="IcAi" />
                  </button>
                </Tooltip>
              )}

              <button
                className="text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-grey3"
                ref={viewSettingButtonRef}
                onClick={showViewSetting}
              >
                <Icon size="middle" name="IcMenuSettings" className="mr-1" />
                视图设置
              </button>

              {viewType && ![CollectionViewType.FORM].includes(viewType) && filterEnabled && (
                <Tooltip popup="筛选">
                  <button
                    className={cx(
                      'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-active_color'
                    )}
                    onClick={showConfigTableFilter}
                  >
                    <Icon size="middle" name="IcFilter" />
                  </button>
                </Tooltip>
              )}

              {viewType && ![CollectionViewType.FORM].includes(viewType) && sortersEnabled && (
                <Tooltip popup="排序">
                  <button
                    className={cx(
                      'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-active_color',
                      TableClassName.tableSortManager
                    )}
                    onClick={showConfigTableSort}
                  >
                    <Icon size="middle" name="IcDescending" />
                  </button>
                </Tooltip>
              )}

              {viewType &&
                groupEnabled &&
                ![CollectionViewType.CALENDAR, CollectionViewType.FORM].includes(viewType) && (
                  <Tooltip popup="分组">
                    <button
                      className={cx(
                        'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-active_color'
                      )}
                      onClick={(event) => showGroups(event)}
                    >
                      <Icon size="middle" name="IcGroup" />
                    </button>
                  </Tooltip>
                )}

              {viewType === CollectionViewType.BOARD && subGroupEnabled && (
                <Tooltip popup="子分组">
                  <button
                    className={cx(
                      'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-active_color'
                    )}
                    onClick={(event) => showGroups(event, true)}
                  >
                    <Icon size="middle" name="IcSubGroup" />
                  </button>
                </Tooltip>
              )}
            </>
          )}

          {managerReadonly && (
            <>
              <Tooltip popup="筛选">
                <button
                  className={cx(
                    'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-grey3',
                    filterEnabled && 'text-active_color'
                  )}
                  onClick={showConfigTableFilter}
                >
                  <Icon size="middle" name="IcFilter" />
                </button>
              </Tooltip>

              <Tooltip popup="排序">
                <button
                  className={cx(
                    'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-grey3',
                    TableClassName.tableSortManager,
                    sortersEnabled && 'text-active_color'
                  )}
                  onClick={showConfigTableSort}
                >
                  <Icon size="middle" name="IcDescending" />
                </button>
              </Tooltip>
            </>
          )}

          <BitableSearch className="mx-1" />

          <Tooltip popup="以页面打开">
            <Link
              to={path}
              className="animate-hover flex p-1 items-center justify-center rounded text-grey3"
            >
              <Icon size="middle" name="IcMenuOpen" />
            </Link>
          </Tooltip>

          {!formIllegal && (
            <>
              {!managerReadonly && (
                <Tooltip popup="菜单">
                  <button
                    className="animate-hover mx-1 flex p-1 items-center justify-center rounded text-grey3"
                    onClick={showOptionMenu}
                  >
                    <Icon size="middle" name="IcMore" />
                  </button>
                </Tooltip>
              )}
              {!readonly && !isForm && renderCreateRecordButton()}
            </>
          )}
        </div>
      </div>

      {showTitle && (
        <div className="flex w-full py-1 border-t">
          <TableTitle />
        </div>
      )}
    </div>
  );
};
