import { useRef } from 'react';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useTextareaPlaceHolder } from 'src/services/app';
import { usePickBlock } from 'src/utils/pick-block';
import type { BlockElement } from '../core/type';
import { BlockChildren } from '../uikit/block-children';
import { Editable } from '../uikit/editable';
import { BlockDrop } from './dnd/block-drop';

export const TextareaBlockElement: BlockElement = ({ id, children, root }) => {
  const block = usePickBlock(id, ['data'], ['segments']);
  const { enableAI } = useEnableAI();
  const { alwaysShowPlaceHolder, placeHolder } = useTextareaPlaceHolder(id, {
    defaultPlaceholder: enableAI ? `按空格使用 AI，输入文字或 '/' 命令` : `输入文字或 '/' 命令`,
  });

  const ref = useRef<HTMLDivElement>(null);
  // const readOnly = useReadonly(id);

  if (!block) return null;

  // const notText = isBlockNoText(block.data);

  return (
    <BlockDrop id={id} horizontal={root} className="my-px">
      <div ref={ref} className="px-0.5 py-1 text-t1 group">
        <Editable
          alwaysShowPlaceholder={alwaysShowPlaceHolder}
          uuid={id}
          placeholder={placeHolder}
        />
        {/* {notText && !readOnly && root && <Shortcut uuid={id} />} */}
      </div>
      {children && <BlockChildren blockId={id}>{children}</BlockChildren>}
    </BlockDrop>
  );
};

// const Shortcut: FC<{ uuid: string }> = memo(({ uuid }) => {
//   const transaction = useTransaction();
//   const isLiteSize = useIsLiteSize();
//   const isMobileSize = useIsMobileSize();
//   const isSelecting = useIsSelecting();
//   const isDragging = useIsDragging();
//   const getEditorModel = useGetOrInitEditorModel();

//   const items = useMemo(() => {
//     const BlockMenu = getBlockMenuList();
//     return [
//       BlockMenu.basicBlock.todo,
//       BlockMenu.basicBlock.h1,
//       BlockMenu.basicBlock.h2,
//       BlockMenu.basicBlock.h3,
//       BlockMenu.basicBlock.h4,
//       BlockMenu.basicBlock.list,
//       BlockMenu.basicBlock.orderList,
//       BlockMenu.basicBlock.foldList,
//       BlockMenu.basicBlock.quote,
//       BlockMenu.basicBlock.mark,
//     ].map((i) => i.data);
//   }, []);

//   const handleClick = useCallback(
//     (item: typeof items[0] & { params?: any }) => {
//       const { type, params = {}, title } = item;
//       transaction(() => {
//         bizTracker.event('block_fastchoose', { block_name: title, block_type: type });
//         convertBlock([uuid], { type, ...params, data: { ...params.data } });
//         const editorModel = getEditorModel(uuid, false);
//         void editorModel?.requestFocus();
//       });
//     },
//     [getEditorModel, transaction, uuid]
//   );

//   if (isSelecting || isDragging || isMobileSize || isLiteSize) return null;

//   return (
//     <div
//       className={
//         'absolute top-1 transition-opacity flex items-center flex-shrink-0 opacity-0 group-hover:opacity-60 right-0'
//       }
//     >
//       {items.map((item) => (
//         <Tooltip
//           key={item.title}
//           onClick={() => handleClick(item)}
//           className="ml-1 cursor-pointer"
//           popup={item.title}
//         >
//           <Icon size="large" name={item.icon as IconName} />
//         </Tooltip>
//       ))}
//     </div>
//   );
// });
