import { cx } from '@flowus/common/cx';
import { PermissionRole } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { useAtomValue } from 'jotai';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { fromEvent } from 'rxjs';
import { recordWalkerByCollectionViewId } from 'src/bitable/shared';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { isPublicPageDeleted } from 'src/common/utils/is-public-page-deleted';
import { DeleteBar } from 'src/components/delete-bar';
import { NoticeBanner } from 'src/components/notice-banner';
import { Share } from 'src/components/share';
import { SpaceCapacity } from 'src/components/space-capacity';
import { HEADER_HEIGHT } from 'src/const/public';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { useBlockStatus } from 'src/hooks/block/use-block-status';
import { useReadonly } from 'src/hooks/page';
import { useHasAIBlock } from 'src/hooks/page/use-page-has-aiBlock';
import { useOfficialSpaceShare } from 'src/hooks/public/use-official-space-share';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useFileIllegal } from 'src/services/app/hook';
import { judgeSharePage, useGetPageId, useSwitchLeftRight } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useCloseRight, useIsInRight, useMasterHistory, useOpenRight } from 'src/utils/right-utils';
import { ShortcutOption } from 'src/utils/shortcut';
import { isRightDrawer, useDrawerOpenStateValue } from '../drawer/shared';
import { Favorite } from './favorite';
import { Popup, TooltipButton } from './helper';
import { LockButton } from './lock-button';
import { MenuButton } from './menu-button';
import { PageCommentButton } from './page-comment-button';
import { PageFeedsButton } from './page-feeds-button';

export const HeaderBar: FC<{}> = () => {
  const pageId = useGetPageId();
  const isInRight = useIsInRight();
  const isDeleted = useBlockStatus(pageId);
  const _isPublicPageDeleted = isPublicPageDeleted(pageId);
  // const isTemplate = useIsCustomTemplate(pageId);
  // const isSharePage = judgeSharePage();
  // const { role } = usePermissions(pageId);
  const block = usePickBlock(pageId, ['isTemplate']);
  const history = useMasterHistory();
  const readonly = useReadonly(pageId);

  return (
    <>
      {isInRight && <RightToolbar />}
      <SpaceCapacity type="header" />
      {!_isPublicPageDeleted && isDeleted && <DeleteBar uuid={pageId} />}
      {/* {isTemplate && !isSharePage && !isDeleted && role !== PermissionRole.NONE && (
        <TemplateBar uuid={pageId} />
      )} */}
      {block?.isTemplate && (
        <NoticeBanner className="flex justify-between text-t2">
          {(history.location.state as any)?.noticeBannerShowBackButton ? (
            <button
              className="text-grey3 ml-3 pl-1 pr-2 flex items-center animate-hover"
              onClick={() => {
                history.goBack();
              }}
            >
              <Icon name="IcArrowBack" size="middle" className="mt-[1px]" />
              返回
            </button>
          ) : (
            <span></span>
          )}
          <span className="flex items-center">
            {__BUILD_IN__ ? (
              <>
                {['You are', readonly ? 'view' : 'editing', 'template in'].join(' ')}
                <InlinePage
                  className="text-black"
                  uuid={block.parentId}
                  isLinkPage={true}
                  interactable={true}
                  autoIconSize={true}
                />
              </>
            ) : (
              <>
                {'你正在'}
                <InlinePage
                  className="text-black"
                  uuid={block.parentId}
                  isLinkPage={true}
                  interactable={true}
                  autoIconSize={true}
                />
                {readonly ? '查看' : '编辑'}
                {'模板记录'}
              </>
            )}
          </span>
          <span></span>
        </NoticeBanner>
      )}
    </>
  );
};

export const RightToolbar: FC = () => {
  const pageId = useGetPageId();
  const hasAIBlock = useHasAIBlock(pageId);
  const walkerTable = useAtomValue(recordWalkerByCollectionViewId);
  const walkers = Object.values(walkerTable);
  const walker = walkers.find((it) => it.checkRecord(pageId));
  const history = useHistory();
  const closeRight = useCloseRight();
  const openRight = useOpenRight();
  const switchLeftRight = useSwitchLeftRight();
  const isSharePage = judgeSharePage();
  const fileIllegal = useFileIllegal();
  const { role, illegal } = usePermissions(pageId);
  const officialSpaceShare = useOfficialSpaceShare(pageId);
  const illegalPage = illegal || fileIllegal;
  const drawerOpen = useDrawerOpenStateValue();

  useEffect(() => {
    if (walker == null) return;
    const subscription = fromEvent<KeyboardEvent>(document, 'keydown').subscribe((event) => {
      if (isHotkey('ctrl+shift+k')(event)) {
        const prevRecord = walker.prevRecord(pageId);
        if (prevRecord == null) return;
        history.push(`/${prevRecord}`);
        event.preventDefault();
      } else if (isHotkey('ctrl+shift+j')(event)) {
        const nextRecord = walker.nextRecord(pageId);
        if (nextRecord == null) return;
        history.push(`/${nextRecord}`);
        event.preventDefault();
      }
    });
    return () => subscription.unsubscribe();
  }, [history, pageId, walker]);

  return (
    <div
      className={cx(
        'absolute top-0 left-0 w-full bg-white2 flex items-center justify-between z-50 px-4 text-black transition-all border-b border-transparent',
        isRightDrawer(drawerOpen) && 'border-grey6'
      )}
      style={{ height: HEADER_HEIGHT }}
    >
      <div className="flex items-center space-x-4">
        <TooltipButton
          title="关闭"
          text={`${ShortcutOption}+Q`}
          icon="IcClose"
          onClick={closeRight}
        />
        <TooltipButton
          title="切换"
          text={`${ShortcutOption}+'`}
          icon="IcTitleSwitch"
          onClick={switchLeftRight}
        />
        <TooltipButton
          title="放大"
          text={`${ShortcutOption}+F`}
          icon="IcMenuOpen"
          onClick={openRight}
        />

        {walker != null && (
          <>
            <div className="w-0 border-l-[1px] border-grey6  h-[14px]" />
            <Tooltip popup={<Popup title="上一条记录" text={`ctrl+Shift+K`} className="w-28" />}>
              <button
                className="flex items-center justify-center w-5 h-5 rounded outline-none animate-hover disabled:opacity-30"
                disabled={walker.prevRecord(pageId) == null}
                onClick={() => {
                  const prevRecord = walker.prevRecord(pageId);
                  if (prevRecord == null) return;
                  history.push(`/${prevRecord}`);
                }}
              >
                <Icon name="IcArrowPagePrev" size="middle" />
              </button>
            </Tooltip>

            <Tooltip popup={<Popup title="下一条记录" text={`ctrl+Shift+J`} className="w-28" />}>
              <button
                className="flex items-center justify-center w-5 h-5 rounded outline-none animate-hover disabled:opacity-30"
                disabled={walker.nextRecord(pageId) == null}
                onClick={() => {
                  const nextRecord = walker.nextRecord(pageId);
                  if (nextRecord == null) return;
                  history.push(`/${nextRecord}`);
                }}
              >
                <Icon name="IcArrowPageNext" size="middle" />
              </button>
            </Tooltip>
          </>
        )}
        <LockButton uuid={pageId} />
      </div>
      <div className="flex item-center z-10 space-x-2">
        {hasAIBlock && !__BUILD_IN__ && isSharePage && (
          <p className={cx('text-grey4 text-t4 flex items-center !ml-auto')}>
            <Icon name="IcNote" size="middle" />
            当前页面可能含有 AI 生成内容
          </p>
        )}

        {!isSharePage && !illegalPage && !officialSpaceShare && <Share showType="button" />}
        {!illegal && (role === PermissionRole.WRITER || role === PermissionRole.EDITOR) && (
          <PageCommentButton />
        )}
        {!illegal && (role === PermissionRole.WRITER || role === PermissionRole.EDITOR) && (
          <PageFeedsButton />
        )}
        {!isSharePage && !illegal && <Favorite uuid={pageId} />}

        <MenuButton />
      </div>
    </div>
  );
};
