import { getDateStrBySegment } from '@flowus/common';
import { formula } from '@flowus/formula';
import type { CollectionSchema } from '@next-space/fe-api-idl';
import { GroupLevel, CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { getDatePropertyFromBlock, readUrlFromSegments } from 'src/bitable/table-view/cell/helpers';
import { formatNumber } from 'src/bitable/table-view/cell/num';
import { copyNormalizedValue } from 'src/bitable/table-view/cell/person';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'src/common/const';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import {
  buildDateSegment,
  buildPersonSegment,
  readDateFromDateSegment,
} from 'src/editor/utils/segments';
import { getState } from 'src/redux/store';
import type { NextBlock, RootState } from 'src/redux/types';
import { getFormulaTool } from '../block/use-formula-tool';
import { getPropertySchema } from '../block/use-property-schema';
import { getUserName } from '../user/use-remark-name';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { MemoizeByBlockVersion } from 'src/services/memoize/cache';
import { getGroupNameFromDate } from './table-groups/date-number-groupname';
import { getDateShowContent } from 'src/bitable/group-header/group-name';

export function getTablePropertyValue(
  block: string | NextBlock | undefined,
  propertyId: string,
  schema?: CollectionSchema
): string[] | string | number | undefined {
  if (!block) return;
  const { blocks } = getState();

  if (typeof block === 'string') {
    const blockInfo = blocks[block];
    if (!blockInfo) return;

    block = blockInfo;
  }

  if (!schema) {
    const collection = blocks[block.parentId];
    if (!collection) return;
    schema = collection.data.schema?.[propertyId];
  }

  if (!schema) return;

  switch (schema.type) {
    case CollectionSchemaType.CREATED_AT:
      return block.createdAt;

    case CollectionSchemaType.UPDATED_AT:
      return block.updatedAt;

    case CollectionSchemaType.CREATED_BY:
      return block.createdBy;

    case CollectionSchemaType.UPDATED_BY:
      return block.updatedBy;

    case CollectionSchemaType.DATE: {
      return getDatePropertyFromBlock(block, propertyId)?.timestamp;
    }

    case CollectionSchemaType.TITLE: {
      const value = segmentsToText(block.data.segments);
      return value.trim();
    }

    case CollectionSchemaType.NUMBER: {
      const value = segmentsToText(block.data.collectionProperties?.[propertyId]);
      let num = parseFloat(value);
      if (Number.isNaN(num)) {
        num = parseFloat(value.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
      }

      if (!Number.isNaN(num)) return num;
      return undefined;
    }

    case CollectionSchemaType.FILE: {
      const segments = block.data.collectionProperties?.[propertyId] ?? [];
      const result = segments
        .filter((item) => item.type === TextType.URL && item.fileStorageType === 'internal')
        .map((segment) => segment.url)
        .filter((url): url is string => !!url);

      return result.length > 0 ? result : undefined;
    }

    case CollectionSchemaType.EMAIL:
    case CollectionSchemaType.PHONE:
    case CollectionSchemaType.URL: {
      return readUrlFromSegments(block.data.collectionProperties?.[propertyId]);
    }

    case CollectionSchemaType.SELECT:
    case CollectionSchemaType.MULTI_SELECT: {
      const text = segmentsToText(block.data.collectionProperties?.[propertyId]).trim();
      const tags = text
        .split(/\s*,\s*/g)
        .filter((item) => schema?.options?.some((option) => option.value === item));

      return tags;
    }

    default: {
      const value = segmentsToText(block.data.collectionProperties?.[propertyId]);
      return value.trim();
    }
  }
}
/* 获取多维表cell显示的value*/
export const getTablePropertyValueForCopy = MemoizeByBlockVersion.create(
  (block: string | NextBlock | undefined, propertyId: string, state = getState()): any => {
    if (!block) return;
    const { blocks } = state as RootState;

    if (typeof block === 'string') {
      const blockInfo = blocks[block];
      if (!blockInfo) return;

      block = blockInfo;
    }
    const collection = blocks[block.parentId];
    if (!collection) return;
    const collectionId = block.parentId;
    const schema = getPropertySchema(collection.uuid, propertyId).propertySchema;

    if (!schema) return;

    const segments = block.data.collectionProperties?.[propertyId];
    switch (schema.type) {
      case CollectionSchemaType.CREATED_AT:
        return dayjs(block.createdAt).format(DATE_TIME_FORMAT);
      case CollectionSchemaType.UPDATED_AT:
        return dayjs(block.updatedAt).format(DATE_TIME_FORMAT);
      case CollectionSchemaType.CREATED_BY:
        return getUserName(block.createdBy ?? '');
      case CollectionSchemaType.UPDATED_BY:
        return getUserName(block.updatedBy ?? '');
      case CollectionSchemaType.DATE: {
        const segment = segments?.find((item) => {
          return item.type === TextType.DATE || item.type === TextType.DATETIME;
        });
        if (!segment) return '';
        return getDateStrBySegment(
          segment,
          schema.dateFormat,
          segment.type === TextType.DATETIME,
          schema.timeFormat
        );
      }
      case CollectionSchemaType.PERSON: {
        return copyNormalizedValue(segments)
          .map((user) => getUserName(user.uuid ?? ''))
          .join('');
      }
      case CollectionSchemaType.FILE: {
        return segments
          ?.filter((it) => it.type === TextType.URL && it.fileStorageType === 'internal')
          .map((it) => it.text)
          .join(',');
      }
      case CollectionSchemaType.NUMBER: {
        const format = schema.numberFormat;
        const text = segmentsToText(segments);
        let num = parseFloat(text);
        if (Number.isNaN(num)) {
          num = parseFloat(text.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
        }
        const renderNumber = formatNumber(num, format);
        return renderNumber;
      }
      case CollectionSchemaType.EMAIL:
      case CollectionSchemaType.PHONE:
      case CollectionSchemaType.URL: {
        return readUrlFromSegments(segments);
      }
      case CollectionSchemaType.SELECT:
      case CollectionSchemaType.MULTI_SELECT: {
        const text = segmentsToText(block.data.collectionProperties?.[propertyId]).trim();
        const tags = text
          .split(/\s*,\s*/g)
          .filter((item) => schema?.options?.some((option) => option.value === item));
        return tags.join(',');
      }

      case CollectionSchemaType.TITLE: {
        const value = segmentsToText(block.data.segments);
        return value.trim();
      }
      case CollectionSchemaType.CHECKBOX: {
        const text = segmentsToText(segments);
        return formatCheckBoxValue(text) ? 'YES' : 'NO';
      }
      case CollectionSchemaType.FORMULA: {
        if (collectionId) {
          const formulaTool = getFormulaTool(collectionId);
          const type = formulaTool.getType(propertyId);
          const value = formulaTool.getValue(block.uuid, propertyId);
          if (type === formula.ValueTool.numberType) {
            return value as number;
          } else if (type === formula.ValueTool.stringType) {
            return value as string;
          } else if (type === formula.ValueTool.booleanType) {
            if (value as Boolean) {
              return 'YES';
            }
            return 'NO';
          } else if (type === formula.ValueTool.dateType) {
            const date = value as Date;
            return dayjs(date).format(DATE_TIME_FORMAT);
          }
        }

        return '';
      }
      default: {
        return segmentsToText(block.data.collectionProperties?.[propertyId]);
      }
    }
  },
  (block, propertyId) => [block, propertyId]
);

export const getTablePropertyValueForChart = (
  block: string | NextBlock | undefined,
  propertyId: string,
  groupLevel: GroupLevel | undefined,
  state: RootState
) => {
  const value = _getTablePropertyValueForChart(block, propertyId, groupLevel, state);
  if (value instanceof Array) {
    if (value.length === 0) {
      return ['空值'];
    }
    return value;
  }
  if (typeof value === 'string') {
    return value ? [value] : ['空值'];
  }
  return value;
};

const _getTablePropertyValueForChart = (
  block: string | NextBlock | undefined,
  propertyId: string,
  groupLevel = GroupLevel.DAY,
  state = getState()
) => {
  if (!block) return;
  const { blocks } = state as RootState;

  if (typeof block === 'string') {
    const blockInfo = blocks[block];
    if (!blockInfo) return;

    block = blockInfo;
  }
  const collection = blocks[block.parentId];
  if (!collection) return;
  const collectionId = block.parentId;
  const schema = getPropertySchema(collection.uuid, propertyId).propertySchema;

  if (!schema) return;

  const segments = block.data.collectionProperties?.[propertyId];
  switch (schema.type) {
    case CollectionSchemaType.CREATED_AT: {
      if (block.createdAt) {
        const groupName = getGroupNameFromDate(block.createdAt, groupLevel);
        if (groupName) {
          const content = getDateShowContent(groupName, groupLevel);
          return content;
        }
      }
      return dayjs(block.createdAt).format(DATE_FORMAT);
    }
    case CollectionSchemaType.UPDATED_AT: {
      if (block.updatedAt) {
        const groupName = getGroupNameFromDate(block.updatedAt, groupLevel);
        if (groupName) {
          const content = getDateShowContent(groupName, groupLevel);
          return content;
        }
      }
      return dayjs(block.updatedAt).format(DATE_FORMAT);
    }
    case CollectionSchemaType.CREATED_BY:
      return getUserName(block.createdBy ?? '');
    case CollectionSchemaType.UPDATED_BY:
      return getUserName(block.updatedBy ?? '');
    case CollectionSchemaType.DATE: {
      const segment = segments?.find((item) => {
        return item.type === TextType.DATE || item.type === TextType.DATETIME;
      });
      if (!segment) return '';
      const date = readDateFromDateSegment(segment);
      const groupName = getGroupNameFromDate(date.getTime(), groupLevel);
      if (groupName) {
        const content = getDateShowContent(groupName, groupLevel);
        return content;
      }
      return getDateStrBySegment(
        segment,
        schema.dateFormat,
        segment.type === TextType.DATETIME,
        schema.timeFormat
      );
    }
    case CollectionSchemaType.PERSON: {
      return copyNormalizedValue(segments).map((user) => getUserName(user.uuid ?? ''));
    }
    case CollectionSchemaType.FILE: {
      return segments
        ?.filter((it) => it.type === TextType.URL && it.fileStorageType === 'internal')
        .map((it) => it.text);
    }
    case CollectionSchemaType.NUMBER: {
      const text = segmentsToText(segments);
      let num = parseFloat(text);
      if (Number.isNaN(num)) {
        num = parseFloat(text.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
      }
      if (Number.isNaN(num)) {
        num = 0;
      }
      return `${num}`;
    }
    case CollectionSchemaType.EMAIL:
    case CollectionSchemaType.PHONE:
    case CollectionSchemaType.URL: {
      return readUrlFromSegments(segments);
    }
    case CollectionSchemaType.SELECT: {
      const text = segmentsToText(block.data.collectionProperties?.[propertyId]).trim();
      if (!text) return '';
      const tags = text
        .split(/\s*,\s*/g)
        .filter((item) => schema?.options?.some((option) => option.value === item));
      return tags[0];
    }
    case CollectionSchemaType.MULTI_SELECT: {
      const text = segmentsToText(block.data.collectionProperties?.[propertyId]).trim();
      const tags = text
        .split(/\s*,\s*/g)
        .filter((item) => schema?.options?.some((option) => option.value === item));
      return tags;
    }

    case CollectionSchemaType.TITLE: {
      const value = segmentsToText(block.data.segments);
      return value.trim();
    }
    case CollectionSchemaType.CHECKBOX: {
      const text = segmentsToText(segments);
      return formatCheckBoxValue(text) ? 'YES' : 'NO';
    }
    case CollectionSchemaType.FORMULA: {
      if (collectionId) {
        const formulaTool = getFormulaTool(collectionId);
        const type = formulaTool.getType(propertyId);
        const value = formulaTool.getValue(block.uuid, propertyId);
        if (type === formula.ValueTool.numberType) {
          return value === null ? '0' : (`${value}` as string);
        } else if (type === formula.ValueTool.stringType) {
          return value as string;
        } else if (type === formula.ValueTool.booleanType) {
          if (value as Boolean) {
            return 'YES';
          }
          return 'NO';
        } else if (type === formula.ValueTool.dateType) {
          const date = value as Date;
          return dayjs(date).format(DATE_TIME_FORMAT);
        }
      }

      return '';
    }
    case CollectionSchemaType.RELATION: {
      let segments = block.data.collectionProperties?.[propertyId];
      segments = segments?.filter((v) => v.type === TextType.LINK_PAGE);
      return segments?.map((s) => segmentsToText([s], 0, false));
    }
    default: {
      return segmentsToText(block.data.collectionProperties?.[propertyId]);
    }
  }
};

/** 复制粘贴专用 */
export const getPropertySegmentsForCopy = (
  block: string | NextBlock | undefined,
  propertyId: string,
  schema?: CollectionSchema
) => {
  if (!block) return;
  const { blocks } = getState();

  if (typeof block === 'string') {
    const blockInfo = blocks[block];
    if (!blockInfo) return;

    block = blockInfo;
  }

  if (!schema) {
    const collection = blocks[block.parentId];
    if (!collection) return;
    schema = collection.data.schema?.[propertyId];
  }

  if (!schema) return;

  switch (schema.type) {
    case CollectionSchemaType.CREATED_AT:
      return [buildDateSegment({ from: new Date(block.createdAt ?? ''), includeTime: true })];
    case CollectionSchemaType.UPDATED_AT:
      return [buildDateSegment({ from: new Date(block.updatedAt ?? ''), includeTime: true })];
    case CollectionSchemaType.CREATED_BY:
      return [buildPersonSegment(block.createdBy ?? '')];
    case CollectionSchemaType.UPDATED_BY:
      return [buildPersonSegment(block.updatedBy ?? '')];
    case CollectionSchemaType.FILE:
    case CollectionSchemaType.DATE:
    case CollectionSchemaType.NUMBER:
    case CollectionSchemaType.EMAIL:
    case CollectionSchemaType.PHONE:
    case CollectionSchemaType.SELECT:
    case CollectionSchemaType.MULTI_SELECT:
    case CollectionSchemaType.URL: {
      return block.data.collectionProperties?.[propertyId];
    }

    case CollectionSchemaType.TITLE: {
      return block.data.segments;
    }
    case CollectionSchemaType.FORMULA: {
      const value = getTablePropertyValueForCopy(block, propertyId);
      return textToSegments(value);
    }
    default: {
      return block.data.collectionProperties?.[propertyId];
    }
  }
};
