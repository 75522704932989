import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ID_NUMBER_DEFAULT_WIDTH, MAX_BITABLE_PAGE_SIZE } from 'src/bitable/const';
import { useOpenFormEndPageSetting } from 'src/bitable/form-view/form-end-page/editor-end-page';
import { Divider } from 'src/common/components/divider';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { useOpenModal } from 'src/common/components/next-modal';
import { Switch } from 'src/common/components/switch';
import { useBlock } from 'src/hooks/block/use-block';
import { useCopyCollectionView } from 'src/hooks/block/use-copy-collection-view';
import { useCreateProperty } from 'src/hooks/block/use-create-property';
import { useRemoveCollectionView } from 'src/hooks/block/use-remove-collection-view';
import { useRemoveProperty } from 'src/hooks/block/use-remove-property';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import {
  useBitablePageSize,
  useCardColorSetting,
  useCollectionView,
  useShowingTablePageIcon,
  useShowLunarCalendar,
} from 'src/hooks/collection-view/use-collection-view';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getLocationOrigin } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { useBitable } from '../../context';
import { CardControl } from '../card-control';
import { ViewNameMap } from '../const';
import { DateTime } from '../datetime';
import { PageSizeLimit } from '../page-size-limit';
import type { SettingProps } from '../type';
import { CardColorList, ColorSettingList } from './color-setting';
import { ViewTypeList } from './view-type-list';

export const ViewSetting: FC<SettingProps> = ({ onCloseModal, viewTitleRef }) => {
  const {
    changeView,
    embed,
    viewType,
    viewId,
    collectionId,
    isLocked,
    managerReadonly,
    viewParentId,
    tableCellWrap,
  } = useBitable();
  const showPageIcon = useShowingTablePageIcon(viewId);
  const showLunarCalendar = useShowLunarCalendar(viewId);
  const showTitle = useObservableStore(
    ({ collectionViews }) => collectionViews[viewId]?.format.showTitle ?? true,
    [viewId]
  );
  const openFormEndPageSetting = useOpenFormEndPageSetting();
  const copyCollectionView = useCopyCollectionView();

  const removeCollectionView = useRemoveCollectionView();
  const openModal = useOpenModal();
  const colorSetting = useCardColorSetting(viewId);
  const view = useCollectionView(viewId);
  const [title, setTitle] = useState(view?.title ?? '');
  const { views = [] } = useBlock(viewParentId) ?? {};

  const pageSize = useBitablePageSize(viewId);
  const removeProperty = useRemoveProperty();
  const createProperty = useCreateProperty();
  const [properties = []] = useProperties(viewId, {
    includeIdNumber: true,
  });
  const idNumberProperty = properties.find((v) => v.type === CollectionSchemaType.ID_NUMBER);
  // boss说要快，所以这个变量不依赖于真实数据，而依赖于用户操作，别问最后对不对，就问你快不快吧
  const [openIdColumn, setOpenIdColumn] = useState(!!idNumberProperty);

  useEffect(() => {
    setTitle(view?.title ?? '');
    if (viewTitleRef) {
      viewTitleRef.current = view?.title;
    }
  }, [view?.title, viewTitleRef]);

  const { startBy, endBy } = useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      const { timelineBy, timelineByEnd, calendarBy, calendarByEnd } = view?.format ?? {};
      const isTimeline = view?.type === CollectionViewType.TIMELINE;
      return {
        startBy: isTimeline ? timelineBy : calendarBy,
        endBy: isTimeline ? timelineByEnd : calendarByEnd,
      };
    },
    [viewId]
  );
  const collection = usePickBlock(collectionId, ['data'], ['schema']);

  const showCardControl =
    viewType === CollectionViewType.BOARD || viewType === CollectionViewType.GALLERY;

  const showTypeList = (event: React.MouseEvent) => {
    if (isLocked) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      content: ({ onCloseModal }) => <ViewTypeList onChange={onCloseModal} viewId={viewId} />,
    });
  };

  const showDateList = (event: React.MouseEvent, isStartTime: boolean) => {
    if (isLocked) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      content: ({ onCloseModal }) => (
        <DateTime isStartTime={isStartTime} onCloseModal={onCloseModal} />
      ),
    });
  };

  const showColorSetting = (event: React.MouseEvent) => {
    if (isLocked) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      content: () => <ColorSettingList />,
    });
  };

  const openPageSizeSetting = (event: React.MouseEvent) => {
    if (isLocked) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content({ onCloseModal }) {
        return <PageSizeLimit closeSelf={onCloseModal} />;
      },
    });
  };

  if (!view) return null;
  return (
    <div className="pb-[5px] px-2">
      <Input
        autoFocus
        readonly={isLocked}
        placeholder="请输入名称"
        className="h-8 flex-1 my-2.5"
        value={title}
        onEnter={onCloseModal}
        onChange={(text) => {
          setTitle(text);
          if (viewTitleRef) {
            viewTitleRef.current = text;
          }
        }}
      />

      <div
        className="w-full py-2.5 text-t2 text-grey3 mb-1 px-2 flex justify-between animate-hover cursor-pointer"
        onClick={showTypeList}
      >
        <p className="text-black">视图类型</p>
        <button className="text-t2 text-grey3 flex items-center">
          {ViewNameMap[view.type]}
          <Icon name="IcArrowDown01" className="ml-2" size="xxxsmall" />
        </button>
      </div>

      {embed && (
        <div className="flex h-10 w-full items-center justify-between px-2">
          <span className="text-t2">显示多维表名称</span>
          <Switch
            open={showTitle}
            onSwitch={(status) => {
              updateViewFormat(viewId, { showTitle: status });
            }}
            disabled={isLocked}
          />
        </div>
      )}

      {viewType &&
        [CollectionViewType.TABLE, CollectionViewType.BOARD, CollectionViewType.GALLERY].includes(
          viewType
        ) && (
          <>
            <div className="flex h-10 w-full items-center justify-between px-2">
              <span className="text-t2">自适应行高</span>
              <Switch
                open={!!tableCellWrap}
                onSwitch={(status) => {
                  updateViewFormat(viewId, { tableWrap: status });
                }}
                disabled={isLocked}
              />
            </div>
          </>
        )}

      {viewType === CollectionViewType.TABLE && (
        <div className="flex h-10 w-full items-center justify-between px-2">
          <span className="text-t2">显示序号</span>
          <Switch
            open={openIdColumn}
            onSwitch={(open) => {
              setOpenIdColumn(open);
              if (open) {
                createProperty({
                  collectionId,
                  viewId,
                  position: {
                    first: true,
                  },
                  visible: true,
                  width: ID_NUMBER_DEFAULT_WIDTH,
                  propertyName: 'No.',
                  propertyType: CollectionSchemaType.ID_NUMBER,
                });
              } else {
                idNumberProperty && removeProperty(collectionId, idNumberProperty.property);
              }
            }}
            disabled={isLocked}
          />
        </div>
      )}
      {(viewType === CollectionViewType.TABLE ||
        viewType === CollectionViewType.BOARD ||
        viewType === CollectionViewType.GALLERY ||
        viewType === CollectionViewType.LIST) && (
        <div className="flex h-10 w-full items-center justify-between px-2">
          <span className="text-t2">每页大小</span>
          <button className="text-t2 text-grey3 flex items-center" onClick={openPageSizeSetting}>
            {pageSize === MAX_BITABLE_PAGE_SIZE ? '不限' : `${pageSize}条`}
            <Icon name="IcArrowDown01" className="ml-2" size="xxxsmall" />
          </button>
        </div>
      )}
      {viewType !== CollectionViewType.FORM && (
        <div className="flex h-10 w-full items-center justify-between px-2">
          <span className="text-t2">显示页面图标</span>
          <Switch
            open={showPageIcon}
            onSwitch={(status) => {
              // @ts-ignore typecheck
              updateViewFormat(viewId, { showPageIcon: status });
            }}
            disabled={isLocked}
          />
        </div>
      )}
      {!__BUILD_IN__ && viewType === CollectionViewType.CALENDAR && (
        <div className="flex h-10 w-full items-center justify-between px-2">
          <span className="text-t2">显示农历</span>
          <Switch
            open={showLunarCalendar}
            onSwitch={(status) => {
              updateViewFormat(viewId, { showLunarCalendar: status });
            }}
            disabled={isLocked}
          />
        </div>
      )}

      {showCardControl && (
        <>
          <Divider className="my-1 mx-2" />
          <CardControl />
        </>
      )}

      {(viewType === CollectionViewType.CALENDAR || viewType === CollectionViewType.TIMELINE) && (
        <>
          <div
            className="flex h-10 w-full items-center justify-between px-2"
            onClick={showColorSetting}
          >
            <span className="text-t2">颜色显示</span>
            <button className="text-t2 text-grey3 flex items-center">
              {CardColorList.find((item) => item.type === colorSetting?.type)?.name ?? '不显示'}
              <Icon name="IcArrowDown01" className="ml-2" size="xxxsmall" />
            </button>
          </div>

          <Divider className="my-1 mx-2" />

          <div
            className="w-full py-2.5 text-t2 text-grey3 mb-1 px-2 flex justify-between animate-hover cursor-pointer"
            onClick={(event) => showDateList(event, true)}
          >
            <p className="text-black">开始日期</p>
            <button className="text-t2 text-grey3 flex items-center" disabled={isLocked}>
              {collection?.data.schema?.[startBy ?? '']?.name}
              <Icon name="IcArrowDown01" className="ml-2" size="xxxsmall" />
            </button>
          </div>
          <div
            className="w-full py-2.5 text-t2 text-grey3 mb-1 px-2 flex justify-between animate-hover cursor-pointer"
            onClick={(event) => showDateList(event, false)}
          >
            <p className="text-black">结束日期</p>
            <button className="text-t2 text-grey3 flex items-center" disabled={isLocked}>
              {collection?.data.schema?.[endBy ?? '']?.name}
              <Icon name="IcArrowDown01" className="ml-2" size="xxxsmall" />
            </button>
          </div>
        </>
      )}

      {viewType === CollectionViewType.FORM && (
        <>
          <Divider className="my-1 mx-2" />

          <div className="flex h-10 w-full items-center justify-between px-2">
            <span className="text-t2">显示收集表说明</span>
            <Switch
              open={!!view?.format.formShowDescription}
              onSwitch={(status) => {
                updateViewFormat(viewId, { formShowDescription: status });
              }}
              disabled={isLocked}
            />
          </div>

          <div className="flex h-10 w-full items-center justify-between px-2">
            {/* 这个序号是表单特有的，跟多维表的id number没关系 */}
            <span className="text-t2">显示序号</span>
            <Switch
              open={!!(view?.format.formSerialNumber ?? true)}
              onSwitch={(status) => {
                updateViewFormat(viewId, { formSerialNumber: status });
              }}
              disabled={isLocked}
            />
          </div>

          <div
            className="flex h-10 w-full items-center justify-between px-2 animate-hover animate-click"
            onClick={() => openFormEndPageSetting({ viewId, collectionId })}
          >
            <span className="text-t2">结束页设计</span>
            <span className="text-t2 text-grey3 flex items-center">
              {view.format.formEndPage ? '已设置' : '未设置'}
              <Icon className="ml-2" size="xxxsmall" name="IcArrowUnfold" />
            </span>
          </div>
        </>
      )}

      {!isLocked && !managerReadonly && (
        <>
          <Divider className="my-1 mx-2" />

          {viewType !== CollectionViewType.FORM && (
            <button
              className="h-10 px-2 flex w-full items-center animate-hover rounded"
              onClick={() => {
                void writeTextInClipboard(`${getLocationOrigin()}/${collectionId}#${viewId}`);
              }}
            >
              <Icon className="mr-1" size="middle" name="IcLink" />
              <span className="text-t2">复制引用视图链接</span>
            </button>
          )}
          <button
            className="h-10 px-2 flex w-full items-center animate-hover rounded"
            onClick={() => {
              bizTracker.event('bitable_view_create', {
                from_scene: embed ? 'inline' : 'inthepage',
                view_type: viewType,
              });

              const newViewId = copyCollectionView(viewId);
              if (newViewId) {
                changeView(newViewId);
              }
            }}
          >
            <Icon className="mr-1" size="middle" name="IcDuplicate" />
            <span className="text-t2">拷贝视图</span>
          </button>

          {views.length > 1 && (
            <button
              className="h-10 px-2 flex w-full items-center animate-hover rounded"
              onClick={() => {
                openModal.warning({
                  title: <>是否确认删除该视图？</>,
                  confirm: () => {
                    removeCollectionView(viewId);
                  },
                });
              }}
            >
              <Icon className="mr-1" size="middle" name="IcTrash" />
              <span className="text-t2">删除视图</span>
            </button>
          )}
        </>
      )}
    </div>
  );
};
