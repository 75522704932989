import { getCurrentDomain, getPublicLink } from '@flowus/common/const';
import { BlockType } from '@next-space/fe-api-idl';
import { VITE_PREFIX } from 'src/env';
export { UNNAMED_USER } from '@flowus/common/const-title';

export const TOC_COLLAPSED = 'tocCollapsed';
export const USER_ID = 'userId';
export const LAST_VIEW_PAGE = 'lastViewPage';
export const SELECTED_CELL = 'selectedCell';
export const EXPAND_FOLD_RECORD = 'expandFoldList';
export const TOKEN = 'token';
export const CURRENT_SPACE_VIEW_ID = 'currentSpaceViewId';
export const CURRENT_SPACE_ID = 'currentSpaceId';
export const LAST_USED_COLOR_INFO = 'color_picker_last_used_color_info';
export const LOCAL_KEY_CREATE_FORM = 'create_form'; // 新帐户注册后，并且创建空间后创建一个收集表
export const LOCAL_KEY_OLD_VERSION = 'old_version';
export const LOCAL_KEY_THEME_MODE = 'theme_mode';
/** 记录最后一次拉消息的时间 */
export const LAST_GET_NOTIFICATION_TIME = 'last_get_notification_time';
/** 文件夹预览模式，本地数据。基于block维度存储 */
export const LOCAL_KEY_FOLDER_VIEW_TYPE_LIST = 'folder_view_type_list';
export const defaultDrawerWidth = 360;

export const PDF_HISTORY_SIDEBAR = 'pdfHistorySidebar';

export const TABLE_CELL_MENU = 'TABLE_CELL_MENU';
export const MAX_MIND_NODE_COUNT = 300; // 思维导图最大节点数

export const UNNAMED_GROUP = '未命名成员组';
export const UNTITLED = '未命名页面';
export const UNTITLED_FORM = '未命名收集表';
export const UNTITLED_BITABLE = '未命名多维表';
export const UNTITLED_BITABLE_TEMPLATE = '未命名模板';
export const UNTITLED_DRIVE = '未命名文件夹';
export const UNTITLED_FILE = '未命名文件';
export const UNTITLED_MIND_MAP = '未命名思维导图';
export const UNTITLED_CODE_BLOCK = '未命名代码块';
export const ILLEGAL_TEXT = '因涉嫌违反平台分享政策，无法查看';
export const PUBLIC_SHARE = '公开分享';
export const SHARE_LINK = '链接分享';
export const PAGE_ALREADY_DELETE = '无访问权限';
export const NONE = 'none';
export const KEY_NEW_SPACE = 'new_space';
export const COLLAPSED_WIDTH = 600; // 当主页面小于600就要收起

/** 获取使用秘籍列表，信息板和未读消息数需要这个特殊的id参数 */
export const SPECIAL_SPACE_ID = '00000000-0000-0000-0000-000000000000';

export const DESKTOP_OSS_PREFIX = (() => {
  if (__HOST_PRODUCTION__ || __HOST_LOCAL__) {
    return 'https://desktopdownload.flowus.cn/production';
  }
  if (__HOST_STAGING__) {
    return 'https://desktopdownload.flowus.cn/staging';
  }

  return 'https://desktopdownload.flowus.cn/test';
})();

export const DESKTOP_OSS_WINDOWS_YML = `${DESKTOP_OSS_PREFIX}/windows/x64/latest.yml`;

/** 服务器上的web版本文件 */
export const OSS_VERSION_URL = `${DESKTOP_OSS_PREFIX}/assets.json`;

export const UNTITLED_NAMES: Record<string, string | undefined> = {
  [BlockType.PAGE]: UNTITLED,
  [BlockType.FOLDER]: UNTITLED_DRIVE,
  [BlockType.FILE]: UNTITLED_FILE,
  [BlockType.COLLECTION_VIEW]: UNTITLED_BITABLE,
  [BlockType.COLLECTION_VIEW_PAGE]: UNTITLED_BITABLE,
  [BlockType.REFERENCE_COLLECTION]: UNTITLED_BITABLE,
  [BlockType.REFERENCE_COLLECTION_PAGE]: UNTITLED_BITABLE,
  [BlockType.CODE]: UNTITLED_CODE_BLOCK,
  [BlockType.MIND_MAPPING_PAGE]: UNTITLED_MIND_MAP,
  [BlockType.MIND_MAPPING]: UNTITLED_MIND_MAP,
};
// 产品提的优化（用户反馈）
export const emptyConvertBlockTypes = [
  BlockType.TEXTAREA,
  BlockType.TODO,
  BlockType.LIST,
  BlockType.ORDER_LIST,
  BlockType.FOLD_LIST,
];

export const HOST_NAME = new URL(VITE_PREFIX).host;

export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';
export const DATE_TIME_FORMAT_SPECIFIC = 'YYYY/MM/DD HH:mm:ss';
export const DATE_FORMAT = 'YYYY/MM/DD';
export const SHORT_DATE_FORMAT = 'MM/DD';
export const TIME_FORMAT = 'HH:mm';
export const DEFAULT_PAGE_WIDTH = 708;
export const ONE_G = 1024 * 1024 * 1024;

export const CURRENT_DOMAIN = getCurrentDomain(VITE_PREFIX);

/** 全局共用的一些死链接 */
export const { publicLink } = getPublicLink();

export const UUID_NAMESPACE: Record<string, string> = {
  PAGE_HISTORY: 'PAGE_HISTORY',
  PAGE_FEEDS: 'PAGE_FEEDS',
  NOTIFICATION: 'NOTIFICATION',
  FORM_PREVIEW: 'FORM_PREVIEW',
  PAGE_TEMPLATE: 'PAGE_TEMPLATE',
  RIGHT_PAGE: 'RIGHT_PAGE',
  RIGHT_PAGE_TITLE: 'RIGHT_PAGE_TITLE',
  RIGHT_PAGE_MIND: 'RIGHT_PAGE_MIND',
  MAIN_PAGE: 'MAIN_PAGE',
  FORM_END_PAGE: 'FORM_END_PAGE',
  PAGE_LITE: 'PAGE_LITE',
};

export const MOUSE_RIGHT = 2;
export const currentVersion = __VERSION__.split('-')[0] || '';
export const promotionChannel = `${VITE_PREFIX}/login?promotionChannel=Share_02`;

export const PersonSpaceFreeCapacity = '200MB';
export const PersonSpaceFreeCopyLimit = ' 3 次';
