import { cx } from '@flowus/common/cx';
import { sleep } from '@flowus/login/utils';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import {
  BlockStatus,
  BlockType,
  PDFAnnotationType,
  PermissionRole,
  ShowInLinePageIconType,
} from '@next-space/fe-api-idl';
import type { IEditorModel } from '@next-space/fe-inlined';
import { newContent, newElement, newText } from '@next-space/fe-inlined';
import { css } from 'otion';
import type { FC, MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { ILLEGAL_TEXT, UNTITLED_CODE_BLOCK } from 'src/common/const';
import { PRIORITY_PORTAL } from 'src/common/utils/global-listener-helper';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { findFirstPoint } from 'src/components/file-preview/pdf/utils';
import { IconTrigger } from 'src/components/icon-trigger';
import { NoAccess } from 'src/components/no-access';
import { PagePreview, set_mention_block_menu } from 'src/components/page-preview';
import { PagePreviewWrapper } from 'src/editor/component/file/page-preview-wrapper';
import { segmentsToText } from 'src/editor/utils/editor';
import { useNormalizePath } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getMentionBlockShowInfo } from 'src/utils/get-mention-block-show-info';
import { useGetPageId } from 'src/utils/getPageId';
import { getLocationOrigin, previewPrefix } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { SegmentPlainText } from 'src/views/main/aside/toc/helper';
import type { Instance, Props } from 'tippy.js';
import { InputContent } from '../input-content';
import { INLINE_MENTION_BLOCK_TAG } from './const';
import { useSpaceInlinePageIcon } from 'src/hooks/space/use-current-space';

/**
 * 这个目前是inline引用页面以及mentionBlock实现
 * @returns
 */
interface InlinePageProps {
  uuid: string;
  isLinkPage?: boolean;
  editIcon?: boolean; // 编辑icon
  interactable?: boolean; // 跳转
  autoIconSize?: boolean;
  className?: string;
  showUnderLine?: boolean;
  blockId?: string; // 对应notion的mention block
  format?: any;
  getEditorModal?: () => IEditorModel | undefined;
  baseOffset?: number;
  aliasText?: string;
}

export const InlinePage: FC<InlinePageProps> = ({
  uuid,
  editIcon = false,
  isLinkPage = false,
  interactable = false,
  className: linkClassName,
  autoIconSize = false,
  showUnderLine = true,
  getEditorModal = () => undefined,
  blockId,
  format,
  baseOffset,
  aliasText,
}) => {
  const pageId = useGetPageId();
  const history = useHistory();
  const pageBlock = usePickBlock(uuid, ['data'], ['icon', 'segments']);
  const block = usePickBlock(
    blockId,
    ['data', 'createdBy', 'status'],
    ['icon', 'segments', 'pdfAnnotation', 'description']
  );
  const hasBlock = Boolean(blockId ? block : pageBlock);
  const inlinePageIcon = useSpaceInlinePageIcon();

  const { showPageIcon, showPage } = useMemo(() => {
    if (block?.type === BlockType.PDF_ANNOTATION) {
      if (block.data.pdfAnnotation?.type === PDFAnnotationType.HIGHLIGHT) {
        return { showPageIcon: false, showPage: false };
      }
      return { showPageIcon: true, showPage: false };
    }

    if (inlinePageIcon === ShowInLinePageIconType.HIDE) {
      return { showPageIcon: false, showPage: false };
    }

    if (inlinePageIcon === ShowInLinePageIconType.SHOW_CUSTOM) {
      if (!pageBlock?.data.icon?.value) {
        return { showPageIcon: false, showPage: getMentionBlockShowInfo(block?.type).showPage };
      }
    }

    return getMentionBlockShowInfo(block?.type);
  }, [block?.data.pdfAnnotation?.type, block?.type, inlinePageIcon, pageBlock?.data.icon?.value]);

  const ref = useRef<any>(null);
  const [iconSize, setIconSize] = useState<number | undefined>(autoIconSize ? undefined : 20);
  // 被风控了
  const { illegal, role } = usePermissions(blockId || uuid);
  const openPage = useOpenPage();
  const tippyInstance = useRef<Instance<Props>>();

  let needOpenNewWindow = false;
  let path = useNormalizePath(uuid);
  if (blockId) {
    path = `${path}#${blockId}`;
  }

  const prefix = previewPrefix();
  if (block?.type === BlockType.PDF_ANNOTATION && block?.parentId) {
    // pdf annotation 行内引用
    const annotation = block.data.pdfAnnotation;
    if (annotation) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const firstPoint = findFirstPoint(annotation);
      if (firstPoint) {
        needOpenNewWindow = true;
        path = `/${prefix}/${block?.parentId}?annotationPos=${annotation.pageNumber},${firstPoint.point?.x},${firstPoint.point?.y}#${blockId}`;
      }
    }
  }
  if (pageBlock?.type === BlockType.FILE && !block) {
    // pdf annotation 源
    needOpenNewWindow = true;
    path = `/${prefix}/${uuid}`;
  }

  const title = useMemo(() => {
    if (aliasText) return aliasText;
    if (!showPage && block?.type === BlockType.CODE) {
      return segmentsToText(block.data.description) || UNTITLED_CODE_BLOCK;
    }

    if (block?.type === BlockType.PDF_ANNOTATION) {
      return block.data.pdfAnnotation?.type === PDFAnnotationType.HIGHLIGHT
        ? block.data.pdfAnnotation.text
        : '图片标注.png';
    }

    return hasBlock ? <SegmentPlainText uuid={showPage ? uuid : blockId ?? uuid} /> : '无法访问';
  }, [
    aliasText,
    block?.data.pdfAnnotation,
    block?.data.description,
    block?.type,
    blockId,
    hasBlock,
    showPage,
    uuid,
  ]);

  // 目前只能通过这样拿到对应的fontSize
  useEffect(() => {
    if (!autoIconSize) return;
    if (!ref.current) return;
    const { fontSize: parentFontSize } = getComputedStyle(ref.current);

    setIconSize(parseFloat(parentFontSize) * 1.25); // 特殊需求，icon需要放大一点
  }, [autoIconSize, iconSize]);

  let className: string;
  // 不同类型的icon在行内引用位置不一致,微调
  if (pageBlock?.data.icon?.type === 'emoji') {
    className = 'align-middle';
  } else if (pageBlock?.data.icon?.type === 'upload') {
    className = 'align-sub';
  } else {
    className = 'align-sub';
  }

  const renderTitle = useCallback(
    (params: {
      defaultIcon?: JSX.Element;
      onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    }) => {
      const { defaultIcon, onClick } = params;
      return (
        <div
          ref={ref}
          className={cx(
            'inline rounded px-1 py-0.5 space-x-1',
            illegal && 'opacity-30',
            interactable ? 'animate-hover' : css({ cursor: 'inherit !important' }),
            linkClassName
          )}
          contentEditable={false}
          onClick={onClick}
        >
          {showPageIcon && (
            <IconTrigger
              iconSize={iconSize}
              className={cx('inline-flex !p-0 transition-none', className)}
              trigger={editIcon}
              hasOverlay={isLinkPage}
              blockId={uuid}
              offset={[100, 0]}
              defaultIcon={defaultIcon}
            />
          )}
          <span
            className={cx('relative font-medium', {
              'underline decoration-grey6 underline-offset-4': showUnderLine,
              'underline decoration-grey6 underline-offset-4 decoration-dashed': blockId,
            })}
          >
            {illegal ? ILLEGAL_TEXT : title}
          </span>
        </div>
      );
    },
    [
      blockId,
      className,
      editIcon,
      iconSize,
      illegal,
      interactable,
      isLinkPage,
      linkClassName,
      showPageIcon,
      showUnderLine,
      title,
      uuid,
    ]
  );

  if (role === PermissionRole.NONE) {
    return <NoAccess id={uuid} inline={true} />;
  }

  if (blockId) {
    let defaultIcon = null;
    if (block?.type === BlockType.PDF_ANNOTATION) {
      defaultIcon = <Icon name="MIcPdf" size="normal" />;
    } else {
      defaultIcon = <BlockDefaultIcon uuid={block?.type === BlockType.FILE ? blockId : uuid} />;
    }
    return (
      <Tooltip
        lazyLoad={true}
        onShow={(instance) => {
          tippyInstance.current = instance;
        }}
        className="inline"
        theme="none"
        interactive={true}
        popupClass="next-modal"
        offset={[0, 5]}
        placement="top"
        maxWidth={10000}
        delay={[500, 50]}
        animation={'shift-away'}
        popup={
          block?.status !== BlockStatus.DELETED ? (
            // pdf标注特殊处理（预览文件）
            block?.type === BlockType.PDF_ANNOTATION ? (
              <PagePreviewWrapper
                uuid={block?.parentId}
                onCloseOutsideModel={() => {
                  tippyInstance.current?.hide();
                }}
              />
            ) : (
              <PagePreview
                pageId={uuid}
                isLink={isLinkPage}
                blockId={blockId}
                showMoreBtn={Boolean(blockId)}
                onCloseOutsideModel={() => {
                  tippyInstance.current?.hide();
                }}
                mentionBlockMenuProps={{
                  pageId: uuid,
                  blockId,
                  baseOffset: baseOffset ?? 0,
                  format,
                  showPage,
                  getEditorModal,
                  aliasText: aliasText ?? '',
                }}
              />
            )
          ) : (
            <div className="next-modal p-2 text-t2 text-center">该引用源已被删除</div>
          )
        }
      >
        {renderTitle({
          defaultIcon,
          onClick: (event) => {
            if (!interactable) {
              event.preventDefault();
              return;
            }

            if (needOpenNewWindow) {
              event.preventDefault();
              window.open(getLocationOrigin() + path);
              return;
            }

            if (block?.status === BlockStatus.DELETED) {
              message.warning('该引用源已被删除');
            }
            if (pageId === uuid) {
              history.push(path);
              return;
            }

            openPage(uuid, {
              forceOpenInRight: event.altKey,
              hash: `#${blockId}`,
              forceOpenNewTab: event.ctrlKey || event.metaKey,
            });
          },
        })}
      </Tooltip>
    );
  }

  return (
    <Tooltip
      lazyLoad={true}
      className="inline"
      theme="none"
      onShow={(instance) => {
        tippyInstance.current = instance;
      }}
      interactive={true}
      popupClass="next-modal"
      offset={[0, 5]}
      placement={block?.status !== BlockStatus.DELETED ? 'right-end' : 'top'}
      maxWidth={10000}
      delay={[500, 50]}
      animation={'shift-away'}
      popup={
        <PagePreview
          showMoreBtn={Boolean(blockId)}
          pageId={uuid}
          isLink={isLinkPage}
          blockId={blockId}
          onCloseOutsideModel={() => {
            tippyInstance.current?.hide();
          }}
        />
      }
    >
      {renderTitle({
        defaultIcon: <BlockDefaultIcon uuid={uuid} />,
        onClick: (event) => {
          if (needOpenNewWindow) {
            event.preventDefault();
            window.open(getLocationOrigin() + path);
            return;
          }

          if (!interactable) {
            event.preventDefault();
            return;
          }

          openPage(uuid, {
            forceOpenInRight: event.altKey,
            forceOpenNewTab: event.ctrlKey || event.metaKey,
          });
        },
      })}
    </Tooltip>
  );
};

export interface MentionBlockMenuProps {
  pageId: string;
  blockId: string;
  baseOffset: number;
  format: any;
  showPage: boolean;
  aliasText: string;
  getEditorModal: () => IEditorModel | undefined;
  onCloseOutModel?: () => void;
}
export const MentionBlockMenu: FC<MentionBlockMenuProps> = (props) => {
  const { pageId, blockId, getEditorModal, baseOffset, format, showPage, aliasText } = props;
  const page = usePickBlock(pageId, ['status', 'data'], ['segments', 'description']);
  const block = usePickBlock(blockId, ['status', 'data'], ['segments', 'pdfAnnotation']);
  const openModal = useOpenModal();
  const textRef = useRef<string>();
  const isPDFAnnotation = block?.type === BlockType.PDF_ANNOTATION;
  let link = useNormalizePath(pageId);
  const pagePath = link;
  if (blockId) {
    link = `${link}#${blockId}`;
    if (isPDFAnnotation) {
      const annotation = block.data.pdfAnnotation;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const firstPoint = findFirstPoint(annotation);
      if (firstPoint) {
        link = `/${previewPrefix()}/${block?.parentId}?annotationPos=${annotation?.pageNumber},${
          firstPoint.point?.x
        },${firstPoint.point?.y}#${blockId}`;
      }
    }
  }

  const items = [
    // {
    //   type: ListItemType.BLOCK_TRANSFORM,
    //   render: () => {
    //     return (
    //       <div className="rounded w-full px-2">
    //         <div className="text-grey4 text-t4 ml-1 my-1">引用源</div>
    //         <InlinePage
    //           uuid={isPDFAnnotation ? block.parentId : pageId}
    //           interactable={true}
    //           showUnderLine={false}
    //           className="flex items-center text-black text-t2 h-10 active-bg animate-hover w-full whitespace-nowrap"
    //         />
    //       </div>
    //     );
    //   },
    // },
    // {
    //   type: ListItemType.LINE,
    //   data: {},
    //   disableClick: true,
    // },
    {
      type: ListItemType.BLOCK_TRANSFORM,
      data: {
        title: '复制行内块引用链接',
        icon: 'IcLink',
        onClick: () => {
          void writeTextInClipboard(`${getLocationOrigin()}${link}`);
        },
      },
    },
    {
      type: ListItemType.BLOCK_TRANSFORM,
      data: {
        title: '清除链接',
        icon: 'IcTrash',
        onClick: () => {
          let text = aliasText;
          if (!text) {
            if (block?.type === BlockType.PDF_ANNOTATION) {
              text = block.data?.pdfAnnotation?.text ?? '';
            } else {
              text = segmentsToText(showPage ? page?.data.segments : block?.data.segments);
            }
          }

          const editorModal = getEditorModal();
          editorModal?.performChange((ctx) => {
            ctx.select(baseOffset, baseOffset + 1).replace(newContent([newText(text, format)]));
          });
        },
      },
    },
    {
      type: ListItemType.BLOCK_TRANSFORM,
      data: {
        title: '修改引用文字',
        icon: 'IcBtnEdit',
        onClick: (event: React.MouseEvent) => {
          openModal.dropdown({
            placement: 'right',
            popcorn: elementToGetBoundingClientRect(event.currentTarget),
            closeBeforeCallBack: () => {
              if (textRef.current === undefined) return;
              const editorModal = props.getEditorModal();
              editorModal?.performChange((ctx) => {
                ctx.select(baseOffset, baseOffset + 1).replace(
                  newContent([
                    newElement(INLINE_MENTION_BLOCK_TAG, {
                      pageId,
                      blockId,
                      text: textRef.current,
                    }),
                  ])
                );
              });
            },
            content: (_) => {
              let showText: string | SegmentDTO[] = aliasText;
              if (!showText) {
                if (showPage) {
                  showText = page?.data.segments ?? [];
                } else if (block?.type === BlockType.CODE) {
                  showText = page?.data.description ?? UNTITLED_CODE_BLOCK;
                } else if (block?.type === BlockType.PDF_ANNOTATION) {
                  const annotation = block.data?.pdfAnnotation;

                  if (annotation?.type === PDFAnnotationType.HIGHLIGHT) {
                    showText = block.data?.pdfAnnotation?.text ?? '';
                  } else {
                    showText = '图片标注.png';
                  }
                } else {
                  showText = block?.data.segments ?? [];
                }
              }
              return (
                <InputContent
                  tip="修改引用文字将仍继续保留引用链接"
                  content={showText}
                  onTextChange={(v) => {
                    textRef.current = v;
                  }}
                  onEnter={(e) => {
                    _.onCloseModal();
                    e.stopPropagation();
                  }}
                />
              );
            },
          });
          void sleep(300).then(() => props.onCloseOutModel?.());
        },
      },
    },
    {
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.BLOCK_TRANSFORM,
      data: {
        title: '复制页面访问链接',
        icon: 'IcLink',
        onClick: () => {
          void writeTextInClipboard(`${getLocationOrigin()}${pagePath}`);
        },
      },
    },
  ];
  return (
    <ListView className="next-modal py-2 w-52" items={items} keyDownPriority={PRIORITY_PORTAL} />
  );
};

set_mention_block_menu(MentionBlockMenu);
