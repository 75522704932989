import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { Editor } from '@next-space/fe-inlined';
import isHotkey from 'is-hotkey';
import { css } from 'otion';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { UNTITLED, UNTITLED_BITABLE } from 'src/common/const';
import { IconTrigger } from 'src/components/icon-trigger';
import {
  deleteEditorModel,
  setEditorModel,
  useEditorModelKey,
} from 'src/editor/editor/uikit/editable/helper';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { convertContentToSegments } from 'src/editor/utils/segments';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useEditorInput, useEditorKeydown } from 'src/hooks/editor';
import { INLINE_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePickBlock } from 'src/utils/pick-block';
import {
  SYNCED_REVISION_REF_SYMBOL,
  SYNCED_SEGMENTS_REF_SYMBOL,
  syncSegmentsToEditorModel,
} from 'src/utils/sync-segments-to-editor-model';
import { useBitable } from '../context';

export const TableTitle: FC = memo(() => {
  const { collectionId, readonly, viewParentId } = useBitable();
  const block = usePickBlock(collectionId, ['data'], ['icon', 'segments']);
  const icon = block?.data.icon;
  const segments = block?.data.segments;
  const hasIcon = Boolean(icon?.value?.trim() && icon.type?.trim());
  const isLinkTable = collectionId !== viewParentId;
  const openPage = useOpenPage();

  const getOrInitEditorModel = useGetOrInitEditorModel();
  const editorModelKey = useEditorModelKey(viewParentId);
  const editorModel = getOrInitEditorModel(viewParentId, true, TITLE_EDITOR_PLUGINS);
  const editorInput = useEditorInput(viewParentId, 'segments', INLINE_KEYDOWN_OPTION);
  const editorKeydown = useEditorKeydown(viewParentId, 'segments', INLINE_KEYDOWN_OPTION);
  const syncedSegmentsRef = useRef<SegmentDTO[] | undefined>();
  const syncedRevisionRef = useRef(NaN);
  const updateSegments = useThrottleUpdateSegments(collectionId);

  useEffect(() => {
    editorModel.setReadonly(readonly || isLinkTable);
  }, [editorModel, isLinkTable, readonly]);

  useEffect(() => {
    (editorModel as any)[SYNCED_SEGMENTS_REF_SYMBOL] = syncedSegmentsRef;
    (editorModel as any)[SYNCED_REVISION_REF_SYMBOL] = syncedRevisionRef;
    setEditorModel(editorModelKey, editorModel);
    return () => {
      deleteEditorModel(editorModelKey);
    };
  }, [editorModelKey, editorModel]);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;
    (element as any).addEventListener('inlinedinput', editorInput);
    return () => {
      (element as any).removeEventListener('inlinedinput', editorInput);
    };
  }, [editorInput]);

  useEffect(() => {
    if (readonly) return;
    syncSegmentsToEditorModel(editorModel, segments);
  }, [collectionId, editorModel, isLinkTable, readonly, segments]);

  useEffect(() => {
    if (readonly) return;
    const subscription = editorModel.onChange.subscribe(
      ({ previousContent, previousSelection }) => {
        const { content } = editorModel;
        if (content === previousContent) return;

        const segments = convertContentToSegments(content);
        if (syncedRevisionRef.current !== editorModel.revision) {
          syncedSegmentsRef.current = segments;
          updateSegments(segments, [previousContent, previousSelection]);
          syncedRevisionRef.current = editorModel.revision;
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [editorModel, readonly, updateSegments]);

  const titleInput = useMemo(() => {
    if (isLinkTable || readonly) {
      return (
        <RichText
          className="text-t1 inline whitespace-pre-wrap break-words align-middle font-semibold"
          plugins={TITLE_EDITOR_PLUGINS}
          segments={segments}
          placeholder={UNTITLED}
          interactable={false}
        />
      );
    }

    return (
      <Editor
        className={cx(
          'animate-hover whitespace-pre-wrap rounded px-1.5 py-0.5 font-semibold focus:bg-black_006',
          css({ outline: '0 !important' })
        )}
        emptyClassName={css({
          selectors: {
            '&::after': {
              content: JSON.stringify(UNTITLED_BITABLE),
              pointerEvents: 'none',
              color: 'var(--grey4)',
              fontWeight: 'bold',
            },
            '& > br': {
              display: 'none',
            },
          },
        })}
        ref={containerRef}
        model={editorModel}
        spellCheck={false}
        onKeyDown={(event) => {
          if (isHotkey(['Enter', 'Shift+Enter'])(event)) return;
          editorKeydown(event);
        }}
      />
    );
  }, [editorKeydown, editorModel, isLinkTable, readonly, segments]);

  if (isLinkTable) {
    return (
      <Tooltip
        popup="点击访问原始多维表"
        className={cx(
          'text-h4 animate-hover relative mr-1 flex items-center rounded px-1.5 py-0.5 text-black whitespace-nowrap max-w-full overflow-auto'
        )}
        onClick={(event) =>
          openPage(collectionId, {
            forceOpenInRight: event.altKey,
            forceOpenNewTab: event.ctrlKey || event.metaKey,
          })
        }
      >
        <IconTrigger
          trigger={false}
          iconSize={20}
          className="mr-1"
          tooltipClassName="flex-shrink-0"
          hasOverlay={hasIcon}
          blockId={collectionId}
          offset={[100, 0]}
          defaultIcon={<Icon size="normal" name="IcPagelink" />}
        />

        {titleInput}
      </Tooltip>
    );
  }

  return (
    <div data-disable-select className={'relative mr-1 max-w-full'}>
      {titleInput}
    </div>
  );
});
